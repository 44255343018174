import React, { useEffect, useState } from 'react';
import { Group, Select, Autocomplete } from '@mantine/core';
import { supabase } from "../supabaseClient";
import { IconCalendar } from '@tabler/icons-react';
import { rem } from '@mantine/core';

const FiltrosNivelCobranza = ({ setFilters, filters }) => {
    const icon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

    const [periodos, setPeriodos] = useState([]);
    const [aseguradoras, setAseguradora] = useState([]);
    const [tiposeguro, setTiposeguro] = useState([]);

    useEffect(() => {
        const fetchPeriodos = async () => {
            try {
                const { data: periodosData, error: periodosError } = await supabase
                    .from('nivel_cobranza')
                    .select('periodo')
                    .order('periodo', { ascending: true });

                if (periodosError) {
                    throw periodosError;
                }

                const uniqueYears = new Set();
                periodosData.forEach(periodo => uniqueYears.add(periodo.periodo));

                const periodosOptions = Array.from(uniqueYears).map(year => ({
                    label: year.toString(),
                    value: year.toString()
                }));

                setPeriodos(periodosOptions);

            } catch (error) {
                console.error("Error al obtener los periodos:", error);
            }
        };

        const fetchAseguradorasData = async () => {
            try {
                const { data: polizasData, error: polizasError } = await supabase
                    .from('cat_aseguradoras')
                    .select('nombre_aseguradora')
                    .order('nombre_aseguradora', { ascending: true });

                if (polizasError) {
                    throw polizasError;
                }

                const aseguradorasOptions = polizasData.map((nombre_aseguradora) => ({
                    label: nombre_aseguradora.nombre_aseguradora,
                    value: nombre_aseguradora.nombre_aseguradora
                }));

                setAseguradora(aseguradorasOptions);
            } catch (error) {
                console.error('Error al obtener las aseguradoras:', error.message);
            }
        };

        const fetchTiposeguroData = async () => {
            try {
                const { data: tiposeguroData, error: tiposeguroError } = await supabase
                    .from('cat_tipos_seguros')
                    .select('nombre_tipo_seguro')
                    .order('nombre_tipo_seguro', { ascending: true });

                if (tiposeguroError) {
                    throw tiposeguroError;
                }

                const tiposeguroOptions = tiposeguroData.map((nombre_tipo_seguro) => ({
                    label: nombre_tipo_seguro.nombre_tipo_seguro,
                    value: nombre_tipo_seguro.nombre_tipo_seguro
                }));

                setTiposeguro(tiposeguroOptions);
            } catch (error) {
                console.error('Error al obtener las tiposeguro:', error.message);
            }
        };

        fetchPeriodos();
        fetchAseguradorasData();
        fetchTiposeguroData();
    }, []);

    return (
        <div style={{
            //display: 'flex',
            //flexWrap: 'wrap',
            marginBottom: '10px',
            //justifyContent: 'center', // Centra horizontalmente
            //alignItems: 'center', // Centra verticalmente
        }}>
            <Group style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Select
                    label="Año"
                    placeholder="Selecciona año"
                    data={periodos}
                    value={filters.periodo}
                    onChange={(value) => setFilters({ ...filters, periodo: value })}
                    name="periodo"
                    //clearable
                    allowDeselect={false}
                    style={{ width: '300px', maxWidth: '300px' }}

                />

                <Autocomplete
                    label="Aseguradora"
                    placeholder="Selecciona Aseguradora"
                    data={aseguradoras}
                    value={filters.nombre_aseguradora}
                    onChange={(value) => setFilters({ ...filters, nombre_aseguradora: value })}
                    name="nombre_aseguradora"
                    style={{ width: '300px', maxWidth: '300px' }}
                />
                <Autocomplete
                    label="Tipo seguro"
                    placeholder="Selecciona tipo seguro"
                    data={tiposeguro}
                    value={filters.nombre_tipo_seguro}
                    onChange={(value) => setFilters({ ...filters, nombre_tipo_seguro: value })}
                    name="nombre_tipo_seguro"
                    style={{ width: '300px', maxWidth: '300px' }}
                />

            </Group>


        </div >
    );
};

export default FiltrosNivelCobranza;
