import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import Header from '../components/Header';
import TiposSegurosTable from '../components/TiposSegurosTable';

import TiposSegurosForm from '../components/TiposSegurosForm';
import { Group, Button, Text, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { useAuth } from '../context/AuthContext';



function CatalogoTiposSeguros() {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [tiposseguros, setTiposSeguros] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroNombreTipoSeguro, setFiltroNombreTipoSeguro] = useState('');
    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);
                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        }
    }, [user, dispatch]);


    useEffect(() => {
        //body
        try {
            const getTiposSeguros = async () => {
                dispatch(ShowLoading());
                const allTiposSeguros = await fetchTiposSeguros()
                setTiposSeguros(allTiposSeguros) //populate the react state
                // Llamar a la función contarContratantes para obtener el conteo al cargar el componente
                //contarContratantes();
                dispatch(HideLoading());
            }
            getTiposSeguros()

        } catch (error) {
            //console.error('Error:', error);
            dispatch(HideLoading());
        }
    }, [])

    /* ESTA CONST ES PARA QUERY SOLO CAT_TIPOS_CONTRATANTES */

    const fetchTiposSeguros = async () => {
        try {
            let { data: tiposseguros, error } = await supabase
                .from('cat_tipos_seguros')
                .select(`
                id_tipo_seguro,
                nombre_tipo_seguro
                
                `)
                .order('id_tipo_seguro', { ascending: true })
            //console.log("Tipo de seguros obtenidos:", tiposseguros); // Agregar este console.log para verificar los contratantes obtenidos

            if (error) {
                throw error;
            }
            return tiposseguros;
        } catch (error) {
            //console.error('Error al obtener tipos de seguros:', error.message);
            throw error; // Propaga el error para que se maneje en el componente padre si es necesario
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: tipossegurosFiltrados, error } = await supabase
                .from('cat_tipos_seguros')
                .select(`
                id_tipo_seguro,
                nombre_tipo_seguro
                
                `)
                .ilike('nombre_tipo_seguro', `%${filtroNombreTipoSeguro.toLowerCase()}%`)
                .order('id_tipo_seguro', { ascending: true });

            if (error) {
                throw error;
            }
            setTiposSeguros(tipossegurosFiltrados);
        } catch (error) {
            //console.error('Error al filtrar tipos de seguro:', error.message);
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };



    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            //console.log('Estado de carga activado');
            const allTiposSeguros = await fetchTiposSeguros();
            setTiposSeguros(allTiposSeguros);
        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddTipoSeguro = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_tiposseguros &&
        permisos.catalogos.modulos.cat_tiposseguros.acciones &&
        permisos.catalogos.modulos.cat_tiposseguros.acciones.registrar_tiposseguro === true;

    return (
        <div>
            <Card className='CardTituloCatalogos'>
                <Text
                    size="xl"
                    fw={900}
                >Catálogo Tipos seguros
                </Text>
            </Card>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar por nombre tipo"
                                value={filtroNombreTipoSeguro}
                                onChange={(event) => setFiltroNombreTipoSeguro(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddTipoSeguro && (

                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Tipo de Seguro
                                </Button>
                            )}

                        </Group>

                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>

                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Tipo de Seguro" : "Editar Tipo de Seguro"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <TiposSegurosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar el contratante seleccionado como prop
                    />

                </Modal>
                <div style={{ overflowX: "auto" }}>

                    <TiposSegurosTable
                        tiposseguros={tiposseguros}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                    />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoTiposSeguros;