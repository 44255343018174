import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button, Checkbox, Accordion, AccordionItem, rem } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";


function UsuariosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));    


    //valores iniciales
    const [user, setUser] = useState({
        id: "",
        username: "",
        email: "",
        acciones: {
            // Inicializa acciones con valores predeterminados
            inicio: {
                activo: false,
                acciones: { consultar: false }
            },
            catalogos: {
                activo: false,
                modulos: {
                    cat_tipos_contratantes: {
                        activo: false,
                        acciones: {
                            registrar_tiposcontratantes: false,
                            modificar_tiposcontratantes: false,
                            eliminar_tiposcontratantes: false
                        }
                    },
                    cat_contratantes: {
                        activo: false,
                        acciones: {
                            registrar_contratante: false,
                            modificar_contratante: false,
                            eliminar_contratante: false
                        }
                    },
                    cat_aseguradoras: {
                        activo: false,
                        acciones: {
                            registrar_aseguradora: false,
                            modificar_aseguradora: false,
                            eliminar_aseguradora: false
                        }
                    },
                    cat_tiposseguros: {
                        activo: false,
                        acciones: {
                            registrar_tiposseguro: false,
                            modificar_tiposseguro: false,
                            eliminar_tiposseguro: false
                        }
                    },
                    cat_duracionespagos: {
                        activo: false,
                        acciones: {
                            eliminar_duracionpago: false,
                            modificar_duracionpago: false,
                            registrar_duracionpago: false
                        }
                    },
                    cat_planes: {
                        activo: false,
                        acciones: {
                            eliminar_plan: false,
                            modificar_plan: false,
                            registrar_plan: false
                        }
                    },
                    cat_formaspagos: {
                        activo: false,
                        acciones: {
                            eliminar_formaspago: false,
                            modificar_formaspago: false,
                            registrar_formaspago: false
                        }
                    },
                    cat_estatuspagos: {
                        activo: false,
                        acciones: {
                            eliminar_estatuspago: false,
                            modificar_estatuspago: false,
                            registrar_estatuspago: false
                        }
                    },
                    cat_estatuspolizas: {
                        activo: false,
                        acciones: {
                            eliminar_estatuspoliza: false,
                            modificar_estatuspoliza: false,
                            registrar_estatuspoliza: false
                        }
                    },
                    cat_monedas: {
                        activo: false,
                        acciones: {
                            eliminar_moneda: false,
                            modificar_moneda: false,
                            registrar_moneda: false
                        }
                    },
                    cat_conductosdecobros: {
                        activo: false,
                        acciones: {
                            eliminar_conductosdecobro: false,
                            modificar_conductosdecobro: false,
                            registrar_conductosdecobro: false
                        }
                    },
                    cat_usuarios: {
                        activo: false,
                        acciones: {
                            eliminar_usuario: false,
                            modificar_usuario: false,
                            registrar_usuario: false
                        }
                    },

                    // Agrega más módulos de catálogos aquí si es necesario
                }
            },
            polizas: {
                activo: false,
                acciones: {
                    registrar_poliza: false,
                    modificar_poliza: false,
                    eliminar_poliza: false
                }
            },
            pagos: {
                activo: false,
                acciones: {
                    registrar_pago: false,
                    modificar_pago: false,
                    eliminar_pago: false,
                    duplicar_pago: false
                }
            },
            reportes: {
                activo: false,
                modulos: {
                    cumpleaneros: { 
                        activo: false 
                    },
                    lista_regalos: { 
                        activo: false 
                    },
                    polizas_nuevas: { 
                        activo: false 
                    },
                    nivel_cobranza: { 
                        activo: false 
                    }
                }
            }
        }

    });


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                id: transactionData.id,
                username: transactionData.username,
                email: transactionData.email,
                acciones: transactionData.acciones // Actualiza acciones con los datos existentes

            });
            //console.log("Transaction data2:", transactionData);
        }
    }, [formMode, transactionData]);

    const handleCheckboxChange = (event, section, module, action) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                [section]: {
                    ...prevUser.acciones[section],
                    modulos: {
                        ...prevUser.acciones[section].modulos,
                        [module]: {
                            ...prevUser.acciones[section].modulos[module],
                            acciones: {
                                ...prevUser.acciones[section].modulos[module].acciones,
                                [action]: isChecked
                            }
                        }
                    }
                }
            }
        }));
    };

    const handleCheckboxChangeActivo = (event, section, module) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                [section]: {
                    ...prevUser.acciones[section],
                    modulos: {
                        ...prevUser.acciones[section].modulos,
                        [module]: {
                            ...prevUser.acciones[section].modulos[module],
                            activo: isChecked
                        }
                    }
                }
            }
        }));
    };

    const handleCheckboxChangeActivo2 = (event, section) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
            ...prevUser,
            acciones: {
                ...prevUser.acciones,
                [section]: {
                    ...prevUser.acciones[section],
                    activo: isChecked
                }
            }
        }));
    };

   

    const handleCheckboxChangePolizas = (event, action) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
          ...prevUser,
          acciones: {
            ...prevUser.acciones,
            polizas: {
              ...prevUser.acciones.polizas,
              acciones: {
                ...prevUser.acciones.polizas.acciones,
                [action]: isChecked
              }
            }
          }
        }));
      };

      const handleCheckboxChangePagos = (event, action) => {
        const isChecked = event.target.checked;
        setUser(prevUser => ({
          ...prevUser,
          acciones: {
            ...prevUser.acciones,
            pagos: {
              ...prevUser.acciones.pagos,
              acciones: {
                ...prevUser.acciones.pagos.acciones,
                [action]: isChecked
              }
            }
          }
        }));
      };
      

    const handleCheckboxChangeReportes = (event, module) => {
        // Determina si el checkbox está marcado o no
        const isChecked = event.target.checked;
    
        // Actualiza el estado del usuario y sus permisos en la sección de reportes
        setUser(prevUser => ({
            ...prevUser, // Mantiene el estado anterior del usuario
            acciones: {
                ...prevUser.acciones, // Mantiene las acciones anteriores
                reportes: {
                    ...prevUser.acciones.reportes, // Mantiene las propiedades de reportes
                    modulos: {
                        ...prevUser.acciones.reportes.modulos, // Mantiene los módulos de reportes
                        [module]: {
                            ...prevUser.acciones.reportes.modulos[module], // Mantiene las propiedades del módulo
                            activo: isChecked // Actualiza el valor de activo del módulo específico
                        }
                    }
                }
            }
        }));
    };
    
    



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateContratante = async () => {
        try {
            await supabase
                .from('profiles')
                .update({
                    username: user.username,
                    acciones: user.acciones // Actualiza los permisos junto con el nombre de usuario
                })
                .eq('id', user.id);

            showNotification({
                title: 'Usuario actualizado',
                message: 'Usuario actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            //console.error('Error al actualizar el estatus de pago:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el estatus de pago.',
                color: 'red',
            });
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {
                // Buscar el id_tipo_contratante correspondiente al nombre_tipo_contratante seleccionado

                const { error } = await supabase
                    .from('profiles')
                    .insert({
                        username: user.username
                    });
                showNotification({
                    title: 'Usuario agregado',
                    message: 'Usuario ha sido agregado con éxito',
                    color: 'green',
                });

                if (error) {
                    throw error;
                }
            } else if (formMode === 'edit') {
                await handleUpdateContratante();
            }
            dispatch(HideLoading());
            setShowForm(false);
            getData();
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar el usuario:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el usuario.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action="" onSubmit={handleSubmit}>
                <Group style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                    <TextInput
                        disabled
                        name="id"
                        label="ID"
                        //onChange={handleChange}
                        style={{ width: '300px' }}
                        value={user.id}
                    />
                    <TextInput
                        name="username"
                        label="Nombre usuario"
                        style={{ width: '300px' }}
                        required
                        onChange={handleChange}
                        value={user.username}
                        placeholder='Estatus de pago'
                    />
                    <TextInput
                        name="email"
                        label="Email"
                        style={{ width: '300px' }}
                        disabled
                        onChange={handleChange}
                        value={user.email}
                        placeholder='Email'
                    />
                </Group>
                <Group style={{ display: 'flex', alignItems: 'flex-start',marginBottom: '10px'  }}>
                    <Accordion variant='contained'>
                        <Accordion.Item value="cat">
                            <Accordion.Control className="AccordionControlUsuariosForm">
                                Catálogos
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Accordion>
                                    <Accordion.Item value='1.1 Tipo contratantes'>
                                        <Accordion.Control className="AccordionControl2">Tipo Contratantes</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_tipos_contratantes.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_tipos_contratantes', 'activo')}
                                                    label="Ver tipos contratantes"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_tipos_contratantes.acciones.registrar_tiposcontratantes}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_tipos_contratantes', 'registrar_tiposcontratantes')}
                                                    label="Registrar tipo contratante"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_tipos_contratantes.acciones.modificar_tiposcontratantes}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_tipos_contratantes', 'modificar_tiposcontratantes')}
                                                    label="Modificar tipo contratante"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_tipos_contratantes.acciones.eliminar_tiposcontratantes}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_tipos_contratantes', 'eliminar_tiposcontratantes')}
                                                    label="Eliminar tipo contratante"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='1.2 Contratantes'>
                                        <Accordion.Control className="AccordionControl2">Contratantes</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_contratantes.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_contratantes', 'activo')}
                                                    label="Ver contratantes"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_contratantes.acciones.registrar_contratante}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_contratantes', 'registrar_contratante')}
                                                    label="Registrar contratante"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_contratantes.acciones.modificar_contratante}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_contratantes', 'modificar_contratante')}
                                                    label="Modificar contratante"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_contratantes.acciones.eliminar_contratante}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_contratantes', 'eliminar_contratante')}
                                                    label="Eliminar contratante"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='1.3 Aseguradoras'>
                                        <Accordion.Control className="AccordionControl2">Aseguradoras</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_aseguradoras.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_aseguradoras', 'activo')}
                                                    label="Ver aseguradoras"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_aseguradoras.acciones.registrar_aseguradora}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_aseguradoras', 'registrar_aseguradora')}
                                                    label="Registrar aseguradora"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_aseguradoras.acciones.modificar_aseguradora}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_aseguradoras', 'modificar_aseguradora')}
                                                    label="Modificar aseguradora"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_aseguradoras.acciones.eliminar_aseguradora}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_aseguradoras', 'eliminar_aseguradora')}
                                                    label="Eliminar aseguradora"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='1.4 Tipos seguros'>
                                        <Accordion.Control className="AccordionControl2">Tipos seguros</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_tiposseguros.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_tiposseguros', 'activo')}
                                                    label="Ver tipos seguros"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_tiposseguros.acciones.registrar_tiposseguro}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_tiposseguros', 'registrar_tiposseguro')}
                                                    label="Registrar tipo seguro"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_tiposseguros.acciones.modificar_tiposseguro}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_tiposseguros', 'modificar_tiposseguro')}
                                                    label="Modificar tipo seguro"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_tiposseguros.acciones.eliminar_tiposseguro}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_tiposseguros', 'eliminar_tiposseguro')}
                                                    label="Eliminar tipo seguro"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='1.5 Duración pagos'>
                                        <Accordion.Control className="AccordionControl2">Duración pagos</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_duracionespagos.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_duracionespagos', 'activo')}
                                                    label="Ver duración pagos"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_duracionespagos.acciones.registrar_duracionpago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_duracionespagos', 'registrar_duracionpago')}
                                                    label="Registrar duración pago"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_duracionespagos.acciones.modificar_duracionpago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_duracionespagos', 'modificar_duracionpago')}
                                                    label="Modificar duración pago"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_duracionespagos.acciones.eliminar_duracionpago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_duracionespagos', 'eliminar_duracionpago')}
                                                    label="Eliminar duración pago"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='Planes'>
                                        <Accordion.Control className="AccordionControl2">Planes</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_planes.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_planes', 'activo')}
                                                    label="Ver planes"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_planes.acciones.registrar_plan}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_planes', 'registrar_plan')}
                                                    label="Registrar plan"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_planes.acciones.modificar_plan}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_planes', 'modificar_plan')}
                                                    label="Modificar plan"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_planes.acciones.eliminar_plan}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_planes', 'eliminar_plan')}
                                                    label="Eliminar plan"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='Formas pagos'>
                                        <Accordion.Control className="AccordionControl2">Formas pagos</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_formaspagos.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_formaspagos', 'activo')}
                                                    label="Ver formas pagos"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_formaspagos.acciones.registrar_formaspago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_formaspagos', 'registrar_formaspago')}
                                                    label="Registrar forma pago"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_formaspagos.acciones.modificar_formaspago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_formaspagos', 'modificar_formaspago')}
                                                    label="Modificar forma pago"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_formaspagos.acciones.eliminar_formaspago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_formaspagos', 'eliminar_formaspago')}
                                                    label="Eliminar forma pago"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion>
                                    <Accordion.Item value='Estatus pagos'>
                                        <Accordion.Control className="AccordionControl2">Estatus pagos</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_estatuspagos.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_estatuspagos', 'activo')}
                                                    label="Ver estatus pagos"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_estatuspagos.acciones.registrar_estatuspago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_estatuspagos', 'registrar_estatuspago')}
                                                    label="Registrar estatus pago"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_estatuspagos.acciones.modificar_estatuspago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_estatuspagos', 'modificar_estatuspago')}
                                                    label="Modificar estatus pago"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_estatuspagos.acciones.eliminar_estatuspago}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_estatuspagos', 'eliminar_estatuspago')}
                                                    label="Eliminar estatus pago"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion>
                                    <Accordion.Item value='Estatus polizas'>
                                        <Accordion.Control className="AccordionControl2">Estatus pólizas</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_estatuspolizas.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_estatuspolizas', 'activo')}
                                                    label="Ver estatus pagos"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_estatuspolizas.acciones.registrar_estatuspoliza}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_estatuspolizas', 'registrar_estatuspoliza')}
                                                    label="Registrar estatus pago"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_estatuspolizas.acciones.modificar_estatuspoliza}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_estatuspolizas', 'modificar_estatuspoliza')}
                                                    label="Modificar estatus pago"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_estatuspolizas.acciones.eliminar_estatuspoliza}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_estatuspolizas', 'eliminar_estatuspoliza')}
                                                    label="Eliminar estatus pago"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion>
                                    <Accordion.Item value='Monedas'>
                                        <Accordion.Control className="AccordionControl2">Monedas</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_monedas.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_monedas', 'activo')}
                                                    label="Ver estatus pagos"
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_monedas.acciones.registrar_moneda}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_monedas', 'registrar_moneda')}
                                                    label="Registrar estatus pago"
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_monedas.acciones.modificar_moneda}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_monedas', 'modificar_moneda')}
                                                    label="Modificar estatus pago"

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_monedas.acciones.eliminar_moneda}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_monedas', 'eliminar_moneda')}
                                                    label="Eliminar estatus pago"
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion>
                                    <Accordion.Item value='Conductos'>
                                        <Accordion.Control className="AccordionControl2">Conductos de cobro</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_conductosdecobros.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_conductosdecobros', 'activo')}
                                                    label="Ver conductos de cobros "
                                                >
                                                </Checkbox>

                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_conductosdecobros.acciones.registrar_conductosdecobro}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_conductosdecobros', 'registrar_conductosdecobro')}
                                                    label="Registrar conductos de cobros "
                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_conductosdecobros.acciones.modificar_conductosdecobro}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_conductosdecobros', 'modificar_conductosdecobro')}
                                                    label="Modificar conductos de cobros "

                                                >
                                                </Checkbox>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_conductosdecobros.acciones.eliminar_conductosdecobro}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_conductosdecobros', 'eliminar_conductosdecobro')}
                                                    label="Eliminar conductos de cobros "
                                                >
                                                </Checkbox>
                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion>
                                    <Accordion.Item value='Usuarios'>
                                        <Accordion.Control className="AccordionControl2">Usuarios</Accordion.Control>
                                        <Accordion.Panel>
                                            <Stack>
                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_usuarios.activo}
                                                    onChange={(e) => handleCheckboxChangeActivo(e, 'catalogos', 'cat_usuarios', 'activo')}
                                                    label="Ver usuarios "
                                                >
                                                </Checkbox>


                                                <Checkbox
                                                    checked={user.acciones.catalogos.modulos.cat_usuarios.acciones.modificar_usuario}
                                                    onChange={(e) => handleCheckboxChange(e, 'catalogos', 'cat_usuarios', 'modificar_usuario')}
                                                    label="Modificar usuarios "

                                                >
                                                </Checkbox>

                                            </Stack>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                </Accordion>

                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion variant='contained'>
                        <Accordion.Item value="polizas">
                            <Accordion.Control className="AccordionControlUsuariosForm">
                                Pólizas
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack>
                                    <Checkbox
                                        checked={user.acciones.polizas.activo}
                                        onChange={(e) => handleCheckboxChangeActivo2(e, 'polizas', 'activo')}
                                        label="Ver Pólizas"
                                    />
                                    <Checkbox
                                        checked={user.acciones.polizas.acciones.registrar_poliza}
                                        onChange={(e) => handleCheckboxChangePolizas(e, 'registrar_poliza')}
                                        label="Registrar póliza"
                                    />
                                    <Checkbox
                                        checked={user.acciones.polizas.acciones.modificar_poliza}
                                        onChange={(e) => handleCheckboxChangePolizas(e, 'modificar_poliza')}
                                        label="Modificar póliza"
                                    />
                                    <Checkbox
                                        checked={user.acciones.polizas.acciones.eliminar_poliza}
                                        onChange={(e) => handleCheckboxChangePolizas(e, 'eliminar_poliza')}
                                        label="Eliminar póliza"
                                    />
                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion variant='contained'>
                        <Accordion.Item value="pagos">
                            <Accordion.Control className="AccordionControlUsuariosForm">
                                Pagos
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack>
                                    <Checkbox
                                        checked={user.acciones.pagos.activo}
                                        onChange={(e) => handleCheckboxChangeActivo2(e, 'pagos', 'activo')}
                                        label="Ver pagos"
                                    />
                                    <Checkbox
                                        checked={user.acciones.pagos.acciones.registrar_pago}
                                        onChange={(e) => handleCheckboxChangePagos(e, 'registrar_pago')}
                                        label="Registrar pagos"
                                    />
                                    <Checkbox
                                        checked={user.acciones.pagos.acciones.modificar_pago}
                                        onChange={(e) => handleCheckboxChangePagos(e, 'modificar_pago')}
                                        label="Modificar pagos"
                                    />
                                    <Checkbox
                                        checked={user.acciones.pagos.acciones.eliminar_pago}
                                        onChange={(e) => handleCheckboxChangePagos(e, 'eliminar_pago')}
                                        label="Eliminar pagos"
                                    />
                                    <Checkbox
                                        checked={user.acciones.pagos.acciones.duplicar_pago}
                                        onChange={(e) => handleCheckboxChangePagos(e, 'duplicar_pago')}
                                        label="Duplicar pagos"
                                    />

                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </Group>
                <Group>

                    <Accordion variant='contained'>
                        <Accordion.Item value="reportes">
                            <Accordion.Control className="AccordionControlUsuariosForm">
                                Reportes
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack>

                                    <Checkbox
                                    checked={user.acciones.reportes.modulos.cumpleaneros.activo}
                                    onChange={(e) => handleCheckboxChangeReportes(e,'cumpleaneros')}
                                    label='Ver reporte cumpleañeros'
                                    />
                                    <Checkbox
                                    checked={user.acciones.reportes.modulos.lista_regalos.activo}
                                    onChange={(e) => handleCheckboxChangeReportes(e,'lista_regalos')}
                                    label='Ver reporte lista regalos'
                                    />
                                    <Checkbox
                                    checked={user.acciones.reportes.modulos.polizas_nuevas.activo}
                                    onChange={(e) => handleCheckboxChangeReportes(e,'polizas_nuevas')}
                                    label='Ver reporte pólizas nuevas'
                                    />
                                    <Checkbox
                                    checked={user.acciones.reportes.modulos.nivel_cobranza.activo}
                                    onChange={(e) => handleCheckboxChangeReportes(e,'nivel_cobranza')}
                                    label='Ver reporte nivel cobranza'
                                    />

                                    
                                    
                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>

                </Group>










                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Usuario" : "Editar Usuario"}
                    </Button>
                </div>
            </form>
        </div>
    );
}



export default UsuariosForm;