import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useAuth } from "../context/AuthContext";



function DuracionesPagosTable({ duracionespagos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombreDuracionPago }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  //const truncateText = (text, maxLength) => {
  //  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  //};
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dispatch = useDispatch();

  const { user } = useAuth();
  const [permisos, setPermisos] = useState([]);


  // Este useEffect sirve para revisar qué permisos tiene este usuario
  useEffect(() => {
    if (user) {
      const fetchUserPermissions = async () => {
        try {
          dispatch(ShowLoading());
          const { data: profile, error } = await supabase
            .from('profiles')
            .select('acciones')
            .eq('id', user.id)
            .single();

          if (error) {
            console.error("Error fetching user profile:", error.message);
            throw error;
          }

          if (profile) {
            const userPermissions = profile.acciones || {};
            setPermisos(userPermissions);
            //console.log("Permisos:", userPermissions);
          }

        } catch (error) {
          console.error("Error fetching user permissions:", error.message);
        } finally {
          dispatch(HideLoading());
        }
      };

      fetchUserPermissions();
    }
  }, [user, dispatch]);


  // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
  const duracionpagosFiltrados = filtroNombreDuracionPago
    ? duracionespagos.filter(duracionpago => duracionpago.nombre_duracion_pago.toLowerCase().includes(filtroNombreDuracionPago.toLowerCase()))
    : duracionespagos;

  const paginatedTransactions = duracionpagosFiltrados.slice(startIndex, endIndex);


  /*
  const handleSelectedTransaction = (duracionpago) => {
    console.log("Duracion pago seleccionado:", duracionpago); // Agregar este console.log para verificar el contratante seleccionado
    setSelectedTransaction(duracionpago);
    setFormMode("edit");
    setShowForm(true);
  };
  */

  const deleteTransaction = async (id) => {
    try {
      dispatch(ShowLoading());

      // Aquí realizamos la eliminación usando Supabase
      const { error } = await supabase
        .from('cat_duraciones_pagos')
        .delete()
        .eq('id_duracion_pago', id);

      if (error) {
        throw new Error('No se pudo borrar la Duración de pago');
      }

      dispatch(HideLoading());
      showNotification({
        title: 'Duración de pago borrado',
        color: 'green'
      });

      getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
    } catch (error) {
      dispatch(HideLoading());
      showNotification({
        title: 'Error, no se pudo borrar la Duración del pago',
        color: 'red'
      });
    }
  };

  const getRows = paginatedTransactions.map((duracionpago) => (
    <tr key={duracionpago.id_duracion_pago}>
      <td>{duracionpago.id_duracion_pago}</td>
      <td>{duracionpago.nombre_duracion_pago}</td>

      <td>
        <Group justify="center">
          {permisos &&
            permisos.catalogos &&
            permisos.catalogos.modulos &&
            permisos.catalogos.modulos.cat_duracionespagos &&
            permisos.catalogos.modulos.cat_duracionespagos.acciones &&
            permisos.catalogos.modulos.cat_duracionespagos.acciones.modificar_duracionpago && (
              <i
                key={`edit-${duracionpago.id_duracion_pago}`}
                className='ri-pencil-line'
                onClick={() => {
                  setSelectedTransaction(duracionpago);
                  setFormMode("edit");
                  setShowForm(true);
                  //console.log("D. pago seleccionado:", duracionpago);
                }}
              ></i>
            )}
          {permisos &&
            permisos.catalogos &&
            permisos.catalogos.modulos &&
            permisos.catalogos.modulos.cat_duracionespagos &&
            permisos.catalogos.modulos.cat_duracionespagos.acciones &&
            permisos.catalogos.modulos.cat_duracionespagos.acciones.eliminar_duracionpago && (

              <i
                key={`delete-${duracionpago.id_duracion_pago}`}
                className='ri-delete-bin-line'
                onClick={() => {
                  // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                  if (window.confirm('¿Estás seguro de eliminar esta Forma de Pago?')) {
                    deleteTransaction(duracionpago.id_duracion_pago);
                  }
                }}
              ></i>
            )}

        </Group>
      </td>
    </tr>
  ));

  const csvHeaders = [
    'ID',
    'Nombre duracion pago',

  ];

  const csvData = duracionespagos.map((duracionpago) => [
    duracionpago.id_duracion_pago,
    // nuevo campo
    duracionpago.nombre_duracion_pago
  ]);

  return (
    <div >

      <Table
        style={{ textAlign: 'left', borderCollapse: 'collapse' }}
        verticalSpacing="xl"
        fontSize="sm"
        mb={10}
        striped
        withRowBorders={true}
        className="my-custom-table"
      >
        <thead
          style={{ textAlign: 'left' }}
        >
          <tr>
            <th>ID</th>
            <th>Nombre duración pago</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{getRows}</tbody>
      </Table>
      <Group justify="flex-end" mb={10}>
        <Pagination
          total={Math.ceil(duracionespagos.length / itemsPerPage)}
          siblings={1}
          withEdges
          value={currentPage}
          onChange={(newPage) => setCurrentPage(newPage)}
          color='#00497A'
        />

        {/* Agregar enlace para descargar CSV con react-csv-downloader */}

        <CsvDownloader
          filename={'duracionespagos.csv'}
          separator=","
          wrapColumnChar=""
          datas={csvData}
          columns={csvHeaders}
        >
          <Button variant="light" color='#00497A' >Descargar Tabla</Button>
        </CsvDownloader>
      </Group>

    </div>
  );
}

export default DuracionesPagosTable;