const Plan = ({ plan }) => {
    return (
        <div className="plan">
            <h3>
                {plan.nombre_plan}
            </h3>
            <p>ID: {plan.id_plan}</p> {/* Agregamos el ID aquí */}
            
        </div >
    );
}

export default Plan;