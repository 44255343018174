import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination, Card } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import { useAuth } from "../context/AuthContext";





function TiposContratantesTable({ tiposcontratantes, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombreTipoContratante }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();
    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);


// Este useEffect sirve para revisar qué permisos tiene este usuario
    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);
                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        }
    }, [user, dispatch]);


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const tipocontratantesFiltrados = filtroNombreTipoContratante
        ? tiposcontratantes.filter(tipocontratante => tipocontratante.nombre_tipo_contratante.toLowerCase().includes(filtroNombreTipoContratante.toLowerCase()))
        : tiposcontratantes;

    const paginatedTransactions = tipocontratantesFiltrados.slice(startIndex, endIndex);


    const handleSelectedTransaction = (tipocontratante) => {
        //console.log("Tipo Contratante seleccionado:", tipocontratante); // Agregar este console.log para verificar el contratante seleccionado
        setSelectedTransaction(tipocontratante);
        setFormMode("edit");
        setShowForm(true);
    };

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_tipos_contratantes')
                .delete()
                .eq('id_tipo_contratante', id);

            if (error) {
                throw new Error('No se pudo borrar Tipo de contratante');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Tipo de contratante borrado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la Transacción',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((tipocontratante) => (
        <tr key={tipocontratante.id_tipo_contratante}>
            <td>{tipocontratante.id_tipo_contratante}</td>
            <td>{tipocontratante.nombre_tipo_contratante}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tipos_contratantes &&
                        permisos.catalogos.modulos.cat_tipos_contratantes.acciones &&
                        permisos.catalogos.modulos.cat_tipos_contratantes.acciones.modificar_tiposcontratantes && (
                            <i
                                key={`edit-${tipocontratante.id_tipo_contratante}`}
                                className='ri-pencil-line'
                                onClick={() => handleSelectedTransaction(tipocontratante)}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tipos_contratantes &&
                        permisos.catalogos.modulos.cat_tipos_contratantes.acciones &&
                        permisos.catalogos.modulos.cat_tipos_contratantes.acciones.eliminar_tiposcontratantes && (
                            <i
                                key={`delete-${tipocontratante.id_tipo_contratante}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar este elemento?')) {
                                        deleteTransaction(tipocontratante.id_tipo_contratante);
                                    }
                                }}
                            ></i>
                        )}

                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre tipo contratante',
    ];

    const csvData = tiposcontratantes.map((tipocontratante) => [
        tipocontratante.id_tipo_contratante,
        tipocontratante.nombre_tipo_contratante,
    ]);



    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Nombre tipo contratante</th>
                        <th>Acciones</th>


                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(tiposcontratantes.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'tiposcontratantes.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default TiposContratantesTable;