import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from "../redux/alertsSlice";
import Login from '../pages/Auth/Login'; // Importa el componente Login

function PublicRoute({ children }) {
    const { user, loading } = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        if (loading) {
            dispatch(ShowLoading());
        } else {
            dispatch(HideLoading());
        }
    }, [loading, dispatch]);

    if (loading || user === null) {
        // Si está cargando o el usuario es null, muestra el formulario de Login
        return <Login />;
    }

    // Si hay un usuario autenticado, redirige al inicio
    return <Navigate to="/" replace />;
}

export default PublicRoute;
