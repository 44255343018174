import React, { useEffect } from 'react';
import { Group, Card, Autocomplete, Select } from '@mantine/core';
import { useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { supabase } from "../supabaseClient";
import { IconCalendar } from '@tabler/icons-react';
import { rem } from '@mantine/core';


function FiltrosReporteCumpleaneros({ setFilters, filters }) {
    const icon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

    const [numeroPolizas, setNumeroPolizas] = useState([]);
    const [contratante, setContratante] = useState([]);
    const [aseguradoras, setAseguradora] = useState([]);
    const [estatuspoliza, setEstatuspoliza] = useState([]);


    const meses = [
        { value: 'ENERO', label: 'ENERO' },
        { value: 'FEBRERO', label: 'FEBRERO' },
        { value: 'MARZO', label: 'MARZO' },
        { value: 'ABRIL', label: 'ABRIL' },
        { value: 'MAYO', label: 'MAYO' },
        { value: 'JUNIO', label: 'JUNIO' },
        { value: 'JULIO', label: 'JULIO' },
        { value: 'AGOSTO', label: 'AGOSTO' },
        { value: 'SEPTIEMBRE', label: 'SEPTIEMBRE' },
        { value: 'OCTUBRE', label: 'OCTUBRE' },
        { value: 'NOVIEMBRE', label: 'NOVIEMBRE' },
        { value: 'DICIEMBRE', label: 'DICIEMBRE' },
    ];


    useEffect(() => {
        const fetchNumeroPoliza = async () => {
            try {
                // Consulta para select 
                const { data: numeropolizaData, error: estatusError } = await supabase
                    .from('polizas')
                    .select('numero_poliza');

                if (estatusError) {
                    throw estatusError;
                }

                const numeropolizasOptions = numeropolizaData.map((estatus) => ({
                    label: estatus.numero_poliza,
                    value: estatus.numero_poliza
                }));

                setNumeroPolizas(numeropolizasOptions);
            } catch (error) {
                console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };

        const fetchContratantesData = async () => {
            try {
                // Consulta para select de Pólizas
                const { data: contratantesData, error: polizasError } = await supabase
                    .from('cat_contratantes')
                    .select('nombre_contratante')
                    .order('nombre_contratante', { ascending: true });



                if (polizasError) {
                    throw polizasError;
                }

                const contratantesOptions = contratantesData.map((contratante) => ({
                    label: contratante.nombre_contratante,
                    value: contratante.nombre_contratante
                }));

                setContratante(contratantesOptions);
            } catch (error) {
                console.error('Error al obtener las pólizas:', error.message);
            }
        };

        const fetchAseguradorasData = async () => {
            try {
                // Consulta para select de Pólizas
                const { data: contratantesData, error: polizasError } = await supabase
                    .from('cat_aseguradoras')
                    .select('nombre_aseguradora')
                    .order('nombre_aseguradora', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const aseguradorasOptions = contratantesData.map((aseguradora) => ({
                    label: aseguradora.nombre_aseguradora,
                    value: aseguradora.nombre_aseguradora
                }));

                setAseguradora(aseguradorasOptions);
            } catch (error) {
                console.error('Error al obtener las aseguradoras:', error.message);
            }
        };

        const fetchEstatuspoliza = async () => {
            try {
                // Consulta para select
                const { data: estatuspolizaData, error: polizasError } = await supabase
                    .from('cat_estatus_polizas')
                    .select('nombre_estatus_poliza')
                    .order('nombre_estatus_poliza', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const estatuspolizaOptions = estatuspolizaData.map((tipoestatuspolizas) => ({
                    label: tipoestatuspolizas.nombre_estatus_poliza,
                    value: tipoestatuspolizas.nombre_estatus_poliza
                }));

                setEstatuspoliza(estatuspolizaOptions);
            } catch (error) {
                console.error('Error al obtener los tipos estatus poliza:', error.message);
            }
        };

        fetchNumeroPoliza();
        fetchContratantesData();
        fetchAseguradorasData();
        fetchEstatuspoliza();
    }, []);

    return (
        <div style={{
            //display: 'flex',
            //flexWrap: 'wrap',
            marginBottom: '10px',
            //justifyContent: 'center', // Centra horizontalmente
            //alignItems: 'center', // Centra verticalmente
        }}>
            <Group style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Select
                    label="Mes"
                    placeholder="Selecciona mes"
                    data={meses}
                    value={filters.nombre_mes}
                    onChange={(value) => setFilters({ ...filters, nombre_mes: value })}
                    name="nombre_mes"
                    allowDeselect={false}
                    style={{ flex: '1 1 auto', marginRight: '1px' }} // Ancho flexible y margen derecho
                />

                <Autocomplete
                    label="Estatus de Póliza"
                    placeholder="Estatus Póliza"
                    data={estatuspoliza}
                    value={filters.nombre_estatus_poliza}
                    onChange={(value) => setFilters({ ...filters, nombre_estatus_poliza: value })}
                    name="nombre_estatus_poliza"
                    style={{ flex: '1 1 auto', marginRight: '1px' }} // Ancho flexible y margen derecho

                />
                <Autocomplete
                    label="Contratante"
                    placeholder="Contratante"
                    data={contratante}
                    value={filters.nombre_contratante}
                    onChange={(value) => setFilters({ ...filters, nombre_contratante: value })}
                    name="nombre_contratante"
                    style={{ flex: '1 1 auto', marginRight: '1px' }} // Ancho flexible y margen derecho

                />
                <Autocomplete
                    label="Aseguradora"
                    placeholder="Aseguradora"
                    data={aseguradoras}
                    value={filters.nombre_aseguradora}
                    onChange={(value) => setFilters({ ...filters, nombre_aseguradora: value })}
                    name="nombre_aseguradora"
                    style={{ flex: '1 1 auto', marginRight: '1px' }} // Ancho flexible y margen derecho

                />
                <Autocomplete
                    label="Número de Póliza"
                    placeholder="Póliza"
                    data={numeroPolizas}
                    value={filters.numero_poliza}
                    onChange={(value) => setFilters({ ...filters, numero_poliza: value })}
                    name="numero_poliza"
                    style={{ flex: '1 1 auto', marginRight: '1px' }} // Ancho flexible y margen derecho

                />

            </Group>


        </div>
    );
}

export default FiltrosReporteCumpleaneros;