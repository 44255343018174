import React, { useState, useEffect } from 'react';
import { supabase } from "../supabaseClient";
import Header from '../components/Header';
import { Group, Button, Divider, Modal, Card, Text, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import PagosForm from '../components/PagosForm';
import { IconSearch, IconEyeglass, IconClipboardText, IconBrandCashapp } from '@tabler/icons-react';
import FiltrosPolizasNuevas from '../components/FiltrosPolizasNuevas';
import PolizasNuevasTable from '../components/PolizasNuevasTable';
import PolizasNuevasTable2 from '../components/PolizasNuevasTable2';
import PolizasNuevasTable3 from '../components/PolizasNuevasTable3';




function CatalogoPolizasNuevas() {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [pagos, setPagos] = useState([]);
    const [pagos2, setPagos2] = useState([]);
    const [pagos3, setPagos3] = useState([]);


    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const [filters, setFilters] = React.useState({
        periodo: "2024",
        mes: "Enero",
        aseguradora: "",
        tipo_seguro: "",
        nombre_aseguradora: "",
        nombre_tipo_seguro: ""

    });
    const dispatch = useDispatch();

    const fetchPagos = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('vista_polizas4').select('*');

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.periodo) {
                supabaseQuery = supabaseQuery.eq('periodo', filters.periodo);
            }

            if (filters.mes) {
                supabaseQuery = supabaseQuery.eq('mes', filters.mes);
            }

            if (filters.aseguradora) {
                supabaseQuery = supabaseQuery.eq('aseguradora', filters.aseguradora);
            }

            if (filters.tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('tipo_seguro', filters.tipo_seguro);
            }


            let { data: pagosData, error } = await supabaseQuery;

            if (error) {
                throw error;
            }

            setPagos(pagosData);
            //console.log("pagos1", pagosData); // Aquí se imprime pagos2 en la consola

            dispatch(HideLoading());



        } catch (error) {
            //console.error('Error al obtener pagos:', error.message);
            dispatch(HideLoading());
        }
    };

    const fetchPagos2 = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('vista_polizas5').select('*');

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.periodo) {
                supabaseQuery = supabaseQuery.eq('periodo', filters.periodo);
            }

            if (filters.mes) {
                supabaseQuery = supabaseQuery.eq('mes', filters.mes);
            }

            if (filters.aseguradora) {
                supabaseQuery = supabaseQuery.eq('nombre_aseguradora', filters.aseguradora);
            }

            if (filters.tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('nombre_tipo_seguro', filters.tipo_seguro);
            }


            let { data: pagosData2, error } = await supabaseQuery;


            if (error) {
                throw error;
            }

            setPagos2(pagosData2);
            //console.log("pagos2", pagosData2); // Aquí se imprime pagos2 en la consola

            dispatch(HideLoading());



        } catch (error) {
            //console.error('Error al obtener pagos2:', error.message);
            dispatch(HideLoading());
        }
    };

    const fetchPagos3 = async () => {
        try {
            dispatch(ShowLoading());

            // Obtener los números de póliza de pagos2
            const numerosPoliza = pagos2.map(pago3 => pago3.numero_poliza);

            let supabaseQuery = supabase.from('vista_polizas7').select('*');

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.periodo) {
                supabaseQuery = supabaseQuery.eq('periodo', filters.periodo);
            }

            if (filters.mes) {
                supabaseQuery = supabaseQuery.eq('mes', filters.mes);
            }

            if (filters.aseguradora) {
                supabaseQuery = supabaseQuery.eq('nombre_aseguradora', filters.aseguradora);
            }

            if (filters.tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('nombre_tipo_seguro', filters.tipo_seguro);
            }

            // Filtrar por los números de póliza de pagos2
            supabaseQuery = supabaseQuery.in('numero_poliza', numerosPoliza);


            let { data: pagosData3, error } = await supabaseQuery;

            if (error) {
                throw error;
            }

            setPagos3(pagosData3);
            //console.log("pagos3", pagosData3); // Aquí se imprime pagos2 en la consola

            dispatch(HideLoading());



        } catch (error) {
            //console.error('Error al obtener pagos3:', error.message);
            dispatch(HideLoading());
        }
    };



    const getData = async () => {
        try {
            await fetchPagos();
            await fetchPagos2();
            //await fetchPagos3();
        } catch (error) {
            // Manejo de errores
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            dispatch(HideLoading());
        }
    };

    const getData2 = async () => {
        try {
            //await fetchPagos();
            //await fetchPagos2();
            await fetchPagos3();
        } catch (error) {
            // Manejo de errores
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            dispatch(HideLoading());
        }
    };


    return (
        <div >
            <Card className='CardTituloReportes'
                mb={'15px'}
            >
                <Text
                    size="xl"
                    fw={900}

                >Reporte Pólizas Nuevas
                </Text>
            </Card>
            <Accordion defaultValue="pago">
                <Accordion.Item value="pago">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <FiltrosPolizasNuevas
                                filters={filters}
                                setFilters={setFilters}

                            />
                        </Group>
                        <Button leftSection={<IconSearch size={20} />} mt={'10px'} color="#005187" onClick={getData}>
                            Buscar Pólizas
                        </Button>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Pago" : "Editar Pago"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <PagosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction}
                    />
                </Modal>
            
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <Card 
                        shadow="lg"
                        c='black'
                        color='black'
                        //bg='#F0F0F0'

                        style={{ padding: '10px', marginBottom: '20px', marginRight: '10px', borderRadius: '8px', border: '2px solid #DEE2E6' }} // Agregar margen derecho
                        className="poliza-card"

                    >
                        <div style={{ overflowX: "auto" }}>
                            <Group mt="md" mb="xs">
                                <IconClipboardText size={20} color="#00497A" />
                                <Text c='#00497A' size='xl' fw={500}>Resumen Pólizas Nuevas</Text>
                            </Group>
                            <PolizasNuevasTable
                                pagos={pagos}
                                setSelectedTransaction={setSelectedTransaction}
                                setFormMode={setFormMode}
                                setShowForm={setShowForm}
                                getData={getData}
                            />
                        </div>
                    </Card>
                    <Card
                        shadow="lg"
                        c='black'
                        color='black'
                        //bg='#F0F0F0'
                        style={{ padding: '10px', marginBottom: '20px', marginLeft: '10px', borderRadius: '8px', border: '2px solid #DEE2E6' }} // Agregar margen izquierdo
                        className="poliza-card"
                    >
                        <div style={{ overflowX: "auto" }}>
                            <Group mt="md" mb="xs">
                                <IconEyeglass size={20} color="#00497A" />
                                <Text c='#00497A' size='xl' fw={500}>Detalle Pólizas Nuevas</Text>
                            </Group>
                            <PolizasNuevasTable2
                                pagos2={pagos2}
                            />
                        </div>
                    </Card>
                </div>
                <Divider my="xs" />

                <Group>
                    <Button leftSection={<IconSearch size={20} />} mb={'10px'} color="#269664" onClick={getData2}>
                        Buscar Pagos
                    </Button>

                </Group>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

                    <Card
                        shadow="lg"
                        c='black'
                        color='black'
                        //bg='#F0F0F0'
                        style={{ flex: 1, padding: '10px', marginBottom: '20px', marginLeft: '10px', borderRadius: '8px', border: '2px solid #DEE2E6' }} // Agregar margen izquierdo
                        className="poliza-card"
                    >
                        <div style={{ overflowX: "auto" }}>
                            <Group mt="md" mb="xs">
                                <IconBrandCashapp size={20} color="#00497A" />
                                <Text c='#00497A' size='xl' fw={500}>Detalle Pagos Pólizas Nuevas</Text>
                            </Group>
                            <PolizasNuevasTable3
                                pagos3={pagos3}
                            />
                        </div>
                    </Card>
                </div>
            </Card>
        </div>
    );
}

export default CatalogoPolizasNuevas;
