import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import ConductosCobrosForm from '../components/ConductosCobrosForm';
import { Group, Button, Text, Accordion, Modal, Card, TextInput } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import ConductosCobrosTable from '../components/ConductosCobrosTable';



function CatalogoConductosCobros({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [conductoscobros, setConductosCobros] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroConductoCobro, setFiltroConductoCobro] = useState('');


    useEffect(() => {
        //body
        try {
            const getConductosCobros = async () => {
                dispatch(ShowLoading());
                const allConductosCobros = await fetchConductosCobros()
                setConductosCobros(allConductosCobros) //populate the react state
                // Llamar a la función contarContratantes para obtener el conteo al cargar el componente
                //contarContratantes();
                dispatch(HideLoading());
            }

            getConductosCobros()

        } catch (error) {
            //console.error('Error:', error);
            dispatch(HideLoading());
        }
    }, [])

    /* ESTA CONST ES PARA QUERY SOLO CAT_TIPOS_CONTRATANTES */

    const fetchConductosCobros = async () => {
        try {
            let { data: conductoscobros, error } = await supabase
                .from('cat_conductos_cobros')
                .select(`
                id_conducto_cobro,
                nombre_conducto_cobro
                
                `)
                .order('id_conducto_cobro', { ascending: true })
            //console.log("Tipo de conductoscobros obtenidos:", conductoscobros); // Agregar este console.log para verificar los contratantes obtenidos

            if (error) {
                throw error;
            }
            return conductoscobros;
        } catch (error) {
            //console.error('Error al obtener conductoscobros:', error.message);
            throw error; // Propaga el error para que se maneje en el componente padre si es necesario
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: planesFiltrados, error } = await supabase
                .from('cat_conductos_cobros')
                .select(`
                id_conducto_cobro,
                nombre_conducto_cobro
                
                `)
                .ilike('nombre_conducto_cobro', `%${filtroConductoCobro.toLowerCase()}%`)
                .order('id_conducto_cobro', { ascending: true });

            if (error) {
                throw error;
            }
            setConductosCobros(planesFiltrados);
        } catch (error) {
            //console.error('Error al filtrar conductos cobros:', error.message);
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };



    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            //console.log('Estado de carga activado');
            const allConductosCobros = await fetchConductosCobros();
            setConductosCobros(allConductosCobros);
        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddConductosCobros = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_conductosdecobros &&
        permisos.catalogos.modulos.cat_conductosdecobros.acciones &&
        permisos.catalogos.modulos.cat_conductosdecobros.acciones.registrar_conductosdecobro === true;


    return (
        <div>
            <Card className='CardTituloCatalogos'>
                <Text
                    size="xl"
                    fw={900}
                >Catálogo Conductos Cobros
                </Text>
            </Card>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar plan"
                                value={filtroConductoCobro}
                                onChange={(event) => setFiltroConductoCobro(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddConductosCobros && (
                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Conducto Cobro
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>

                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Conducto Cobro" : "Editar Conducto Cobro"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <ConductosCobrosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar el contratante seleccionado como prop
                    />

                </Modal>
                <div style={{ overflowX: "auto" }}>

                    <ConductosCobrosTable
                        conductoscobros={conductoscobros}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoConductosCobros;