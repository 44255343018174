import React, { useState } from 'react';
import { Card, Button, Group, Pagination, Table } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { IconFileX, IconFileCertificate, IconClipboardText, IconBuildingBank, IconGift, IconBriefcase, IconUser, IconBalloon, IconCalendarStats, IconCoins, IconCoin, IconHourglass, IconCurrencyDollar, IconFileDollar, IconLock, IconCalendar } from '@tabler/icons-react';
import moment from "moment";
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";

function PolizasTable2({ polizas, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombrePoliza, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const tipopolizasFiltradas = filtroNombrePoliza
        ? polizas.filter(poliza => poliza.numero_poliza.toLowerCase().includes(filtroNombrePoliza.toLowerCase()))
        : polizas;

    const paginatedTransactions = tipopolizasFiltradas.slice(startIndex, endIndex);

    const handleSelectedTransaction = (poliza) => {
        setSelectedTransaction(poliza);
        setFormMode("edit");
        setShowForm(true);
    };

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('polizas')
                .delete()
                .eq('id_poliza', id);

            if (error) {
                throw new Error('No se pudo borrar la Póliza');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Póliza eliminada',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la Póliza',
                color: 'red'
            });
        }
    };

    const getBadgeColor = (nombreAseguradora) => {
        switch (nombreAseguradora) {
            case "En vigor":
                return "green";
            case "Cancelada":
                return "red";
            case "En vigor sin pago de primas":
                return "blue";
            case "En vigor prorrogado":
                return "purple";
            default:
                return "black";
        }
    };

    const getRows = paginatedTransactions.map((poliza) => {
        let colorFondo, colorTexto;

        switch (poliza.cat_estatus_polizas.nombre_estatus_poliza) {
            case 'En vigor':
                colorFondo = '#40C057';
                colorTexto = 'white';
                break;
            case 'Cancelada':
                colorFondo = '#FA5252';
                colorTexto = 'white';
                break;
            case 'En vigor sin pago de primas':
                colorFondo = '#228BE6';
                colorTexto = 'white';
                break;
            case 'En vigor prorrogado':
                colorFondo = '#800080';
                colorTexto = 'white';
                break;
            default:
                colorFondo = 'black';
                colorTexto = 'white';
        }

        const estiloCelda = {
            backgroundColor: colorFondo,
            color: colorTexto
        };

        return (
            <tr key={poliza.id_poliza}>
                <td>{poliza.id_poliza}</td>
                <td>{poliza.numero_poliza}</td>
                <td style={estiloCelda}>{poliza.cat_estatus_polizas.nombre_estatus_poliza}</td>
                <td>{poliza.cat_contratantes.nombre_contratante}</td>
                <td>{poliza.nombre_asegurado}</td>
                <td>{poliza.fecha_nacimiento_asegurado}</td>
                <td>{poliza.cat_aseguradoras.nombre_aseguradora}</td>
                <td>{poliza.cat_tipos_seguros.nombre_tipo_seguro}</td>
                <td>{poliza.cat_planes.nombre_plan}</td>
                <td>{poliza.fecha_emision}</td>
                <td>{poliza.fecha_vigencia}</td>
                <td>{poliza.cat_duraciones_pagos.nombre_duracion_pago}</td>
                <td>{poliza.cat_formas_pagos.nombre_forma_pago}</td>
                <td>{poliza.prima}</td>
                <td>{poliza.suma_asegurada}</td>
                <td>{poliza.cat_monedas.nombre_moneda}</td>
                <td>
                    <Group justify="center">
                        {permisos &&
                            permisos.polizas &&
                            permisos.polizas.acciones &&
                            permisos.polizas.acciones.modificar_poliza && (
                                <i
                                    key={`edit-${poliza.id_poliza}`}
                                    className='ri-pencil-line'
                                    onClick={() => {
                                        setSelectedTransaction(poliza);
                                        setFormMode("edit");
                                        setShowForm(true);
                                        //console.log("Póliza seleccionado:", poliza);
                                    }}
                                ></i>
                            )}
                        {permisos &&
                            permisos.polizas &&
                            permisos.polizas.acciones &&
                            permisos.polizas.acciones.eliminar_poliza && (

                                <i
                                    key={`delete-${poliza.id_poliza}`}
                                    className='ri-delete-bin-line'
                                    onClick={() => {
                                        // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                        if (window.confirm('¿Estás seguro de eliminar esta Póliza?')) {
                                            deleteTransaction(poliza.id_poliza);
                                        }
                                    }}
                                ></i>
                            )}

                    </Group>
                </td>
            </tr>
        );
    });


    const csvHeaders = [
        'ID',
        'Número de Póliza',
        'Estatus póliza',
        'Contratante',
        'Asegurado',
        'Cumpleaños',
        'Aseguradora',
        'Tipo Seguro',
        'Plan',
        'F. Emisión',
        'F. Vigencia',
        'Duración de pagos',
        'Forma de pago',
        'Prima',
        'Suma asegurada',
        'Moneda',
        'Notas',
    ];

    const csvData = polizas.map((poliza) => {
        // Formatear la suma asegurada eliminando las comas
        const sumaAsegurada = poliza.suma_asegurada ? poliza.suma_asegurada.replace(/,/g, '') : '';

        return [
            poliza.id_poliza,
            poliza.numero_poliza,
            poliza.cat_estatus_polizas.nombre_estatus_poliza,
            poliza.cat_contratantes ? poliza.cat_contratantes.nombre_contratante : '',
            poliza.nombre_asegurado,
            poliza.fecha_nacimiento_asegurado,
            poliza.cat_aseguradoras.nombre_aseguradora,
            poliza.cat_tipos_seguros.nombre_tipo_seguro,
            poliza.cat_planes.nombre_plan,
            poliza.fecha_emision,
            poliza.fecha_vigencia,
            poliza.cat_duraciones_pagos.nombre_duracion_pago,
            poliza.cat_formas_pagos.nombre_forma_pago,
            poliza.prima,
            sumaAsegurada, // Usar la versión formateada de la suma asegurada
            poliza.cat_monedas.nombre_moneda,
            poliza.notas_poliza,
        ];
    });


    return (

        <div>

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Número Póliza</th>
                        <th>Estatus Póliza</th>

                        <th>Contratante</th>
                        <th>Asegurado</th>
                        <th>Cumpleaños asegurado</th>
                        <th>Aseguradora</th>
                        <th>Tipo seguro</th>
                        <th>Plan</th>
                        <th>Fecha de emisión</th>
                        <th>Fecha de vigencia</th>
                        <th>Duración pagos</th>
                        <th>Forma pago</th>
                        <th>Prima</th>
                        <th>Suma asegurada</th>
                        <th>Moneda</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>


                <Pagination
                    total={Math.ceil(polizas.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'polizas.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>

            </Group>



        </div>
    );
}

export default PolizasTable2;