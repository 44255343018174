import React, { useState } from 'react';
import { Card, Button, Group, Pagination, Text, Divider, Badge, Tooltip, Stack } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { IconFileX, IconFileCertificate, IconClipboardText, IconBuildingBank, IconGift, IconBriefcase, IconUser, IconBalloon, IconCalendarStats, IconCoins, IconCoin, IconHourglass, IconCurrencyDollar, IconFileDollar, IconLock, IconCalendar } from '@tabler/icons-react';
import moment from "moment";
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";

function PolizasTable({ polizas, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombrePoliza, user, permisos }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dispatch = useDispatch();


  // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
  const tipopolizasFiltradas = filtroNombrePoliza
    ? polizas.filter(poliza => poliza.numero_poliza.toLowerCase().includes(filtroNombrePoliza.toLowerCase()))
    : polizas;

  const paginatedTransactions = tipopolizasFiltradas.slice(startIndex, endIndex);

  const handleSelectedTransaction = (poliza) => {
    setSelectedTransaction(poliza);
    setFormMode("edit");
    setShowForm(true);
  };

  const deleteTransaction = async (id) => {
    try {
      dispatch(ShowLoading());

      // Aquí realizamos la eliminación usando Supabase
      const { error } = await supabase
        .from('polizas')
        .delete()
        .eq('id_poliza', id);

      if (error) {
        throw new Error('No se pudo borrar la Póliza');
      }

      dispatch(HideLoading());
      showNotification({
        title: 'Póliza eliminada',
        color: 'green'
      });

      getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
    } catch (error) {
      dispatch(HideLoading());
      showNotification({
        title: 'Error, no se pudo borrar la Póliza',
        color: 'red'
      });
    }
  };

  const getBadgeColor = (nombreAseguradora) => {
    switch (nombreAseguradora) {
      case "En vigor":
        return "green";
      case "Cancelada":
        return "red";
      case "En vigor sin pago de primas":
        return "blue";
      case "En vigor prorrogado":
        return "purple";
      case "Seguro saldado":
        return "black";
      default:
        return "black";
    }
  };

  const getCards = paginatedTransactions.map((poliza) => (
    <Card
      key={poliza.id_poliza}
      shadow="lg"
      c='black'
      color='black'
      //bg='#F0F0F0'
      bg='linear-gradient(to top, #F0F0F0, #E0E9EF)' // Aquí defines el fondo degradado

      style={{ padding: '10px', marginBottom: '20px', borderRadius: '8px', border: '2px solid #DEE2E6' }}
      className="poliza-card"
    >
      <Card.Section inheritPadding='lg' mt='xs' mb='xs'>
        <Group justify="space-between" mb="xs">
          <Text style={{ fontSize: '18px' }} weight={500} size="xl" justify='center'>
            <strong>Póliza:</strong> {poliza.numero_poliza}
          </Text>
          <Badge color={getBadgeColor(poliza.cat_estatus_polizas ? poliza.cat_estatus_polizas.nombre_estatus_poliza : '')}>
            {poliza.cat_estatus_polizas ? poliza.cat_estatus_polizas.nombre_estatus_poliza : ''}
          </Badge>
        </Group>
        <Text style={{ fontSize: '13px' }} weight={500} size="xl" justify='center'>
          ID: {poliza.id_poliza}
        </Text>
        <Divider size="sm" my="md" ml='-10px' mr='-10px' />
        <Card mb={10} shadow="none" padding="xs" radius="md" withBorder style={{ padding: '10px', borderRadius: '8px', border: '2px solid #DEE2E6' }}>
          <div style={{ fontSize: '15px', marginBottom: '5px' }} >
            <IconBriefcase size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong> Contratante:</strong> {poliza.cat_contratantes ? poliza.cat_contratantes.nombre_contratante : ''}
          </div>
          <div style={{ fontSize: '15px', marginBottom: '5px' }} >
            <IconUser size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong> Asegurado (a):</strong> {poliza.nombre_asegurado}
          </div>
          <div style={{ fontSize: '15px', marginBottom: '5px' }} >
            <IconGift size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong> Cumpleaños:</strong> {moment(poliza.fecha_nacimiento_asegurado).format('DD-MM-YYYY')}
          </div>

        </Card>
        <Card mb={10} shadow="none" padding="xs" radius="md" withBorder style={{ padding: '10px', borderRadius: '8px', border: '2px solid #DEE2E6' }}>
          <div style={{ fontSize: '15px', marginBottom: '5px' }} >
            <IconBuildingBank size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Aseguradora:</strong> {poliza.cat_aseguradoras ? poliza.cat_aseguradoras.nombre_aseguradora : ''}
          </div>
          <div style={{ fontSize: '15px', marginBottom: '5px' }} >
            <IconClipboardText size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Tipo Seguro:</strong> {poliza.cat_tipos_seguros ? poliza.cat_tipos_seguros.nombre_tipo_seguro : ''}
          </div>
          <div style={{ fontSize: '15px', marginBottom: '5px' }} >
            <IconFileCertificate size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Plan:</strong> {poliza.cat_planes ? poliza.cat_planes.nombre_plan : ''}
          </div>
        </Card>
        <Card mb={10} shadow="none" padding="xs" radius="md" withBorder style={{ padding: '10px', borderRadius: '8px', border: '2px solid #DEE2E6' }}>
          <div style={{ marginRight: '5px', fontSize: '15px' }}>
            <IconCalendar size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Emisión: </strong>{poliza.fecha_emision ? moment(poliza.fecha_emision).format('DD-MM-YYYY') : ''}
          </div>
          <div style={{ marginRight: '5px', fontSize: '15px' }}>
            <IconFileX size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Vigencia: </strong>{poliza.fecha_vigencia ? moment(poliza.fecha_vigencia).format('DD-MM-YYYY') : ''}
          </div>
          <div style={{ marginRight: '5px', fontSize: '15px' }}>
            <IconHourglass size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Duración pagos: </strong>{poliza.cat_duraciones_pagos ? poliza.cat_duraciones_pagos.nombre_duracion_pago : ''}
          </div>
          <div style={{ marginRight: '5px', fontSize: '15px' }}>
            <IconCalendarStats size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Forma pago: </strong>{poliza.cat_formas_pagos ? poliza.cat_formas_pagos.nombre_forma_pago : ''}
          </div>
        </Card>
        <Card mb={10} shadow="none" padding="xs" radius="md" withBorder style={{ padding: '10px', borderRadius: '8px', border: '2px solid #DEE2E6' }}>
          <div style={{ marginRight: '5px', fontSize: '15px' }}>
            <IconCurrencyDollar size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Prima: </strong>
            {poliza.prima ? `$${poliza.prima.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''}
          </div>

          <div style={{ marginRight: '5px', fontSize: '15px' }}>
            <IconLock size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Suma asegurada: </strong> {poliza.suma_asegurada ? poliza.suma_asegurada : ''}
          </div>
          <div style={{ marginRight: '5px', fontSize: '15px' }}>
            <IconCoins size={20} style={{ marginBottom: '-4px', marginRight: "2px" }} />
            <strong>Moneda: </strong> {poliza.cat_monedas ? poliza.cat_monedas.nombre_moneda : ''}
          </div>
        </Card>

      </Card.Section>
      <Card.Section mt='xs' mb='xs'>
        <Group justify="center">
          {permisos &&
            permisos.polizas &&
            permisos.polizas.acciones &&
            permisos.polizas.acciones.modificar_poliza && (
              <i
                className='ri-pencil-line'

                onClick={() => {
                  setSelectedTransaction(poliza);
                  setFormMode("edit");
                  setShowForm(true);
                  //console.log("Tipo poliza:", poliza);
                }}          >
              </i>
            )}
          {permisos &&
            permisos.polizas &&
            permisos.polizas.acciones &&
            permisos.polizas.acciones.eliminar_poliza && (

              <i
                key={`delete-${poliza.id_poliza}`}
                className='ri-delete-bin-line'
                onClick={() => {
                  // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                  if (window.confirm('¿Estás seguro de eliminar esta Póliza?')) {
                    deleteTransaction(poliza.id_poliza);
                  }
                }}
              ></i>
            )}

        </Group>
      </Card.Section>
    </Card >
  ));

  const csvHeaders = [
    'ID',
    'Número de Póliza',
    'Estatus de Póliza',
    'Contratante',
    'Asegurado',
    'Cumpleaños',
    'Aseguradora',
    'Tipo Seguro',
    'Plan',
    'F. Emisión',
    'F. Vigencia',
    'Duración de pagos',
    'Forma de pago',
    'Prima',
    'Suma asegurada',
    'Moneda',
    'Notas',
  ];

  const csvData = polizas.map((poliza) => {
    // Formatear la suma asegurada eliminando las comas
    const sumaAsegurada = poliza.suma_asegurada ? poliza.suma_asegurada.replace(/,/g, '') : '';

    return [
      poliza.id_poliza,
      poliza.numero_poliza,
      poliza.cat_estatus_polizas.nombre_estatus_poliza,
      poliza.cat_contratantes ? poliza.cat_contratantes.nombre_contratante : '',
      poliza.nombre_asegurado,
      poliza.fecha_nacimiento_asegurado,
      poliza.cat_aseguradoras.nombre_aseguradora,
      poliza.cat_tipos_seguros.nombre_tipo_seguro,
      poliza.cat_planes.nombre_plan,
      poliza.fecha_emision,
      poliza.fecha_vigencia,
      poliza.cat_duraciones_pagos.nombre_duracion_pago,
      poliza.cat_formas_pagos.nombre_forma_pago,
      poliza.prima,
      sumaAsegurada, // Usar la versión formateada de la suma asegurada
      poliza.cat_monedas.nombre_moneda,
      poliza.notas_poliza,
    ];
  });


  return (

    <div>

      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '20px', marginBottom: '20px', padding: '20px' }}>
        {getCards}

      </div>
      <Group justify="flex-end" mb={10}>

        <Pagination
          total={Math.ceil(polizas.length / itemsPerPage)}
          siblings={1}
          withEdges
          value={currentPage}
          onChange={(newPage) => setCurrentPage(newPage)}
          color='#00497A'
        />
        <CsvDownloader
          filename={'polizas.csv'}
          separator=","
          wrapColumnChar=""
          datas={csvData}
          columns={csvHeaders}
        >
          <Button variant="light" color='#00497A' >Descargar Tabla</Button>
        </CsvDownloader>
      </Group>


    </div>
  );
}

export default PolizasTable;




/* Codigo 7 de marzo 2024 en formato Tabla

import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';



function PolizasTable({ polizas, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombrePoliza }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const tipopolizasFiltradas = filtroNombrePoliza
        ? polizas.filter(poliza => poliza.numero_poliza.toLowerCase().includes(filtroNombrePoliza.toLowerCase()))
        : polizas;

    const paginatedTransactions = tipopolizasFiltradas.slice(startIndex, endIndex);


    const handleSelectedTransaction = (poliza) => {
        console.log("Tipo poliza seleccionado:", poliza); // Agregar este console.log para verificar el contratante seleccionado
        setSelectedTransaction(poliza);
        setFormMode("edit");
        setShowForm(true);
    };

    const getRows = paginatedTransactions.map((poliza) => (
        <tr key={poliza.id_poliza}>
            <td>{poliza.id_poliza}</td>
            <td>{poliza.numero_poliza}</td>
            <td>{poliza.cat_contratantes ? poliza.cat_contratantes.nombre_contratante : ''}</td>
            <td>{poliza.cat_aseguradoras ? poliza.cat_aseguradoras.nombre_aseguradora : ''}</td>
            <td>{poliza.cat_tipos_seguros ? poliza.cat_tipos_seguros.nombre_tipo_seguro : ''}</td>
            <td>{poliza.cat_planes ? poliza.cat_planes.nombre_plan : ''}</td>
            <td>{poliza.cat_duraciones_pagos ? poliza.cat_duraciones_pagos.nombre_duracion_pago : ''}</td>




            <td>
                <Group justify="center">
                    <i
                        key={`edit-${poliza.id_poliza}`}
                        className='ri-pencil-line'
                        onClick={() => {
                            setSelectedTransaction(poliza);
                            setFormMode("edit");
                            setShowForm(true);
                            console.log("Tipo poliza seleccionado:", poliza);
                        }}
                    ></i>
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Número de Póliza',
        'Contratante',
        'Aseguradora',
        'Tipo seguro',
        'Plan'
    ];

    const csvData = polizas.map((poliza) => [
        poliza.id_poliza,
        poliza.numero_poliza,
        poliza.cat_contratantes ? poliza.cat_contratantes.nombre_contratante : '',

    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Número de Póliza</th>
                        <th>Nombre Contratante</th>
                        <th>Nombre Aseguradora</th>
                        <th>Tipo Seguro</th>
                        <th>Plan</th>
                        <th>Duración pagos</th>





                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(polizas.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {Agregar enlace para descargar CSV con react-csv-downloader}

                <CsvDownloader
                    filename={'polizas.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default PolizasTable;
*/