import { UnstyledButton, Group, Avatar, Text, rem } from '@mantine/core';
import classes from './UserButton.module.css';
import { useAuth } from '../context/AuthContext';



export function UserButton() {
    const { user, signOut } = useAuth();


    return (
        <UnstyledButton className={classes.user}>
            <Group>
                {user !== null && user.avatar_url !== null ? (
                    <Avatar src={user.avatar_url} radius="xl" />
                ) : (
                    ""
                )}
                <div style={{ flex: 1 }}>
                    <Text color='white' size="lg" fw={500}>
                        {user !== null ? user.username : ""}
                    </Text>
                    <Text c="dimmed" size="xs">
                        {user !== null ? user.email : ""}
                    </Text>
                </div>
            </Group>
        </UnstyledButton>

    );
}