import React, { useState } from 'react';
import { Table, Button, Group, Pagination, Badge } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import moment from "moment";
import { IconMail, IconExclamationCircle, IconHourglassLow, IconAlertTriangle, IconUrgent, IconXboxX, IconBrandWhatsapp } from '@tabler/icons-react';
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";


function obtenerMes(fecha) {
    const [anio, mes, dia] = fecha.split('-');
    const dateObject = new Date(anio, mes - 1, dia); // Restar 1 al mes porque los meses en JavaScript van de 0 a 11

    const mesNombre = dateObject.toLocaleString('es', { month: 'long' });
    const mesCapitalizado = mesNombre.charAt(0).toUpperCase() + mesNombre.slice(1);

    // Retornar el mes capitalizado sin espacios alrededor
    return mesCapitalizado.trim();
}

function PagosTable({ pagos, setSelectedTransaction, setFormMode, setShowForm, getData, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    const handleSelectedTransaction = (pago) => {
        setSelectedTransaction(pago);
        setFormMode("edit");
        setShowForm(true);
    };

    const handleDuplicatePago = async (id) => {
        const { data: pago, error } = await supabase
            .from('pagos_polizas')
            .select('*')
            .eq('id_pago_poliza', id)
            .single();

        if (error) {
            //console.error('Error fetching pago to duplicate:', error);
            return;
        }

        // Elimina el ID específico de la tabla 'pagos_polizas'
        delete pago.id_pago_poliza;

        const { data: newPago, error: insertError } = await supabase
            .from('pagos_polizas')
            .insert([pago]);

        if (insertError) {
            //console.error('Error duplicating pago:', insertError);
            return;
        }

        // Actualizar la vista con el nuevo registro duplicado
        getData(); // Llamar a la función getData para actualizar la lista de pagos
    };

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('pagos_polizas')
                .delete()
                .eq('id_pago_poliza', id);

            if (error) {
                throw new Error('No se pudo borrar el Pago');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Pago borrado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Pago',
                color: 'red'
            });
        }
    };


    const getRows = pagos.slice(startIndex, endIndex).map((pago) => {


        // Definir los iconos basado en los días_para_pago
        let icon = null;
        if (pago.dias_para_pago >= -1000 && pago.dias_para_pago <= -31) {
            icon = <IconXboxX color="red" />;
        } else if (pago.dias_para_pago >= -30 && pago.dias_para_pago < 0) {
            icon = <IconUrgent color="red" />;
        } else if (pago.dias_para_pago >= 1 && pago.dias_para_pago <= 7) {
            icon = <IconHourglassLow color="red" />;
        } else if (pago.dias_para_pago >= 8 && pago.dias_para_pago <= 30) {
            icon = <IconAlertTriangle color="#DBA203" />;
        } else if (pago.dias_para_pago > 30) {
            icon = <IconExclamationCircle color="green" />;
        }

        // Definir el estilo de la celda basado en la condición de 'ultimo'
        const notasCellStyle = pago.notas_pago.includes('ultimo')
            ? { backgroundColor: 'orange' }
            : {};

        return (
            <tr key={pago.id_pago_poliza}>
                <td>{pago.id_pago_poliza}</td>
                <td style={
                    pago.cat_estatus_pagos.nombre_estatus_pago === 'Pendiente' ? { backgroundColor: '#D5133A', color: 'white' } :
                        pago.cat_estatus_pagos.nombre_estatus_pago === 'Pagado' ? { backgroundColor: '#4CBB16', color: 'white' } :
                            pago.cat_estatus_pagos.nombre_estatus_pago === 'Pagado con retraso' ? { backgroundColor: '#E68C02', color: 'white' } : {}
                }>{pago.cat_estatus_pagos.nombre_estatus_pago}</td>
                <td>
                    {pago.pago_flexible === 2 ? (
                        <Badge color="blue">SI</Badge>
                    ) : (
                        <Badge color="red">NO</Badge>
                    )}
                </td>
                <td>{pago.polizas ? pago.polizas.numero_poliza : '-'}</td>

                <td>{pago.numero_endoso}</td>

                <td>{pago.polizas.cat_aseguradoras.nombre_aseguradora}</td>
                <td>{pago.polizas.cat_tipos_seguros.nombre_tipo_seguro}</td>

                <td>{pago.polizas.cat_contratantes.nombre_contratante}</td>
                <td>{pago.descripcion_pago}</td>
                <td>{pago.monto_pago ? `$ ${pago.monto_pago.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''}</td>
                <td>{pago.polizas.cat_monedas.nombre_moneda}</td>

                <td>{moment(pago.fecha_pago_requerida).format('DD-MM-YYYY')}</td>
                <td>{obtenerMes(pago.fecha_pago_requerida)}</td>
                <td>{pago.fecha_pago_real ? moment(pago.fecha_pago_real).format('DD-MM-YYYY') : '-'}</td>
                <td style={{ verticalAlign: 'middle', ...notasCellStyle }}>
                    {icon && (
                        <span style={{ display: 'inline-block', marginRight: '5px', verticalAlign: 'middle' }}>
                            {icon}
                        </span>
                    )}
                    {pago.dias_para_pago}
                </td>
                <td>
                    <Group justify="center">
                        {permisos &&
                            permisos.pagos &&
                            permisos.pagos.acciones &&
                            permisos.pagos.acciones.modificar_pago && (
                                <i
                                    key={`edit-${pago.id_pago_poliza}`}
                                    className='ri-pencil-line'
                                    onClick={() => handleSelectedTransaction(pago)}
                                ></i>
                            )}
                        {permisos &&
                            permisos.pagos &&
                            permisos.pagos.acciones &&
                            permisos.pagos.acciones.duplicar_pago && (

                                <i
                                    key={`duplicate-${pago.id_pago_poliza}`}
                                    className='ri-file-copy-2-line' //REMIX icons https://remixicon.com/
                                    onClick={() => {
                                        // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                        if (window.confirm('¿Estás seguro de duplicar este Pago?')) {
                                            handleDuplicatePago(pago.id_pago_poliza);
                                        }
                                    }}
                                ></i>
                            )}
                        {permisos &&
                            permisos.pagos &&
                            permisos.pagos.acciones &&
                            permisos.pagos.acciones.eliminar_pago && (

                                <i
                                    key={`delete-${pago.id_pago_poliza}`}
                                    className='ri-delete-bin-line'
                                    onClick={() => {
                                        // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                        if (window.confirm('¿Estás seguro de eliminar este Pago? Está acción no se puede deshacer.')) {
                                            deleteTransaction(pago.id_pago_poliza);
                                        }
                                    }}
                                ></i>
                            )}

                    </Group>
                </td>
            </tr>
        );
    });


    const csvHeaders = [
        'ID',
        'Estatus pago',
        'Flexible',
        'Póliza',
        'Endoso',
        'Aseguradora',
        'Tipo seguro',
        'Moneda',
        'Contratante',
        'Descripción pago',
        'Monto',
        'Fecha pago requerida',
        'Mes',
        'Fecha real de pago',
        'Días para pago'
    ];

    const csvHeadersWhatsApp = [
        'WhatsApp',
        'Mensaje',
    ];

    const csvHeadersGmail = [
        'contratante',
        'poliza',
        'asegurado',
        'pago',
        'moneda',
        'vigencia',
        'email',
    ];

    const csvData = pagos.map((pago) => [
        pago.id_pago_poliza,
        pago.cat_estatus_pagos.nombre_estatus_pago,
        pago.pago_flexible,
        pago.polizas.numero_poliza,
        pago.numero_endoso,
        pago.polizas.cat_aseguradoras.nombre_aseguradora,
        pago.polizas.cat_tipos_seguros.nombre_tipo_seguro,
        pago.polizas.cat_contratantes.nombre_contratante,

        pago.descripcion_pago,
        pago.monto_pago,
        pago.polizas.cat_monedas.nombre_moneda,
        pago.fecha_pago_requerida,
        obtenerMes(pago.fecha_pago_requerida),
        pago.fecha_pago_real === null ? "" : pago.fecha_pago_real,
        pago.dias_para_pago,
    ]);

    const csvDataWhatsApp = pagos.map((pago) => {
        // Agregar 30 días a la fecha de pago requerida
        const fechaVencimiento = moment(pago.fecha_pago_requerida).add(29, 'days').format('DD-MM-YYYY');

        let mensaje = '';

        if (pago.tipo_pago === 1) {
            mensaje = `Hola ${pago.polizas.cat_contratantes.nombre_contratante}. Hemos confirmado que el pago de tu póliza: ${pago.polizas.numero_poliza} con vencimiento ${moment(pago.fecha_pago_requerida).format('DD-MM-YYYY')} aún no se ha efectuado. Es necesario que realices el pago antes del ${fechaVencimiento}. Si tienes dudas al respecto, por favor entra en contacto con nuestra área especializada.`;
        } else if (pago.tipo_pago === 2) {
            mensaje = `Hola ${pago.polizas.cat_contratantes.nombre_contratante}. Hemos confirmado que el pago de endoso: ${pago.numero_endoso} de tu póliza: ${pago.polizas.numero_poliza} con vencimiento ${moment(pago.fecha_pago_requerida).format('DD-MM-YYYY')} aún no se ha efectuado. Es necesario que realices el pago antes del ${fechaVencimiento}. Si tienes dudas al respecto, por favor entra en contacto con nuestra área especializada.`;
        }

        // Retornar el número de teléfono y el mensaje
        return [
            `"${pago.polizas.cat_contratantes.telefono_celular}"`,
            `"${mensaje}"`
        ];
    });


    const csvDataGmail = pagos.map((pago) => [
        pago.polizas.cat_contratantes.nombre_contratante,
        pago.tipo_pago === 1 ? pago.polizas.numero_poliza : `Endoso ${pago.numero_endoso} de Poliza ${pago.polizas.numero_poliza}`,
        pago.polizas.nombre_asegurado,
        pago.monto_pago,
        pago.polizas.cat_monedas.nombre_moneda,
        pago.fecha_pago_requerida,
        pago.polizas.cat_contratantes.correo,
    ]);



    return (
        <div>
            <div>
                <Table
                    style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                    verticalSpacing="xl"
                    fontSize="sm"
                    mb={10}
                    striped
                    withRowBorders={true}
                    className="my-custom-table"
                >
                    <thead style={{ textAlign: 'left' }}>
                        <tr>
                            <th style={{ padding: '2px' }}>ID</th>
                            <th style={{ padding: '2px' }}>Estatus</th>
                            <th style={{ padding: '2px' }}>Flexible</th>

                            <th style={{ padding: '2px' }}>Póliza</th>
                            <th style={{ padding: '2px' }}>Endoso</th>

                            <th style={{ padding: '2px' }}>Aseguradora</th>
                            <th style={{ padding: '2px' }}>T. seguro</th>

                            <th style={{ padding: '2px' }}>Contratante</th>

                            <th style={{ padding: '2px' }}>Descripción</th>
                            <th style={{ padding: '2px' }}>Monto</th>
                            <th style={{ padding: '2px' }}>Moneda</th>

                            <th style={{ padding: '2px' }}>F. pago req</th>
                            <th style={{ padding: '2px' }}>Mes</th>
                            <th style={{ padding: '2px' }}>F. real pago</th>
                            <th style={{ padding: '2px' }}>Semáforo</th>
                            <th style={{ padding: '2px' }}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>{getRows}</tbody>
                </Table>

                <Group justify="flex-end" mb={10}>


                    <CsvDownloader
                        filename={'pagosW.csv'}
                        separator=","
                        wrapColumnChar=""
                        datas={csvDataWhatsApp}
                        columns={csvHeadersWhatsApp}
                    >
                        <Button leftSection={<IconBrandWhatsapp size={20} />} color="green" variant="light">
                            Descargar Cobranza WhatsApp
                        </Button>
                    </CsvDownloader>
                    <CsvDownloader
                        filename={'contactos.csv'}
                        separator=","
                        wrapColumnChar=""
                        datas={csvDataGmail}
                        columns={csvHeadersGmail}
                    >
                        <Button leftSection={<IconMail size={20} />} color="red" variant="light">
                            Descargar Cobranza Gmail
                        </Button>

                    </CsvDownloader>
                    <CsvDownloader
                        filename={'pagos.csv'}
                        separator=","
                        wrapColumnChar=""
                        datas={csvData}
                        columns={csvHeaders}
                    >
                        <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                    </CsvDownloader>
                    <Pagination
                        total={Math.ceil(pagos.length / itemsPerPage)}
                        siblings={1}
                        withEdges
                        value={currentPage}
                        onChange={(newPage) => setCurrentPage(newPage)}
                        color='#00497A'
                    />
                </Group>
            </div>
        </div>
    );
};

export default PagosTable;
