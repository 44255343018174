import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom'
import { Card, Group, Text, Menu, MenuItem, Button } from "@mantine/core";
import {
    IconClipboardText,
    IconHome,
    IconBook2,
    IconReportMoney,
    IconReportAnalytics,
    IconLogout
} from '@tabler/icons-react';

import { useAuth } from '../context/AuthContext';



function Header() {
    const { user, signOut } = useAuth();



    /*
    useEffect(() => {
        // Aquí puedes realizar acciones cuando el usuario cambie
        if (user) {
            console.log("Usuario autenticado:", user);
        } else {
            console.log("No hay usuario autenticado");
        }
    }, [user]);
    */

    const handleSignOut = async () => {
        try {
            await signOut();
            //console.log("has cerrado sesion")
        } catch (error) {
            //console.error("Error signing out:", error.message);
        }
    };

    return (
        <div>
            <Card
                shadow="md"
                withBorder
                p={20}
            >
                <div className="flex justify-between">
                    <Group >
                        <Text
                            size='xl'
                            variant="text"
                            c="blue"
                            weight="bold"
                        >
                            Polizas
                        </Text>
                        <NavLink to='/'>
                            <Button
                                color='#00497A'
                                variant="filled"
                                style={{ color: 'black', backgroundColor: 'white' }}
                                leftSection={<IconHome />}
                            >
                                Inicio
                            </Button>
                        </NavLink>
                        <Menu position="bottom-start" offset={3} shadow="md" width={200}>
                            <Menu.Target>
                                <Button
                                    color='#00497A'
                                    variant="filled"
                                    style={{ color: 'black', backgroundColor: 'white' }}
                                    leftSection={<IconBook2 />}
                                >
                                    Catálogos
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}> {/* Contenedor con scroll */}
                                    <NavLink to='/cattiposcontratantes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            1. Tipo contratantes
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catcontratantes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            2. Contratantes
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/cataseguradoras' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            3. Aseguradoras
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/cattiposseguros' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            4. Tipo de seguros
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catplanes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            5. Planes
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catformaspagos' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            6. Forma pagos
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catduracionpagos' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            7. Duración pagos
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catestatuspagos' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            8. Estatus de pago
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catestatuspolizas' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            9. Estatus póliza
                                        </Menu.Item>
                                    </NavLink>

                                    <NavLink to='/catmonedas' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            10. Monedas
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catconductoscobros' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            11. Conducto Cobro
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catusuarios' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            12. Usuarios
                                        </Menu.Item>
                                    </NavLink>






                                </div>
                            </Menu.Dropdown>

                        </Menu>
                        <NavLink to='/polizas'>
                            <Button
                                color='#00497A'
                                variant="filled"
                                style={{ color: 'black', backgroundColor: 'white' }}
                                leftSection={<IconClipboardText />}
                            >
                                Pólizas
                            </Button>
                        </NavLink>
                        <NavLink to='/pagos'>
                            <Button
                                color='#00497A'
                                variant="filled"
                                style={{ color: 'black', backgroundColor: 'white' }}
                                leftSection={<IconReportMoney />}
                            >
                                Pagos
                            </Button>
                        </NavLink>
                        <Menu position="bottom-start" offset={3} shadow="md" width={200}>

                            <Menu.Target>
                                <Button
                                    color='#00497A'
                                    variant="filled"
                                    style={{ color: 'black', backgroundColor: 'white' }}
                                    leftSection={<IconReportAnalytics />}
                                >
                                    Reportes
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}> {/* Contenedor con scroll */}
                                    <NavLink to='/reportecumpleaneros' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            1. Cumpleañeros
                                        </Menu.Item>
                                    </NavLink>
                                </div>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}> {/* Contenedor con scroll */}
                                    <NavLink to='/catreportelistaregalos' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            2. Lista Regalos
                                        </Menu.Item>
                                    </NavLink>
                                </div>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}> {/* Contenedor con scroll */}
                                    <NavLink to='/catpolizasnuevas' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            3. Pólizas Nuevas
                                        </Menu.Item>
                                    </NavLink>
                                </div>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}> {/* Contenedor con scroll */}
                                    <NavLink to='/catnivelcobranza' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            4. Nivel Cobranza
                                        </Menu.Item>
                                    </NavLink>
                                </div>


                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                    <Group>
                        {user && (
                            <Group>
                                {/* Mostrar el avatar si está disponible */}
                                {user.avatar_url && (
                                    <img src={user.avatar_url} alt="Avatar" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                )}
                                <span>{user.username || user.email}</span>

                                <button onClick={handleSignOut} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                    <IconLogout size={24} strokeWidth={2} color={"#000"} />
                                </button>
                            </Group>
                        )}
                    </Group>

                </div>


            </Card>
        </div >
    );
}

export default Header;

/*
Codigo 03 marzo 2024
import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom'
import { Card, Group, Text, Menu, MenuItem, Button } from "@mantine/core";
import {
    IconClipboardText,
    IconHome,
    IconBook2,
    IconReportMoney,
    IconReportAnalytics,
    IconLogout
} from '@tabler/icons-react';

import { useAuth } from '../context/AuthContext';



function Header() {
    const { user, signOut } = useAuth();


    const handleSignOut = async () => {
        try {
            await signOut();
            console.log("has cerrado sesion")
        } catch (error) {
            console.error("Error signing out:", error.message);
        }
    };

    return (
        <div>
            <Card
                shadow="md"
                withBorder
                p={20}
            >
                <div className="flex justify-between">
                    <Group >
                        <Text
                            size='xl'
                            variant="text"
                            c="blue"
                            weight="bold"
                        >
                            Polizas
                        </Text>
                        <NavLink to='/'>
                            <Button
                                color='#00497A'
                                variant="filled"
                                style={{ color: 'black', backgroundColor: 'white' }}
                                leftSection={<IconHome />}
                            >
                                Inicio
                            </Button>
                        </NavLink>
                        <Menu position="bottom-start" offset={3} shadow="md" width={200}>
                            <Menu.Target>
                                <Button
                                    color='#00497A'
                                    variant="filled"
                                    style={{ color: 'black', backgroundColor: 'white' }}
                                    leftSection={<IconBook2 />}
                                >
                                    Catálogos
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}> 
                                    <NavLink to='/cattiposcontratantes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            1. Tipo contratantes
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catcontratantes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            2. Contratantes
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/cataseguradoras' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            3. Aseguradoras
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/cattiposseguros' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            4. Tipo de seguros
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catplanes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            5. Planes
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catformaspagos' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            6. Forma pagos
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catduracionpagos' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            7. Duración pagos
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catcontratantes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            8. Estatus de pago
                                        </Menu.Item>
                                    </NavLink>
                                    <NavLink to='/catcontratantes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            9. Estatus póliza
                                        </Menu.Item>
                                    </NavLink>

                                    <NavLink to='/catcontratantes' style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Menu.Item>
                                            10. Monedas
                                        </Menu.Item>
                                    </NavLink>





                                </div>
                            </Menu.Dropdown>

                        </Menu>
                        <NavLink to='/'>
                            <Button
                                color='#00497A'
                                variant="filled"
                                style={{ color: 'black', backgroundColor: 'white' }}
                                leftSection={<IconClipboardText />}
                            >
                                Pólizas
                            </Button>
                        </NavLink>
                        <NavLink to='/'>
                            <Button
                                color='#00497A'
                                variant="filled"
                                style={{ color: 'black', backgroundColor: 'white' }}
                                leftSection={<IconReportMoney />}
                            >
                                Pagos
                            </Button>
                        </NavLink>
                        <NavLink to='/'>
                            <Button
                                color='#00497A'
                                variant="filled"
                                style={{ color: 'black', backgroundColor: 'white' }}
                                leftSection={<IconReportAnalytics />}
                            >
                                Reportes
                            </Button>
                        </NavLink>
                    </Group>
                    <Group>
                        {user && (
                            <Group>
                                <span>{user.username || user.email}</span>
                                {user.avatar_url && (
                                    <img src={user.avatar_url} alt="Avatar" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                )}
                                <Button color="red" onClick={handleSignOut}>
                                    Cerrar sesión
                                </Button>
                            </Group>
                        )}
                    </Group>

                </div>


            </Card>
        </div >
    );
}

export default Header;
*/