//import '@mantine/dates/styles.css';
import React, { useEffect } from 'react';
import { Group, Select, Autocomplete, TextInput, Checkbox } from '@mantine/core';
import { useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { supabase } from "../supabaseClient";
import { IconCalendar } from '@tabler/icons-react';
import { rem } from '@mantine/core';






function FiltrosPagos({ setFilters, filters, onEnterPress }) {

    const icon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;


    const [estatusPagos, setEstatusPagos] = useState([]);
    const [polizas, setPolizas] = useState([]);
    const [aseguradoras, setAseguradora] = useState([]);
    const [contratantes, setContratantes] = useState([]);
    const [endosos, setEndosos] = useState('');
    const [tipopago, setTipopago] = useState([]);
    const [tiposeguros, setTiposseguros] = useState([]);

    //select para flex
    const handleSelectChange = (value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            pago_flexible: value
        }));
    };
    // termina codigo para flex

    // nuevo junio 2024

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onEnterPress(); // Llama a la función getData pasada como prop
        }
    };

    const normalizeText = (text) => {
        return text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""); // Elimina los diacríticos (acentos)
    };

    const handleNotaChange = (event) => {
        const normalizedValue = normalizeText(event.currentTarget.value);
        setFilters({ ...filters, notas_pago: normalizedValue });
    };
    // nuevo junio 2024





    const [fechaInicial, setFechaInicial] = useState(null);
    const [fechaFinal, setFechaFinal] = useState(null);


    useEffect(() => {
        const fetchEstatusPagos = async () => {
            try {
                // Consulta para select de Estatus de Pago
                const { data: estatusData, error: estatusError } = await supabase
                    .from('cat_estatus_pagos')
                    .select('nombre_estatus_pago');

                if (estatusError) {
                    throw estatusError;
                }

                const estatusOptions = estatusData.map((estatus) => ({
                    label: estatus.nombre_estatus_pago,
                    value: estatus.nombre_estatus_pago
                }));

                setEstatusPagos(estatusOptions);
            } catch (error) {
                //console.error('Error al obtener los estatus de pago:', error.message);
            }
        };

        const fetchPolizasData = async () => {
            try {
                // Consulta para select de Pólizas
                const { data: polizasData, error: polizasError } = await supabase
                    .from('polizas')
                    .select('numero_poliza')
                    .order('numero_poliza', { ascending: true });



                if (polizasError) {
                    throw polizasError;
                }

                const polizasOptions = polizasData.map((poliza) => ({
                    label: poliza.numero_poliza,
                    value: poliza.numero_poliza
                }));

                setPolizas(polizasOptions);
            } catch (error) {
                //console.error('Error al obtener las pólizas:', error.message);
            }
        };

        const fetchAseguradorasData = async () => {
            try {
                // Consulta para select de Pólizas
                const { data: polizasData, error: polizasError } = await supabase
                    .from('cat_aseguradoras')
                    .select('nombre_aseguradora')
                    .order('nombre_aseguradora', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const aseguradorasOptions = polizasData.map((aseguradora) => ({
                    label: aseguradora.nombre_aseguradora,
                    value: aseguradora.nombre_aseguradora
                }));

                setAseguradora(aseguradorasOptions);
            } catch (error) {
                //console.error('Error al obtener las aseguradoras:', error.message);
            }
        };

        const fetchContratantesData = async () => {
            try {
                // Consulta para select de Pólizas
                const { data: contratantesData, error: contratantesError } = await supabase
                    .from('cat_contratantes')
                    .select('nombre_contratante')
                    .order('nombre_contratante', { ascending: true });
                if (contratantesError) {
                    throw contratantesError;
                }

                const contratantesOptions = contratantesData.map((contratante) => ({
                    label: contratante.nombre_contratante,
                    value: contratante.nombre_contratante
                }));

                setContratantes(contratantesOptions);
            } catch (error) {
                //console.error('Error al obtener los contratantes:', error.message);
            }
        };

        const fetchTipoPagoData = async () => {
            try {
                // Opciones para tipo_pago
                const tipopagoOptions = [
                    {
                        label: 'Polizas',
                        value: '1'
                    },
                    {
                        label: 'Pagos endosos',
                        value: '2'
                    }
                ];

                setTipopago(tipopagoOptions);
            } catch (error) {
                // Manejo de errores
            }
        };

        // se agrega el tipo de seguro
        const fetchTipoSeguroData = async () => {
            try {
                // Consulta para select de Pólizas
                const { data: tipossegurosData, error: tipossegurosError } = await supabase
                    .from('cat_tipos_seguros')
                    .select('nombre_tipo_seguro')
                    .order('nombre_tipo_seguro', { ascending: true });
                if (tipossegurosError) {
                    throw tipossegurosError;
                }

                const tipossegurosOptions = tipossegurosData.map((tiposeguros) => ({
                    label: tiposeguros.nombre_tipo_seguro,
                    value: tiposeguros.nombre_tipo_seguro
                }));

                setTiposseguros(tipossegurosOptions);
            } catch (error) {
                //console.error('Error al obtener los contratantes:', error.message);
            }
        };

        fetchEstatusPagos();
        fetchPolizasData();
        fetchAseguradorasData();
        fetchContratantesData();
        fetchTipoPagoData();
        fetchTipoSeguroData();
    }, []);

    const handleDateChange = (field, date) => {
        if (field === 'fechaInicial') {
            setFilters({ ...filters, fechaInicial: date });
        } else if (field === 'fechaFinal') {
            setFilters({ ...filters, fechaFinal: date });
        }
    };

    const handleEndosoChange = (value) => {
        setEndosos(value); // Actualizar el estado de endosos
        setFilters({ ...filters, numero_endoso: value }); // Actualizar el filtro numero_endoso
    };

    return (
        <div onKeyDown={handleKeyDown}
            style={{
                //display: 'flex',
                //flexWrap: 'wrap',
                marginBottom: '10px',
                //justifyContent: 'center', // Centra horizontalmente
                //alignItems: 'center', // Centra verticalmente
            }}>
            <Group style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Select
                    label="Estatus de Pago"
                    placeholder="Selecciona estatus"
                    data={estatusPagos}
                    value={filters.estatus_pago}
                    onChange={(value) => setFilters({ ...filters, estatus_pago: value })}
                    name="estatus_pago"
                    clearable
                    style={{ width: '300px', maxWidth: '200px' }}

                //style={{ flex: '1 1 auto', marginRight: '1px' }} // Ancho flexible y margen derecho
                //style={{ width: '300px', marginRight: '5px' }} // Ancho de 300px y margen de 5px

                />
                <Select
                    label="Tipo de Pago"
                    placeholder="Selecciona tipo"
                    data={tipopago}
                    value={filters.tipo_pago}
                    onChange={(value) => setFilters({ ...filters, tipo_pago: value })}
                    name="tipo_pago"
                    clearable
                    style={{ width: '300px', maxWidth: '200px' }}
                />
                <Autocomplete
                    label="Póliza"
                    placeholder="Selecciona póliza"
                    data={polizas}
                    value={filters.numero_poliza}
                    onChange={(value) => setFilters({ ...filters, numero_poliza: value })}
                    name="numero_poliza"
                    style={{ width: '300px', maxWidth: '200px' }}
                />
                <TextInput
                    label="Endoso"
                    placeholder="Selecciona endoso"
                    value={endosos}
                    onChange={(event) => handleEndosoChange(event.target.value)} // Llama a la función handleEndosoChange
                    name="numero_endoso"
                    style={{ width: '300px', maxWidth: '200px' }}
                />

                <Autocomplete
                    label="Aseguradora"
                    placeholder="Selecciona Aseguradora"
                    data={aseguradoras}
                    value={filters.nombre_aseguradora}
                    onChange={(value) => setFilters({ ...filters, nombre_aseguradora: value })}
                    name="nombre_aseguradora"
                    style={{ width: '300px', maxWidth: '200px' }}
                />
                <Autocomplete
                    label="Tipo Seguro"
                    placeholder="Tipo seguro"
                    data={tiposeguros}
                    value={filters.nombre_tipo_seguro}
                    onChange={(value) => setFilters({ ...filters, nombre_tipo_seguro: value })}
                    name="nombre_tipo_seguro"
                    style={{ width: '300px', maxWidth: '200px' }}
                />
                <Autocomplete
                    label="Contratante"
                    placeholder="Selecciona Contratante"
                    data={contratantes}
                    value={filters.nombre_contratante}
                    onChange={(value) => setFilters({ ...filters, nombre_contratante: value })}
                    name="nombre_contratante"
                    style={{ width: '200px', maxWidth: '200px' }}
                />

                <DatePickerInput
                    label="F. Req de Pago Inicial"
                    placeholder="Fecha Inicial"
                    leftSection={icon}
                    value={filters.fechaInicial}
                    onChange={(date) => handleDateChange('fechaInicial', date)}
                    clearable
                    style={{ width: '180px', maxWidth: '200px' }}
                />
                <DatePickerInput
                    label="F. Req de Pago Final"
                    placeholder="Fecha Final"
                    leftSection={icon}

                    value={filters.fechaFinal}
                    onChange={(date) => handleDateChange('fechaFinal', date)}
                    clearable
                    style={{ width: '180px', maxWidth: '200px' }}
                />
                <TextInput
                    label="Buscar en notas"
                    style={{ width: '150px', maxWidth: '200px' }}
                    placeholder="Nota"
                    value={filters.notas_pago}
                    //onChange={(event) => setFilters({ ...filters, notas_pago: event.currentTarget.value })}
                    onChange={handleNotaChange}
                />
                <Select
                    label="Pago Flexible"
                    placeholder="Selecciona una opción"
                    value={filters.pago_flexible}
                    onChange={handleSelectChange}
                    data={[
                        { value: '', label: 'Todos' },
                        { value: '1', label: 'Sin Pago Flexible' },
                        { value: '2', label: 'Con Pago Flexible' },
                    ]}
                    style={{ width: '150px', maxWidth: '200px' }}

                />
            </Group>
        </div>
    );
}

export default FiltrosPagos;
