import React, { useState } from 'react';
import { Table, Button, Group, Pagination, Input, Divider, List } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";

function ListaRegalosTable({ polizas }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const dispatch = useDispatch();

  const [conversionFactors, setConversionFactors] = useState({
    //MXN: 1,
    UDIS: 7,
    DLLS: 20,
  });

  const [val1, setVal1] = useState(150000); // Valor de clasificación A
  const [val2, setVal2] = useState(51000); // Valor de clasificación B

  // Verificar que polizas sea un array y contenga datos
  if (!Array.isArray(polizas) || polizas.length === 0) {
    return <div>Sin información para mostrar</div>;
  }

  // Función para obtener el factor de conversión según la moneda
  const getConversionFactor = (moneda) => {
    return conversionFactors[moneda] || 1;
  };

  // Función para clasificar la prima
  const clasificarPrima = (prima) => {
    if (prima > val1) {
      return 'A';
    } else if (prima > val2 && prima <= val1) {
      return 'B';
    } else {
      return 'C';
    }
  };

  // Agrupar por nombre_contratante, contar id_poliza y sumar prima
  const polizasAgrupadas = polizas.reduce((acc, poliza) => {
    const { id_poliza, prima, cat_contratantes, cat_monedas } = poliza;
    const nombre_contratante = cat_contratantes ? cat_contratantes.nombre_contratante : 'Sin Contratante';
    const nombre_moneda = cat_monedas ? cat_monedas.nombre_moneda : 'Sin Moneda';
    const factorConversion = getConversionFactor(nombre_moneda);
    const primaConvertida = prima * factorConversion; // Aplicar factor de conversión

    if (!acc[nombre_contratante]) {
      acc[nombre_contratante] = { count: 0, totalPrima: 0 };
    }
    acc[nombre_contratante].count += 1;
    acc[nombre_contratante].totalPrima += primaConvertida;
    return acc;
  }, {});

  // Convertir a matriz y ordenar por suma de primas de manera descendente
  const polizasAgrupadasArray = Object.entries(polizasAgrupadas)
    .sort(([, a], [, b]) => b.totalPrima - a.totalPrima);

  // Obtener las polizas para la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const paginatedTransactions = polizasAgrupadasArray.slice(startIndex, endIndex);

  const getRows = paginatedTransactions.map(([nombre_contratante, datos]) => {
    let colorFondo, colorTexto;

    switch (clasificarPrima(datos.totalPrima)) {
      case 'A':
        colorFondo = '#4CBB16';
        colorTexto = 'white';
        break;
      case 'B':
        colorFondo = '#E68C02';
        colorTexto = 'white';
        break;
      case 'C':
        colorFondo = '#808080';
        colorTexto = 'white';
        break;
      default:
        colorFondo = 'white';
        colorTexto = 'black';
    }

    const estiloCelda = {
      backgroundColor: colorFondo,
      color: colorTexto
    };

    return (
      <tr key={nombre_contratante}>
        <td>{nombre_contratante}</td>
        <td>{datos.count}</td>
        <td>$ {datos.totalPrima.toLocaleString('es-MX', { minimumFractionDigits: 2 })}</td>
        <td style={estiloCelda}>{clasificarPrima(datos.totalPrima)}</td>
      </tr>
    );
  });

  const csvHeaders = [
    'Nombre Contratante',
    'Cantidad de Pólizas',
    'Total Prima',
    'Clasificación',

  ];

  const csvData = paginatedTransactions.map(([nombre_contratante, datos]) => [
    nombre_contratante,
    datos.count,
    datos.totalPrima.toLocaleString('es-MX', { minimumFractionDigits: 2 }).replace(/,/g, ''),
    clasificarPrima(datos.totalPrima),

  ]);


  return (
    <div>
      <Group justify="flex-end" mb={10}>
        <Pagination
          total={Math.ceil(polizasAgrupadasArray.length / itemsPerPage)}
          siblings={1}
          withEdges
          value={currentPage}
          onChange={(newPage) => setCurrentPage(newPage)}
          color='#00497A'
        />
        <CsvDownloader
          filename={'regalos.csv'}
          separator=","
          wrapColumnChar=""
          datas={csvData}
          columns={csvHeaders}
        >
          <Button variant="light" color='#00497A' >Descargar Tabla</Button>
        </CsvDownloader>
      </Group>
      <Group justify='center'>

      <div style={{
        display: 'flex',
        //flexWrap: 'wrap',
        marginBottom: '20px',
        justifyContent: 'center', // Centra horizontalmente
        //alignItems: 'center', // Centra verticalmente
      }}>

        {Object.entries(conversionFactors).map(([moneda, factor]) => (
          <div key={moneda}>
            <Input.Wrapper label={`Factor conversión  ${moneda}: `} >
              <Input
                style={{ flex: '1 1 auto', marginRight: '5px' }} // Ancho flexible y margen derecho

                type="number"
                value={factor}
                onChange={(e) =>
                  setConversionFactors({
                    ...conversionFactors,
                    [moneda]: parseFloat(e.target.value),
                  })
                }
              />
            </Input.Wrapper>
          </div>
        ))}
      </div>
      
      <div style={{
        display: 'flex',
        marginBottom: '20px',
        justifyContent: 'center',
      }}>

        <Input.Wrapper label="Límite 2: ">
          <Input
            style={{ marginRight: '5px' }}
            type="number"
            value={val2}
            onChange={(e) => setVal2(parseFloat(e.target.value))}
          />
        </Input.Wrapper>
        <Input.Wrapper label="Límite 1: ">
          <Input
            style={{ marginRight: '5px' }}
            type="number"
            value={val1}
            onChange={(e) => setVal1(parseFloat(e.target.value))}
          />
        </Input.Wrapper>
      </div>
        <List size="sm">
          <List.Item>De 0 a {val2}: Categoría C</List.Item>
          <List.Item>De {val2} a {val1}: Categoría B </List.Item>
          <List.Item>Mayor a {val1}: Categoría A </List.Item>

        </List>


      <Table
        style={{ textAlign: 'left', borderCollapse: 'collapse' }}
        verticalSpacing="xl"
        fontSize="sm"
        mb={10}
        striped
        withRowBorders={true}
        className="my-custom-table"
      >
        <thead style={{ textAlign: 'left' }}>
          <tr>
            <th>Nombre Contratante</th>
            <th>Cantidad de Pólizas</th>
            <th>Suma Prima en MXN</th>
            <th>Clasificación</th>
          </tr>
        </thead>
        <tbody>{getRows}</tbody>
      </Table>
      </Group>

    </div>
  );
}

export default ListaRegalosTable;
