import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button, } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";



function DuracionesPagosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    //const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        nombre_duracion_pago: "",
        id_duracion_pago: "" // inicializar

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_duracion_pago: transactionData.nombre_duracion_pago,
                id_duracion_pago: transactionData.id_duracion_pago

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateAseguradora = async () => {

        try {
            await supabase
                .from('cat_duraciones_pagos')
                .update({
                    nombre_duracion_pago: user.nombre_duracion_pago
                })
                .eq('id_duracion_pago', user.id_duracion_pago); //Este método se utiliza para filtrar las filas en una tabla donde el valor de una columna específica es igual a un valor dado.

            showNotification({
                title: 'Duración de pago actualizada',
                message: 'La duración de pago se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar la duración de pago:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la duración de pago.',
                color: 'red',
            });
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            if (formMode === 'add') {
                // Buscar el id_tipo_contratante correspondiente al nombre_tipo_contratante seleccionado

                const { error } = await supabase
                    .from('cat_duraciones_pagos')
                    .insert({
                        nombre_duracion_pago: user.nombre_duracion_pago
                    });
                    showNotification({
                        title: 'Duración de pago agregada',
                        message: 'La duración de pago ha sido agregada con éxito',
                        color: 'green',
                    });

                if (error) {
                    throw error;
                }
            } else if (formMode === 'edit') {
                await handleUpdateAseguradora();
            }
            setShowForm(false);
            getData();
        } catch (error) {
            console.log(error)
            console.error('Error al agregar/actualizar la duración de pago:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la duración de pago.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group grow>
                    <Stack>
                        
                            <TextInput
                                disabled
                                name="id_duracion_pago"
                                label="ID"
                                onChange={handleChange}
                                style={{ width: '300px' }}
                                value={user.id_duracion_pago}

                            />

                            <TextInput
                                name="nombre_duracion_pago"
                                label="Nombre Duración de pago"
                                style={{ width: '300px' }}
                                required
                                onChange={handleChange}
                                value={user.nombre_duracion_pago} // Asignar el valor del estado al campo
                                placeholder='Nombre Duración de pago'
                               
                            />



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Duración de pago" : "Editar Duración de pago"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default DuracionesPagosForm;