import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Input, Group, Select, Button, NativeSelect, Collapse, Autocomplete, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDispatch } from 'react-redux';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import {
    IconNotes,
    IconUser,
    IconDotsCircleHorizontal,
    IconBuildingBank,
} from '@tabler/icons-react';



function PolizasForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        id_poliza: "",
        numero_poliza: "",
        id_contratante: "",
        nombre_contratante: "",
        id_aseguradora: "",
        nombre_aseguradora: "",
        id_tipo_seguro: "",
        nombre_tipo_seguro: "",
        id_plan: "",
        nombre_plan: "",
        id_duracion_pago: "",
        nombre_duracion_pago: "",
        id_forma_pago: "",
        nombre_forma_pago: "",
        id_estatus_poliza: "",
        nombre_estatus_poliza: "",
        id_moneda: "",
        nombre_moneda: "",
        prima: "",
        suma_asegurada: "",
        fecha_emision: "",
        nombre_asegurado: "",
        fecha_nacimiento_asegurado: "",
        fecha_vigencia: "",
        notas_poliza: "",
    })

    // Este useEffect es porque en CatalogosPoliza ya tomo el valor de ids de todos los catalogos, entonces
    // este codigo evita que lo ponga en "" y me marque un warning de que se cambia un valor definido a un ""
    useEffect(() => {
        if (!user.id_poliza) {
            setUser({
                id_poliza: "",
                numero_poliza: "",
                id_contratante: "",
                nombre_contratante: "",
                id_aseguradora: "",
                nombre_aseguradora: "",
                id_tipo_seguro: "",
                nombre_tipo_seguro: "",
                id_plan: "",
                nombre_plan: "",
                id_duracion_pago: "",
                nombre_duracion_pago: "",
                id_forma_pago: "",
                nombre_forma_pago: "",
                id_estatus_poliza: "",
                nombre_estatus_poliza: "",
                id_moneda: "",
                nombre_moneda: "",
                prima: "",
                suma_asegurada: "",
                fecha_emision: "",
                nombre_asegurado: "",
                fecha_nacimiento_asegurado: "",
                fecha_vigencia: "",
                notas_poliza: "",
            });
        }
    }, [user.id_poliza]);



    // Codigo para agregar al select las opciones de tipo de contratante, segun cat_tipo_contratante
    const [selectedTipoContratante, setSelectedTipoContratante] = useState(""); //se pone persona fisica por default al agregar contratante
    const [tiposContratante, setTiposContratante] = useState([]); // para los contratantes
    const [tiposAseguradora, setTiposAseguradora] = useState([]); // para las aseguradoras
    const [selectedTipoAseguradora, setSelectedTipoAseguradora] = useState(""); // para aseguradoras
    const [tiposTipoSeguro, setTiposTipoSeguro] = useState([]); // para tipo seguro
    const [selectedTipoTipoSeguro, setSelectedTipoTipoSeguro] = useState(""); // para tipo seguro
    const [tiposPlan, setTiposPlan] = useState([]); // para plan
    const [selectedPlan, setSelectedPlan] = useState(""); // para plan
    const [tiposDpago, setTiposDpago] = useState([]); // para duracion pago
    const [selectedDpago, setSelectedDpago] = useState(""); // para duracion pago
    const [formasDpago, setFormasPago] = useState([]); // para forma pago
    const [selectedFormaPago, setSelectedFormaPago] = useState(""); // para forma pago
    const [tiposEstatusPoliza, setTiposEstatusPoliza] = useState([]); // para estatus poliza
    const [selectedEstatusPoliza, setSelectedEstatusPoliza] = useState(""); // para estatus poliza
    const [tiposMoneda, setTiposMoneda] = useState([]); // para moneda
    const [selectedMoneda, setSelectedMoneda] = useState(""); // para moneda



    useEffect(() => {
        async function fetchTiposContratante() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_contratantes')
                    .select('id_contratante, nombre_contratante')
                    .order('nombre_contratante', { ascending: true });

                setTiposContratante(tipos);
                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error al obtener los tipos de contratante:', error.message);
            }
        }

        async function fetchTiposAseguradoras() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_aseguradoras')
                    .select('id_aseguradora, nombre_aseguradora')
                    .order('nombre_aseguradora', { ascending: true });

                setTiposAseguradora(tipos);
                //console.log('Tipos de aseguradoras:', tipos);
            } catch (error) {
                console.error('Error al obtener los tipos de aseguradoras:', error.message);
            }
        }

        async function fetchTiposTipoSeguro() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_tipos_seguros')
                    .select('id_tipo_seguro, nombre_tipo_seguro')
                    .order('nombre_tipo_seguro', { ascending: true });


                setTiposTipoSeguro(tipos);
                //console.log('Tipos de tipo seguros:', tipos);
            } catch (error) {
                console.error('Error al obtener los tipos de tipos seguros:', error.message);
            }
        }

        async function fetchPlanes() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_planes')
                    .select('id_plan, nombre_plan')
                    .order('nombre_plan', { ascending: true });


                setTiposPlan(tipos);
                //console.log('Planes:', tipos);
            } catch (error) {
                //console.error('Error al obtener los tipos de plan:', error.message);
            }
        }

        async function fetchDpago() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_duraciones_pagos')
                    .select('id_duracion_pago, nombre_duracion_pago')
                    .order('nombre_duracion_pago', { ascending: true });


                setTiposDpago(tipos);
                //console.log('Dpago:', tipos);
            } catch (error) {
                //console.error('Error al obtener Dpago:', error.message);
            }
        }

        async function fetchFormasPagos() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_formas_pagos')
                    .select('id_forma_pago, nombre_forma_pago')
                    .order('nombre_forma_pago', { ascending: true });


                setFormasPago(tipos);
                //console.log('Dpago:', tipos);
            } catch (error) {
                //console.error('Error al obtener Dpago:', error.message);
            }
        }

        async function fetchEstatusPolizas() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_estatus_polizas')
                    .select('id_estatus_poliza, nombre_estatus_poliza')
                    .order('nombre_estatus_poliza', { ascending: true });


                setTiposEstatusPoliza(tipos);
                //console.log('Dpago:', tipos);
            } catch (error) {
                //console.error('Error al obtener estatus Polizas:', error.message);
            }
        }

        async function fetchEstatusMonedas() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_monedas')
                    .select('id_moneda, nombre_moneda')
                    .order('nombre_moneda', { ascending: true });


                setTiposMoneda(tipos);
                //console.log('Dpago:', tipos);
            } catch (error) {
                //console.error('Error al obtener estatus Polizas:', error.message);
            }
        }

        fetchTiposContratante();
        fetchTiposAseguradoras();
        fetchTiposTipoSeguro();
        fetchPlanes();
        fetchDpago();
        fetchFormasPagos();
        fetchEstatusPolizas();
        fetchEstatusMonedas();
    }, []);


    //para capturar lo que escriben
    // esta linea la quito chatgpt
    //function handleChange(event) {
    //    setUser(prevFormData => {
    //        return {
    //            ...prevFormData,
    //            [event.target.name]: event.target.value
    //        }
    //    })
    //};

    useEffect(() => {
        //console.log("Transaction data:", transactionData);
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                id_poliza: transactionData.id_poliza,
                numero_poliza: transactionData.numero_poliza,
                id_aseguradora: transactionData.cat_aseguradoras ? transactionData.cat_aseguradoras.id_aseguradora : "",
                nombre_aseguradora: transactionData.nombre_aseguradora,
                id_tipo_seguro: transactionData.cat_tipos_seguros ? transactionData.cat_tipos_seguros.id_tipo_seguro : "",
                nombre_tipo_seguro: transactionData.nombre_tipo_seguro,
                id_plan: transactionData.cat_planes ? transactionData.cat_planes.id_plan : "",
                nombre_plan: transactionData.nombre_plan,
                id_duracion_pago: transactionData.cat_duraciones_pagos ? transactionData.cat_duraciones_pagos.id_duracion_pago : "",
                nombre_duracion_pago: transactionData.nombre_duracion_pago,
                id_forma_pago: transactionData.cat_formas_pagos ? transactionData.cat_formas_pagos.id_forma_pago : "",
                nombre_forma_pago: transactionData.nombre_forma_pago,
                id_estatus_poliza: transactionData.cat_estatus_polizas ? transactionData.cat_estatus_polizas.id_estatus_poliza : "",
                nombre_estatus_poliza: transactionData.nombre_estatus_poliza,
                id_moneda: transactionData.cat_monedas ? transactionData.cat_monedas.id_moneda : "",
                nombre_moneda: transactionData.nombre_moneda,
                prima: transactionData.prima,
                suma_asegurada: transactionData.suma_asegurada,
                fecha_emision: transactionData.fecha_emision,
                nombre_asegurado: transactionData.nombre_asegurado,
                fecha_nacimiento_asegurado: transactionData.fecha_nacimiento_asegurado,
                fecha_vigencia: transactionData.fecha_vigencia,
                notas_poliza: transactionData.notas_poliza,
            });

            const contratanteEncontrado = tiposContratante.find(t => t.id_contratante === transactionData.cat_contratantes.id_contratante);
            if (contratanteEncontrado) {
                setSelectedTipoContratante(contratanteEncontrado.id_contratante);
            }

            if (transactionData.cat_aseguradoras && tiposAseguradora.length > 0) {
                const aseguradoraEncontrada = tiposAseguradora.find(t => t.id_aseguradora === transactionData.cat_aseguradoras.id_aseguradora);
                if (aseguradoraEncontrada) {
                    setSelectedTipoAseguradora(aseguradoraEncontrada.id_aseguradora);
                }
            }

            if (transactionData.cat_tipos_seguros && tiposTipoSeguro.length > 0) {
                const tipoSeguroEncontrado = tiposTipoSeguro.find(t => t.id_tipo_seguro === transactionData.cat_tipos_seguros.id_tipo_seguro);
                if (tipoSeguroEncontrado) {
                    setSelectedTipoTipoSeguro(tipoSeguroEncontrado.id_tipo_seguro);
                }
            }

            if (transactionData.cat_planes && tiposPlan.length > 0) {
                const tipoPlanEncontrado = tiposPlan.find(t => t.id_plan === transactionData.cat_planes.id_plan);
                if (tipoPlanEncontrado) {
                    setSelectedPlan(tipoPlanEncontrado.id_plan);
                }
            }

            if (transactionData.cat_duraciones_pagos && tiposDpago.length > 0) {
                const tipoDPencontrado = tiposDpago.find(t => t.id_duracion_pago === transactionData.cat_duraciones_pagos.id_duracion_pago);
                if (tipoDPencontrado) {
                    setSelectedDpago(tipoDPencontrado.id_duracion_pago);
                }
            }

            if (transactionData.cat_formas_pagos && formasDpago.length > 0) {
                const tipoFormasPencontrado = formasDpago.find(t => t.id_forma_pago === transactionData.cat_formas_pagos.id_forma_pago);
                if (tipoFormasPencontrado) {
                    setSelectedFormaPago(tipoFormasPencontrado.id_forma_pago);
                }
            }

            if (transactionData.cat_estatus_polizas && tiposEstatusPoliza.length > 0) {
                const tiposEstatusPolizaEncontrados = tiposEstatusPoliza.find(t => t.id_estatus_poliza === transactionData.cat_estatus_polizas.id_estatus_poliza);
                if (tiposEstatusPolizaEncontrados) {
                    setSelectedEstatusPoliza(tiposEstatusPolizaEncontrados.id_estatus_poliza);
                }
            }

            if (transactionData.cat_monedas && tiposMoneda.length > 0) {
                const tiposMonedaEncontrados = tiposMoneda.find(t => t.id_moneda === transactionData.cat_monedas.id_moneda);
                if (tiposMonedaEncontrados) {
                    setSelectedMoneda(tiposMonedaEncontrados.id_moneda);
                }
            }

            //console.log("Transaction data2:", transactionData);
        }
    }, [formMode, transactionData, tiposContratante, tiposAseguradora, tiposTipoSeguro, tiposPlan, tiposDpago, formasDpago, tiposEstatusPoliza, tiposMoneda]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handlers = {
        id_contratante: setSelectedTipoContratante,
        id_aseguradora: setSelectedTipoAseguradora,
        id_tipo_seguro: setSelectedTipoTipoSeguro,
        id_plan: setSelectedPlan,
        id_duracion_pago: setSelectedDpago,
        id_forma_pago: setSelectedFormaPago,
        id_estatus_poliza: setSelectedEstatusPoliza,
        id_moneda: setSelectedMoneda,
    };
    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        const handler = handlers[name];
        if (handler) {
            handler(value);
        }
    };

    /*
    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        if (name === "id_contratante") {
            setSelectedTipoContratante(value);
        } else if (name === "id_aseguradora") {
            setSelectedTipoAseguradora(value);
        } else if (name === "id_tipo_seguro") {
            setSelectedTipoTipoSeguro(value);
        } else if (name === "id_plan") {
            setSelectedPlan(value);
        } else if (name === "id_duracion_pago") {
            setSelectedDpago(value);
        } else if (name === "id_forma_pago") {
            setSelectedFormaPago(value);
        } else if (name === "id_estatus_poliza") {
            setSelectedEstatusPoliza(value);
        }
        else if (name === "id_moneda") {
            setSelectedMoneda(value);
        }
    };
    */

    const handleUpdateContratante = async () => {

        try {

            // Primero, obtenemos la clave_contratante actual del contratante que se está editando
            const { data: polizaActual, error: polizaError } = await supabase
                .from('polizas')
                .select('numero_poliza')
                .eq('id_poliza', user.id_poliza)
                .single();

            if (polizaError) {
                throw polizaError;
            }

            // Verificamos si la clave_contratante se ha modificado
            if (user.numero_poliza !== polizaActual.numero_poliza) {
                // Si la clave_contratante ha cambiado, verificamos si ya existe en otro registro
                const { data, error } = await supabase
                    .from('polizas')
                    .select('numero_poliza')
                    .eq('numero_poliza', user.numero_poliza)
                    .limit(1);

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'No se actualizó la Póliza, ya que el número de Póliza ya existe.',
                        color: 'red',
                    });
                    return; // Salimos de la función sin realizar la actualización
                }
            }

            // Si la clave_contratante no ha cambiado o no existe en otro registro, procedemos con la actualización
            await supabase
                .from('polizas')
                .update({
                    numero_poliza: user.numero_poliza,

                    id_contratante: selectedTipoContratante,
                    id_aseguradora: selectedTipoAseguradora,
                    id_tipo_seguro: selectedTipoTipoSeguro,
                    id_plan: selectedPlan,
                    id_duracion_pago: selectedDpago,
                    id_forma_pago: selectedFormaPago,
                    id_estatus_poliza: selectedEstatusPoliza,
                    id_moneda: selectedMoneda,
                    prima: user.prima,
                    suma_asegurada: user.suma_asegurada,
                    fecha_emision: user.fecha_emision,
                    nombre_asegurado: user.nombre_asegurado,
                    fecha_nacimiento_asegurado: user.fecha_nacimiento_asegurado,
                    fecha_vigencia: user.fecha_vigencia,
                    notas_poliza: user.notas_poliza,

                })
                .eq('id_poliza', user.id_poliza);

            showNotification({
                title: 'Póliza actualizada',
                message: 'La póliza se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar póliza:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la póliza.',
                color: 'red',
            });
        }
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {
                const { data, error } = await supabase
                    .from('polizas')
                    .select('numero_poliza')
                    .eq('numero_poliza', user.numero_poliza)
                    .limit(1); // Limitamos a un solo resultado

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'La clave de Póliza ya existe. Intente con otra clave.',
                        color: 'red',
                    });
                    dispatch(HideLoading());
                    return;
                } else {
                    /*console.log('Datos a insertar:', {
                        numero_poliza: user.numero_poliza,
                        id_contratante: selectedTipoContratante,
                        id_aseguradora: selectedTipoAseguradora,
                        id_tipo_seguro: selectedTipoTipoSeguro,
                        id_plan: selectedPlan,
                        id_duracion_pago: selectedDpago,
                        id_forma_pago: selectedFormaPago,
                        id_estatus_poliza: selectedEstatusPoliza,
                        id_moneda: selectedMoneda,
                        prima: user.prima,
                        suma_asegurada: user.suma_asegurada,
                        fecha_emision: user.fecha_emision,
                        nombre_asegurado: user.nombre_asegurado,
                        fecha_nacimiento_asegurado: user.fecha_nacimiento_asegurado,
                        fecha_vigencia: user.fecha_vigencia,
                    }); */
                    await supabase
                        .from('polizas')
                        .insert({
                            numero_poliza: user.numero_poliza,

                            id_contratante: selectedTipoContratante,
                            id_aseguradora: selectedTipoAseguradora,
                            id_tipo_seguro: selectedTipoTipoSeguro,
                            id_plan: selectedPlan,
                            id_duracion_pago: selectedDpago,
                            id_forma_pago: selectedFormaPago,
                            id_estatus_poliza: selectedEstatusPoliza,
                            id_moneda: selectedMoneda,
                            prima: user.prima,
                            suma_asegurada: user.suma_asegurada,
                            fecha_emision: user.fecha_emision,
                            nombre_asegurado: user.nombre_asegurado,
                            fecha_nacimiento_asegurado: user.fecha_nacimiento_asegurado,
                            fecha_vigencia: user.fecha_vigencia,
                            notas_poliza: user.notas_poliza,

                        });

                    showNotification({
                        title: 'Póliza agregada',
                        message: 'La póliza ha sido agregado con éxito',
                        color: 'green',
                    });
                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();
                }
            } else if (formMode === 'edit') {
                await handleUpdateContratante();

            }
            dispatch(HideLoading());
            //setShowForm(false);
            //getData();
        } catch (error) {
            console.error('Error al agregar/actualizar contratante:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el contratante.',
                color: 'red',
            });
        }
    };







    //console.log("SelectedTipoContratante antes del return:", selectedTipoContratante);



    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                        <Group >
                            <TextInput
                                disabled
                                name="id_poliza"
                                label="ID"
                                onChange={handleChange}
                                style={{ width: '100px' }}
                                value={user.id_poliza}

                            />

                            <TextInput
                                name="numero_poliza"
                                label="Num. Póliza"
                                style={{ width: '200px' }}
                                required
                                onChange={handleChange}
                                value={user.numero_poliza} // Asignar el valor del estado al campo
                                placeholder='Num Póliza'

                            />


                            <NativeSelect
                                name="id_contratante"
                                label="Contratante"
                                style={{ width: '300px' }}
                                value={selectedTipoContratante}
                                onChange={handleChangeSelect}


                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposContratante.map((tipo) => (
                                    <option key={tipo.id_contratante} value={tipo.id_contratante}>
                                        {tipo.nombre_contratante}
                                    </option>
                                ))}
                            </NativeSelect>


                        </Group>
                        <Group >
                            <NativeSelect
                                name="id_aseguradora"
                                label="Aseguradora"
                                style={{ width: '130px' }}
                                value={selectedTipoAseguradora}
                                onChange={handleChangeSelect}

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposAseguradora.map((tipo) => (
                                    <option key={tipo.id_aseguradora} value={tipo.id_aseguradora}>
                                        {tipo.nombre_aseguradora}
                                    </option>
                                ))}
                            </NativeSelect>

                            <NativeSelect
                                name="id_tipo_seguro"
                                label="Tipo seguro"
                                style={{ width: '170px' }}
                                value={selectedTipoTipoSeguro}
                                onChange={handleChangeSelect}

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposTipoSeguro.map((tipo) => (
                                    <option key={tipo.id_tipo_seguro} value={tipo.id_tipo_seguro}>
                                        {tipo.nombre_tipo_seguro}
                                    </option>
                                ))}
                            </NativeSelect>
                            <NativeSelect
                                name="id_plan"
                                label="Plan"
                                style={{ width: '300px' }}
                                value={selectedPlan}
                                onChange={handleChangeSelect}

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposPlan.map((tipo) => (
                                    <option key={tipo.id_plan} value={tipo.id_plan}>
                                        {tipo.nombre_plan}
                                    </option>
                                ))}
                            </NativeSelect>


                        </Group>
                        <Group >
                            <NativeSelect
                                name="id_duracion_pago"
                                label="Duración pago"
                                style={{ width: '130px' }}
                                value={selectedDpago}
                                onChange={handleChangeSelect}

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposDpago.map((tipo) => (
                                    <option key={tipo.id_duracion_pago} value={tipo.id_duracion_pago}>
                                        {tipo.nombre_duracion_pago}
                                    </option>
                                ))}
                            </NativeSelect>
                            <NativeSelect
                                name="id_forma_pago"
                                label="Forma pago"
                                style={{ width: '170px' }}
                                value={selectedFormaPago}
                                onChange={handleChangeSelect}

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {formasDpago.map((tipo) => (
                                    <option key={tipo.id_forma_pago} value={tipo.id_forma_pago}>
                                        {tipo.nombre_forma_pago}
                                    </option>
                                ))}
                            </NativeSelect>
                            <NativeSelect
                                name="id_estatus_poliza"
                                label="Estatus póliza"
                                style={{ width: '300px' }}
                                value={selectedEstatusPoliza}
                                onChange={handleChangeSelect}

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposEstatusPoliza.map((tipo) => (
                                    <option key={tipo.id_estatus_poliza} value={tipo.id_estatus_poliza}>
                                        {tipo.nombre_estatus_poliza}
                                    </option>
                                ))}
                            </NativeSelect>

                        </Group>
                        <Group>
                            <TextInput
                                name="prima"
                                label="Prima"
                                type="number"
                                style={{ width: '150px' }}
                                onChange={handleChange}
                                required
                                value={user.prima}
                                placeholder='Solo números con dos decimales'


                            />
                            <NativeSelect
                                name="id_moneda"
                                label="Moneda"
                                style={{ width: '150px' }}
                                value={selectedMoneda}
                                onChange={handleChangeSelect}

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposMoneda.map((tipo) => (
                                    <option key={tipo.id_moneda} value={tipo.id_moneda}>
                                        {tipo.nombre_moneda}
                                    </option>
                                ))}
                            </NativeSelect>
                            <TextInput
                                name="suma_asegurada"
                                type='text'
                                label="Suma asegurada"
                                style={{ width: '300px' }}
                                onChange={handleChange}
                                required
                                value={user.suma_asegurada}
                                placeholder='Suma asegurada'


                            />
                        </Group>
                        <Group>
                            <TextInput
                                name="fecha_emision"
                                label="F. emision"
                                type='date'
                                onChange={handleChange}
                                style={{ width: '310px' }}
                                required
                                value={user.fecha_emision}
                            />
                            <TextInput
                                name="fecha_vigencia"
                                label="F. vigencia"
                                type='date'
                                onChange={handleChange}
                                style={{ width: '310px' }}
                                required
                                value={user.fecha_vigencia}
                            />

                        </Group>
                        <Group>

                            <TextInput
                                name="nombre_asegurado"
                                type='text'
                                label="Asegurado (a)"
                                style={{ width: '310px' }}
                                onChange={handleChange}
                                required
                                value={user.nombre_asegurado}
                                placeholder='Asegurado (a)'
                            />
                            <TextInput
                                name="fecha_nacimiento_asegurado"
                                label="Cumpleaños"
                                type='date'
                                onChange={handleChange}
                                style={{ width: '310px' }}
                                required
                                value={user.fecha_nacimiento_asegurado}
                            />
                        </Group>
                        <Group>
                            <TextInput
                                name="notas_poliza"
                                label="Notas"
                                className='textarea-fullwidth'
                                onChange={handleChange}
                                value={user.notas_poliza} // Asignar el valor del estado al campo
                                placeholder='Escribe alguna nota'

                            />
                        </Group>


                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Póliza" : "Editar Póliza"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default PolizasForm;


/*
import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Input, Group, Select, Button, NativeSelect, Collapse } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDispatch } from 'react-redux';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";



function PolizasForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        numero_poliza: "",
        id_poliza: "", // inicializar para que no marque error
        id_contratante: "",
        nombre_contratante: ""
    })

    // Codigo para agregar al select las opciones de tipo de contratante, segun cat_tipo_contratante
    const [selectedContratante, setSelectedContratante] = useState(''); //
    const [tiposContratante, setTiposContratante] = useState([]);


    useEffect(() => {
        async function fetchContratantes() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_contratantes')
                    .select('id_contratante, nombre_contratante');

                setTiposContratante(tipos);
                console.log('tipos son:', tipos)
            } catch (error) {
                console.error('Error al obtener los tipos de contratante:', error.message);
            }
        }
        fetchContratantes();
    }, []);

    //para capturar lo que escriben
    // esta linea la quito chatgpt
    //function handleChange(event) {
    //    setUser(prevFormData => {
    //        return {
    //            ...prevFormData,
    //            [event.target.name]: event.target.value
    //        }
    //    })
    //};

    useEffect(() => {
        console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            console.log("Inside if block - Transaction data:", transactionData);
            setUser({
                numero_poliza: transactionData.numero_poliza,
                id_poliza: transactionData.id_poliza,

            });
            setSelectedContratante(transactionData.id_contratante);
            console.log("SelectedContratante después de useEffect:", selectedContratante);
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleChangeSelect = (event) => {
        console.log("Valor recibido en handleChangeSelect:", event.target.value);

        setSelectedContratante(event.target.value);
        console.log(event.target.value);
        console.log("SelectedContratante después de handleChange:", selectedContratante);

    };

    const handleUpdateContratante = async () => {
        try {
            // Primero, obtenemos la numero_poliza actual del contratante que se está editando
            const { data: polizaActual, error: polizaError } = await supabase
                .from('polizas')
                .select('numero_poliza')
                .eq('id_poliza', user.id_poliza)
                .single();

            if (polizaError) {
                throw polizaError;
            }

            // Verificamos si la numero_poliza se ha modificado
            if (user.numero_poliza !== polizaActual.numero_poliza) {
                // Si la numero_poliza ha cambiado, verificamos si ya existe en otro registro
                const { data, error } = await supabase
                    .from('polizas')
                    .select('numero_poliza')
                    .eq('numero_poliza', user.numero_poliza)
                    .limit(1);

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El número de póliza ya existe.',
                        color: 'red',
                    });
                    return; // Salimos de la función sin realizar la actualización
                }
            }

            // Si la numero_poliza no ha cambiado o no existe en otro registro, procedemos con la actualización
            await supabase
                .from('polizas')
                .update({

                    id_contratante: selectedContratante,
                    numero_poliza: user.numero_poliza
                })
                .eq('id_poliza', user.id_poliza);

            showNotification({
                title: 'Póliza actualizada',
                message: 'La póliza se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar la póliza:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la póliza.',
                color: 'red',
            });
        }
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {
                const { data, error } = await supabase
                    .from('polizas')
                    .select('numero_poliza')
                    .eq('numero_poliza', user.numero_poliza)
                    .limit(1); // Limitamos a un solo resultado

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'La clave de contratante ya existe.',
                        color: 'red',
                    });
                } else {
                    await supabase
                        .from('polizas')
                        .insert({

                            id_contratante: selectedContratante,
                            numero_poliza: user.numero_poliza
                        });

                    showNotification({
                        title: 'Póliza agregada',
                        message: 'La póliza ha sido agregada con éxito',
                        color: 'green',
                    });
                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();
                }
            } else if (formMode === 'edit') {
                await handleUpdateContratante();
            }
            dispatch(HideLoading());
            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al agregar/actualizar la póliza:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la póliza.',
                color: 'red',
            });
        }
    };









    console.log("SelectedContratante antes del return:", selectedContratante);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group>
                    <Stack>
                        <Group grow preventGrowOverflow={false} wrap="nowrap" >
                            <TextInput
                                disabled
                                name="id_poliza"
                                label="ID"
                                onChange={handleChange}
                                style={{ width: '5%' }}
                                value={user.id_poliza}

                            />

                            <TextInput
                                name="numero_poliza"
                                label="Número de Póliza"
                                style={{ width: '5%' }}
                                required
                                onChange={handleChange}
                                value={user.numero_poliza} // Asignar el valor del estado al campo
                                placeholder='Número Póliza'

                            />


                            <NativeSelect
                                name="id_contratante"
                                label="Nombre contratante"
                                style={{ width: '15%' }}
                                value={selectedContratante}
                                onChange={handleChangeSelect}
                                placeholder='Nombre Contratante'

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                
                                {tiposContratante.map((tipo) => (
                                    <option key={tipo.id_contratante} value={tipo.id_contratante}>
                                        {tipo.nombre_contratante}
                                    </option>
                                ))}
                                
                            </NativeSelect>


                        </Group>
                        <Group grow preventGrowOverflow={false} wrap="nowrap">



                        </Group>
                        <Group grow preventGrowOverflow={false} wrap="nowrap" >

                        </Group>


                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar contratante" : "Editar contratante"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default PolizasForm;

NativeSelect de contratante al 11 de marzo 2024
<NativeSelect
                                name="id_contratante"
                                label="Contratante"
                                style={{ width: '60%' }}
                                value={selectedTipoContratante}
                                onChange={handleChangeSelect}

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposContratante.map((tipo) => (
                                    <option key={tipo.id_contratante} value={tipo.id_contratante}>
                                        {tipo.nombre_contratante}
                                    </option>
                                ))}
                            </NativeSelect>

*/