const Moneda = ({ moneda }) => {
    return (
        <div className="moneda">
            <h3>
                {moneda.nombre_moneda}
            </h3>
            <p>ID: {moneda.id_moneda}</p> {/* Agregamos el ID aquí */}
            
        </div >
    );
}

export default Moneda;