import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";


function EstatusPolizasForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        nombre_estatus_poliza: "",
        id_estatus_poliza: "" // inicializar

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_estatus_poliza: transactionData.nombre_estatus_poliza,
                id_estatus_poliza: transactionData.id_estatus_poliza

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateContratante = async () => {

        try {
            await supabase
                .from('cat_estatus_polizas')
                .update({
                    nombre_estatus_poliza: user.nombre_estatus_poliza
                })
                .eq('id_estatus_poliza', user.id_estatus_poliza); //Este método se utiliza para filtrar las filas en una tabla donde el valor de una columna específica es igual a un valor dado.

            showNotification({
                title: 'Estatus de póliza actualizado',
                message: 'El estatus de póliza se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            console.error('Error al actualizar el plan:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el plan.',
                color: 'red',
            });
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {
                // Buscar el id_tipo_contratante correspondiente al nombre_tipo_contratante seleccionado

                const { error } = await supabase
                    .from('cat_estatus_polizas')
                    .insert({
                        nombre_estatus_poliza: user.nombre_estatus_poliza
                    });
                showNotification({
                    title: 'Estatus de póliza agregado',
                    message: 'El estatus de póliza ha sido agregado con éxito',
                    color: 'green',
                });

                if (error) {
                    throw error;
                }
            } else if (formMode === 'edit') {
                await handleUpdateContratante();
            }
            dispatch(HideLoading());
            setShowForm(false);
            getData();
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar el esatus de póliza:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el estatus de póliza.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group grow>
                    <Stack>

                        <TextInput
                            disabled
                            name="id_estatus_poliza"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={user.id_estatus_poliza}

                        />

                        <TextInput
                            name="nombre_estatus_poliza"
                            label="Estatus póliza"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={user.nombre_estatus_poliza} // Asignar el valor del estado al campo
                            placeholder='Estatus póliza'

                        />



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Estatus Póliza" : "Editar Estatus Póliza"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default EstatusPolizasForm;