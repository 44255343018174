import React, { useState, useEffect } from 'react';
import { supabase } from "../supabaseClient";
import Header from '../components/Header';
import { Group, Button, Divider, Accordion, Card, Text } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import PagosForm from '../components/PagosForm';
import { IconSearch, IconEyeglass, IconClipboardText, IconBrandCashapp } from '@tabler/icons-react';
import NivelCobranzaTable from '../components/NivelCobranzaTable';
import FiltrosNivelCobranza from '../components/FiltrosNivelCobranza';


function CatalogoNivelCobranza() {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [pagos, setPagos] = useState([]);
    const [pagos2, setPagos2] = useState([]);
    const [pagos3, setPagos3] = useState([]);
    const [pagosdetalle, setPagosdetalle] = useState([]);



    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const [filters, setFilters] = React.useState({
        periodo: "2024",
        mes: "",
        nombre_aseguradora: "",
        nombre_tipo_seguro: ""

    });
    const dispatch = useDispatch();

    const fetchPagos = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('nivel_cobranza').select('*');

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.periodo) {
                supabaseQuery = supabaseQuery.eq('periodo', filters.periodo);
            }

            if (filters.nombre_aseguradora) {
                supabaseQuery = supabaseQuery.eq('nombre_aseguradora', filters.nombre_aseguradora);
            }

            if (filters.nombre_tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('nombre_tipo_seguro', filters.nombre_tipo_seguro);
            }


            let { data: pagosData, error } = await supabaseQuery;

            if (error) {
                throw error;
            }

            setPagos(pagosData);
            //console.log("pagos1", pagosData); // Aquí se imprime pagos2 en la consola

            dispatch(HideLoading());



        } catch (error) {
            //console.error('Error al obtener pagos:', error.message);
            dispatch(HideLoading());
        }
    };

    const fetchPagosDetalle = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('nivel_cobranza_detalle').select('*');

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.periodo) {
                supabaseQuery = supabaseQuery.eq('periodo', filters.periodo);
            }

            if (filters.nombre_aseguradora) {
                supabaseQuery = supabaseQuery.eq('nombre_aseguradora', filters.nombre_aseguradora);
            }

            if (filters.nombre_tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('nombre_tipo_seguro', filters.nombre_tipo_seguro);
            }

            let { data: pagosDataDetalle, error } = await supabaseQuery;

            if (error) {
                throw error;
            }

            setPagosdetalle(pagosDataDetalle);
            //console.log("pagosdetalle", pagosDataDetalle);
            dispatch(HideLoading());

        } catch (error) {
            //console.error('Error al obtener pagos:', error.message);
            dispatch(HideLoading());
        }
    };

    const getData = async () => {
        try {
            await fetchPagos();
            await fetchPagosDetalle();
        } catch (error) {
            // Manejo de errores
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            dispatch(HideLoading());
        }
    };

    return (
        <div >
            <Card className='CardTituloReportes'
                mb={'15px'}
            >
                <Text
                    size="xl"
                    fw={900}

                >Reporte Nivel Cobranza (en porcentaje)
                </Text>
            </Card>
            <Accordion defaultValue="pago">
                <Accordion.Item value="pago">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <FiltrosNivelCobranza
                                filters={filters}
                                setFilters={setFilters}
                            />

                        </Group>
                        <Button leftSection={<IconSearch size={20} />} mt={'10px'} color="#005187" onClick={getData}>
                            Generar Reporte
                        </Button>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>


            <Card className='CardTablas'>
                <div style={{ overflowX: "auto" }}>
                    <NivelCobranzaTable
                        pagos={pagos}
                        pagosdetalle={pagosdetalle}
                        fetchPagosDetalle={fetchPagosDetalle}
                    />
                </div>
            </Card >
        </div >
    );
}

export default CatalogoNivelCobranza;
