import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import Header from '../components/Header';
import { Group, Button, Divider, Modal, Card, Text, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { IconInfoCircle } from '@tabler/icons-react';
import moment from "moment";
import { IconSearch } from '@tabler/icons-react';
import ListaRegalosTable from '../components/ListaRegalosTable';
import FiltrosListaRegalos from '../components/FiltrosListaRegalos';




function CatalogoReporteListaRegalos() {

    const [polizas, setPolizas] = useState([])
    const dispatch = useDispatch();
    const [filtroPoliza, setFiltroPoliza] = useState('');
    const icon = <IconInfoCircle />;


    //para Filtros Pagos
    const [filters, setFilters] = React.useState({
        numero_poliza: "",
        nombre_contratante: "",
        nombre_aseguradora: "",
        nombre_tipo_seguro: "",
        nombre_plan: "",
        nombre_duracion_pagos: "",
        nombre_forma_pago: "",
        fechaInicial: null,
        fechaFinal: null,
        fechaInicial2: null,
        fechaFinal2: null,
        nombre_moneda: "",
        nombre_estatus_poliza: "",

    });


    const fetchPolizas = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('polizas').select(`
                id_poliza,
                numero_poliza,
                prima,
                suma_asegurada,
                fecha_emision,
                nombre_asegurado,
                fecha_nacimiento_asegurado,
                fecha_vigencia,
                cat_contratantes!inner(id_contratante, nombre_contratante),
                cat_aseguradoras!inner(id_aseguradora, nombre_aseguradora),
                cat_tipos_seguros!inner(id_tipo_seguro, nombre_tipo_seguro),
                cat_planes!inner(id_plan, nombre_plan),
                cat_duraciones_pagos!inner(id_duracion_pago, nombre_duracion_pago),
                cat_formas_pagos!inner(id_forma_pago, nombre_forma_pago),
                cat_estatus_polizas!inner(id_estatus_poliza, nombre_estatus_poliza),
                cat_monedas!inner(id_moneda, nombre_moneda)
                `);

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.numero_poliza) {
                supabaseQuery = supabaseQuery.eq('numero_poliza', filters.numero_poliza);
            }

            if (filters.nombre_contratante) {
                supabaseQuery = supabaseQuery.eq('cat_contratantes.nombre_contratante', filters.nombre_contratante);
            }

            if (filters.nombre_aseguradora) {
                supabaseQuery = supabaseQuery.eq('cat_aseguradoras.nombre_aseguradora', filters.nombre_aseguradora);
            }

            if (filters.nombre_tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('cat_tipos_seguros.nombre_tipo_seguro', filters.nombre_tipo_seguro);
            }

            if (filters.nombre_plan) {
                supabaseQuery = supabaseQuery.eq('cat_planes.nombre_plan', filters.nombre_plan);
            }

            if (filters.nombre_duracion_pagos) {
                supabaseQuery = supabaseQuery.eq('cat_duraciones_pagos.nombre_duracion_pagos', filters.nombre_duracion_pagos);
            }

            if (filters.nombre_forma_pago) {
                supabaseQuery = supabaseQuery.eq('cat_formas_pagos.nombre_forma_pago', filters.nombre_forma_pago);
            }

            if (filters.nombre_moneda) {
                supabaseQuery = supabaseQuery.eq('cat_monedas.nombre_moneda', filters.nombre_moneda);
            }
            if (filters.nombre_estatus_poliza) {
                supabaseQuery = supabaseQuery.eq('cat_estatus_polizas.nombre_estatus_poliza', filters.nombre_estatus_poliza);
            }
            if (filters.fechaInicial) {
                const fechaInicialFormatted = moment(filters.fechaInicial).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.gte('fecha_emision', fechaInicialFormatted);
            }
            if (filters.fechaInicial2) {
                const fechaInicialFormatted2 = moment(filters.fechaInicial2).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.lte('fecha_emision', fechaInicialFormatted2);
            }


            if (filters.fechaFinal) {
                const fechaFinalFormatted = moment(filters.fechaFinal).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.gte('fecha_vigencia', fechaFinalFormatted);
            }

            if (filters.fechaFinal2) {
                const fechaFinalFormatted2 = moment(filters.fechaFinal2).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.lte('fecha_vigencia', fechaFinalFormatted2);
            }

            let { data: polizas, error } = await supabaseQuery
                .order('id_poliza', { ascending: false });

            if (error) {
                throw error;
            }


            console.log("Listado de polizas obtenidas:", polizas); // Agregar este console.log para verificar los contratantes obtenidos
            setPolizas(polizas);
            dispatch(HideLoading());

        } catch (error) {
            //console.error('Error al obtener polizas:', error.message);
            dispatch(HideLoading());

        }
    };





    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            await fetchPolizas();
        } catch (error) {
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    return (
        <div>
            <Card className='CardTituloReportes'
                mb={'15px'}
            >
                <Text
                    size="xl"
                    fw={900}
                >Reporte Lista de Regalos
                </Text>
            </Card>
            <Accordion defaultValue="pago">
                <Accordion.Item value="pago">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <FiltrosListaRegalos
                                filters={filters}
                                setFilters={setFilters}
                                getData={getData}
                            />

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            <Card withBorder>
                <div style={{ overflowX: "auto" }}>
                    <ListaRegalosTable
                        polizas={polizas}
                        getData={getData}
                    />

                </div>
            </Card>
        </div>

    );

}


export default CatalogoReporteListaRegalos;