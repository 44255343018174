import React, { useState } from 'react';
import { Button, Group, TextInput } from '@mantine/core';

function FiltrosContratantes({ setFilters, getData }) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
        setFilters({ nombre_contratante: event.target.value });
    };

    const buscar = async (event) => {
        event.preventDefault();
        // No necesitas buscar aquí, los datos se filtrarán en CatalogoContratantes
        getData();
    };

    return (
        <Group justify="center">
            <TextInput
                label="Buscar nombre"
                placeholder='Buscar nombre'
                value={searchTerm}
                onChange={handleChange}
                name='nombre_contratante'
            />
            <Button onClick={buscar}>Buscar</Button>
        </Group>
    );
}



export default FiltrosContratantes;

