import React, { useState } from 'react';
import { Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, rem } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import classes from './NavbarLinksGroup.module.css';

function LinksGroup({ icon: Icon, label, initiallyOpened, links }) {
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  //console.log("Enlaces en SideNavbar:", links); // Agrega este console.log


  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={classes.control}
      >
        <Group justify="space-between" gap={0}>
          <Box style={{ display: 'flex', alignItems: 'center', }}>
            
            <ThemeIcon /* aqui se ajusta el color de los iconos*/ color='white' variant="light" size={30}>
              <Icon /* aqui se ajusta el color de los iconos*/ color='white' style={{ width: rem(18), height: rem(18) }} />
            </ThemeIcon>
            <Box ml="md" style={{ fontSize: '1rem' }}>{/* Ajusta aquí el tamaño de la fuente */}{label}</Box>
          </Box>
          {hasLinks && (
            <IconChevronRight
              className={classes.chevron}
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? 'rotate(-90deg)' : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks && (
        <Collapse in={opened}>
          {links.map((link) => (
            <a
              key={link.label}
              className={classes.link}
              href={link.link}
              style={{ fontSize: '1rem' }}
              onClick={(event) => event.stopPropagation()}
            >
              {link.label}
            </a>
          ))}
        </Collapse>
      )}
    </>
  );
}

export { LinksGroup };

