import React, { useState } from 'react';
import { Table, Button, Group, Pagination, Modal, Badge } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { IconDownload, IconFileSearch, IconSearch } from '@tabler/icons-react';
import moment from "moment";



function NivelCobranzaTable({ pagos, pagosdetalle, fetchPagosDetalle }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPago, setSelectedPago] = useState(null);
    const [showForm, setShowForm] = useState(false); // Estado para controlar si se muestra el formulario

    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const handleCellClick = (mes, pago) => {
        //console.log("Celda clickeada, pertenece al mes:", mes);
        handleVerDetalleClick(pago, mes); // Llama a handleVerDetalleClick y pasa el mes y el pago
    };

    const [titleModal, setTitleModal] = useState(""); // Estado para el título del modal

    const handleVerDetalleClick = (pago, mes) => {
        // Filtrar los detalles de pago correspondientes a las condiciones dadas
        const detallesPago = pagosdetalle.filter((detalle) =>
            detalle.periodo === pago.periodo &&
            detalle.nombre_aseguradora === pago.nombre_aseguradora &&
            detalle.nombre_tipo_seguro === pago.nombre_tipo_seguro &&
            detalle.mes === mes // Agrega la condición del mes

        );
        // Establecer los detalles de pago seleccionados
        setSelectedPago(detallesPago);
        //console.log('filtrados', detallesPago)
        // Construir el título del modal con el periodo y el mes
        const modalTitle = `Detalles de Pagos - Periodo: ${pago.periodo}, Mes: ${mes}`;
        // Establecer el título del modal
        setTitleModal(modalTitle);
        // Mostrar el modal
        setShowForm(true);
    };

    const handleCloseModal = () => {
        setSelectedPago(null);
        setTitleModal(""); // Limpiar el título del modal
        setShowForm(false); // Al cerrar el modal, volvemos a establecer showForm en false
    };




    const getRows = pagos.slice(startIndex, endIndex).map((pago, index) => {

        return (
            <tr key={index}>
                <td>{pago.periodo}</td>
                <td>{pago.nombre_aseguradora}</td>
                <td>{pago.nombre_tipo_seguro}</td>
                <td style={
                    pago.enero === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.enero <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.enero <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.enero > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.enero === null ? "N/A" : (
                        <>
                            {pago.enero}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Enero", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.febrero === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.febrero <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.febrero <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.febrero > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.febrero === null ? "N/A" : (
                        <>
                            {pago.febrero}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Febrero", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.marzo === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.marzo <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.marzo <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.marzo > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.marzo === null ? "N/A" : (
                        <>
                            {pago.marzo}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Marzo", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.abril === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.abril <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.abril <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.abril > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.abril === null ? "N/A" : (
                        <>
                            {pago.abril}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Abril", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.mayo === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.mayo <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.mayo <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.mayo > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.mayo === null ? "N/A" : (
                        <>
                            {pago.mayo}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Mayo", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.junio === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.junio <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.junio <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.junio > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.junio === null ? "N/A" : (
                        <>
                            {pago.junio}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Junio", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.julio === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.julio <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.julio <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.julio > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.julio === null ? "N/A" : (
                        <>
                            {pago.julio}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Julio", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.agosto === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.agosto <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.agosto <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.agosto > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.agosto === null ? "N/A" : (
                        <>
                            {pago.agosto}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Agosto", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.septiembre === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.septiembre <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.septiembre <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.septiembre > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.septiembre === null ? "N/A" : (
                        <>
                            {pago.septiembre}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Septiembre", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.octubre === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.octubre <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.octubre <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.octubre > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.octubre === null ? "N/A" : (
                        <>
                            {pago.octubre}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Octubre", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.noviembre === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.noviembre <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.noviembre <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.noviembre > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.noviembre === null ? "N/A" : (
                        <>
                            {pago.noviembre}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Octubre", pago)} />
                        </>
                    )}
                </td>
                <td style={
                    pago.diciembre === null ? { backgroundColor: 'white', color: 'grey' } :
                        pago.diciembre <= 33 ? { backgroundColor: '#D5133A', color: 'white' } :
                            pago.diciembre <= 66 ? { backgroundColor: '#E68C00', color: 'white' } :
                                pago.diciembre > 66 ? { backgroundColor: '#4CBB10', color: 'white' } : {}
                }>
                    {pago.diciembre === null ? "N/A" : (
                        <>
                            {pago.diciembre}
                            <br />
                            <IconFileSearch style={{ cursor: 'pointer' }} onClick={() => handleCellClick("Diciembre", pago)} />
                        </>
                    )}
                </td>
            </tr>
        );
    });



    const csvHeaders = [
        'Periodo',
        'Aseguradora',
        'Tipo de Seguro',
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];



    const csvData = pagos.map((pago) => [
        pago.periodo,
        pago.nombre_aseguradora,
        pago.nombre_tipo_seguro,
        pago.enero === null ? "N/A" : pago.enero,
        pago.febrero === null ? "N/A" : pago.febrero,
        pago.marzo === null ? "N/A" : pago.marzo,
        pago.abril === null ? "N/A" : pago.abril,
        pago.mayo === null ? "N/A" : pago.mayo,
        pago.junio === null ? "N/A" : pago.junio,
        pago.julio === null ? "N/A" : pago.julio,
        pago.agosto === null ? "N/A" : pago.agosto,
        pago.septiembre === null ? "N/A" : pago.septiembre,
        pago.octubre === null ? "N/A" : pago.octubre,
        pago.noviembre === null ? "N/A" : pago.noviembre,
        pago.diciembre === null ? "N/A" : pago.diciembre,
    ]);





    return (
        <div>
            <div>
                <Table
                    style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                    verticalSpacing="xl"
                    fontSize="sm"
                    mb={10}
                    striped
                    withRowBorders={true}
                    className="my-custom-table"
                >
                    <thead style={{ textAlign: 'left' }}>
                        <tr>
                            <th style={{ padding: '3px' }}>Periodo</th>
                            <th style={{ padding: '3px' }}>Aseguradora</th>
                            <th style={{ padding: '3px' }}>Tipo de Seguro</th>
                            <th style={{ padding: '3px' }}>Enero</th>
                            <th style={{ padding: '3px' }}>Febrero</th>
                            <th style={{ padding: '3px' }}>Marzo</th>
                            <th style={{ padding: '3px' }}>Abril</th>
                            <th style={{ padding: '3px' }}>Mayo</th>
                            <th style={{ padding: '3px' }}>Junio</th>
                            <th style={{ padding: '3px' }}>Julio</th>
                            <th style={{ padding: '3px' }}>Agosto</th>
                            <th style={{ padding: '3px' }}>Septiembre</th>
                            <th style={{ padding: '3px' }}>Octubre</th>
                            <th style={{ padding: '3px' }}>Noviembre</th>
                            <th style={{ padding: '3px' }}>Diciembre</th>
                        </tr>

                    </thead>
                    <tbody>{getRows}</tbody>
                </Table>
                <Modal
                    title={titleModal} // Usar el estado titleModal como título del modal
                    opened={showForm}
                    onClose={handleCloseModal}
                    size="auto"
                >
                    {selectedPago && (
                        <div>
                            <Table
                                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                                verticalSpacing="xl"
                                fontSize="sm"
                                mb={10}
                                striped
                                withRowBorders={true}
                                className="my-custom-table"
                            >
                                <thead>
                                    <tr>
                                        <th style={{ padding: '5px' }}>ID Pago</th>

                                        <th style={{ padding: '5px' }}>Estatus de Pago</th>
                                        <th style={{ padding: '5px' }}>Flexible</th>

                                        <th style={{ padding: '5px' }}>Póliza</th>
                                        <th style={{ padding: '5px' }}>Aseguradora</th>
                                        <th style={{ padding: '5px' }}>Tipo de Seguro</th>
                                        <th style={{ padding: '5px' }}>Contratante</th>
                                        <th style={{ padding: '5px' }}>Descripción de pago</th>
                                        <th style={{ padding: '5px' }}>Monto del Pago</th>
                                        <th style={{ padding: '5px' }}>Moneda</th>
                                        <th style={{ padding: '5px' }}>F. pago requerida</th>

                                        <th style={{ padding: '5px' }}>Periodo</th>
                                        <th style={{ padding: '5px' }}>Mes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedPago.map((pago, index) => (
                                        <tr key={index}>
                                            <td >{pago.id_pago_poliza}</td>

                                            <td style={
                                                pago.nombre_estatus_pago === 'Pendiente' ? { backgroundColor: '#D5133A', color: 'white' } :
                                                    pago.nombre_estatus_pago === 'Pagado' ? { backgroundColor: '#4CBB16', color: 'white' } :
                                                        pago.nombre_estatus_pago === 'Pagado con retraso' ? { backgroundColor: '#E68C02', color: 'white' } : {}
                                            }>{pago.nombre_estatus_pago}</td>
                                            <td>
                                                {pago.pago_flexible === 2 ? (
                                                    <Badge color="blue">SI</Badge>
                                                ) : (
                                                    <Badge color="red">NO</Badge>
                                                )}
                                            </td>
                                            <td >{pago.numero_poliza}</td>
                                            <td >{pago.nombre_aseguradora}</td>
                                            <td >{pago.nombre_tipo_seguro}</td>


                                            <td >{pago.nombre_contratante}</td>

                                            <td >{pago.descripcion_pago}</td>
                                            <td >{pago.monto_pago}</td>
                                            <td >{pago.nombre_moneda}</td>
                                            <td>{moment(pago.fecha_pago_requerida).format('DD-MM-YYYY')}</td>
                                            <td >{pago.periodo}</td>
                                            <td >{pago.mes}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Modal>




                <Group justify="flex-end" mb={10}>



                    <CsvDownloader
                        filename={'nivel_cobranza.csv'}
                        separator=","
                        wrapColumnChar=""
                        datas={csvData}
                        columns={csvHeaders}
                    >
                        <Button variant="light" color='#00497A' leftSection={<IconDownload size={20} />} >Descargar Tabla</Button>
                    </CsvDownloader>
                    <Pagination
                        total={Math.ceil(pagos.length / itemsPerPage)}
                        siblings={1}
                        withEdges
                        value={currentPage}
                        onChange={(newPage) => setCurrentPage(newPage)}
                        color='#00497A'
                    />
                </Group>
            </div>
        </div>
    );
};

export default NivelCobranzaTable;