import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useAuth } from "../context/AuthContext";





function EstatusPagosTable({ estatuspolizas, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombreEstatusPolizas }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    //const truncateText = (text, maxLength) => {
    //    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    //};
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);


    // Este useEffect sirve para revisar qué permisos tiene este usuario
    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    console.error("Error fetching user permissions:", error.message);
                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        }
    }, [user, dispatch]);


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const tipoestatuspolizasFiltrados = filtroNombreEstatusPolizas
        ? estatuspolizas.filter(estatuspoliza => estatuspoliza.nombre_estatus_poliza.toLowerCase().includes(filtroNombreEstatusPolizas.toLowerCase()))
        : estatuspolizas;

    const paginatedTransactions = tipoestatuspolizasFiltrados.slice(startIndex, endIndex);

    /*
    const handleSelectedTransaction = (estatuspoliza) => {
        console.log("Forma de pago seleccionada:", estatuspoliza); // Agregar este console.log para verificar el contratante seleccionado
        setSelectedTransaction(estatuspoliza);
        setFormMode("edit");
        setShowForm(true);
    };
    */

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_estatus_polizas')
                .delete()
                .eq('id_estatus_poliza', id);

            if (error) {
                throw new Error('No se pudo borrar el Estatus póliza');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Estatus póliza borrado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Estatus póliza',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((estatuspoliza) => (
        <tr key={estatuspoliza.id_estatus_poliza}>
            <td>{estatuspoliza.id_estatus_poliza}</td>
            <td>{estatuspoliza.nombre_estatus_poliza}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_estatuspolizas &&
                        permisos.catalogos.modulos.cat_estatuspolizas.acciones &&
                        permisos.catalogos.modulos.cat_estatuspolizas.acciones.modificar_estatuspoliza && (
                            <i
                                key={`edit-${estatuspoliza.id_estatus_poliza}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(estatuspoliza);
                                    setFormMode("edit");
                                    setShowForm(true);
                                    //console.log("Forma de pago seleccionada:", estatuspoliza);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_estatuspolizas &&
                        permisos.catalogos.modulos.cat_estatuspolizas.acciones &&
                        permisos.catalogos.modulos.cat_estatuspolizas.acciones.eliminar_estatuspoliza && (
                            <i
                                key={`delete-${estatuspoliza.id_estatus_poliza}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar este Estatus póliza?')) {
                                        deleteTransaction(estatuspoliza.id_estatus_poliza);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre forma de pago',
    ];

    const csvData = estatuspolizas.map((estatuspoliza) => [
        estatuspoliza.id_estatus_poliza,
        estatuspoliza.nombre_estatus_poliza,
    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Nombre Estatus Póliza</th>

                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(estatuspolizas.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'estatuspolizas.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default EstatusPagosTable;