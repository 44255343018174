import React, { useEffect, useState } from 'react';
import { Card, Group, Image, Text, Badge } from '@mantine/core';

const TarjetaPolizasCanceladas = ({ polizas }) => {
    // Filtrar las polizas canceladas
    const polizasCanceladas = polizas.filter((poliza) => poliza.cat_estatus_polizas.nombre_estatus_poliza === "Cancelada");

    // Contar el número de polizas canceladas
    const cantidadPolizasCanceladas = polizasCanceladas.length;

    // Filtrar las polizas vigor
    const polizasEnVigor = polizas.filter((poliza) => poliza.cat_estatus_polizas.nombre_estatus_poliza === "En vigor");

    // Contar el número de polizas en vigor
    const cantidadPolizasEnVigor = polizasEnVigor.length;

    // Filtrar las polizas vigor sin pago de primas
    const polizasEnVigorSinpagodeprimas = polizas.filter((poliza) => poliza.cat_estatus_polizas.nombre_estatus_poliza === "En vigor sin pago de primas");

    // Contar el número de polizas en vigor sin pago de primas
    const cantidadPolizasEnVigorSinpagodeprimas = polizasEnVigorSinpagodeprimas.length;

    // Filtrar las polizas vigor prorrogado
    const polizasEnVigorProrrogado = polizas.filter((poliza) => poliza.cat_estatus_polizas.nombre_estatus_poliza === "En vigor prorrogado");

    // Contar el número de polizas en vigor prorrogado
    const cantidadPolizasEnVigorProrrogado = polizasEnVigorProrrogado.length;

    // Filtrar las polizas seguro saldado
    const polizasSeguroSaldado = polizas.filter((poliza) => poliza.cat_estatus_polizas.nombre_estatus_poliza === "Seguro saldado");

    // Contar el número de polizas seguro saldado
    const cantidadPolizasSeguroSaldado = polizasSeguroSaldado.length;

    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '10px',
            justifyContent: 'center', // Centra horizontalmente
            //alignItems: 'center', // Centra verticalmente
        }}>            <Group>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={{ flex: '1 1 auto', marginRight: '1px', border: '2px solid #DEE2E6' }}
                >

                    <Group justify="space-between" mt="md" mb="xs">
                        <Text fw={500}>Polizas En Vigor:</Text>
                        <Badge color="green"></Badge>
                    </Group>
                    <Text size="xl" c="black">
                        {cantidadPolizasEnVigor}
                    </Text>
                </Card>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={{ flex: '1 1 auto', marginRight: '1px' }}
                >

                    <Group justify="space-between" mt="md" mb="xs">
                        <Text fw={500}>Polizas Canceladas:</Text>
                        <Badge color="red"></Badge>
                    </Group>
                    <Text size="xl" c='black'>
                        {cantidadPolizasCanceladas}
                    </Text>
                </Card>

                <Card shadow="sm" padding="lg" radius="md" withBorder style={{ flex: '1 1 auto', marginRight: '1px' }}
                >
                    <Group justify="space-between" mt="md" mb="xs">
                        <Text fw={500}>Polizas En Vigor Sin Pago de Primas:</Text>
                        <Badge color="blue"></Badge>
                    </Group>
                    <Text size="xl" c='black'>
                        {cantidadPolizasEnVigorSinpagodeprimas}
                    </Text>
                </Card>

                <Card shadow="sm" padding="lg" radius="md" withBorder style={{ flex: '1 1 auto', marginRight: '1px' }}
                >
                    
                    <Group justify="space-between" mt="md" mb="xs">
                        <Text fw={500}>Polizas En Vigor Prorrogado:</Text>
                        <Badge color="purple"></Badge>
                    </Group>
                    <Text size="xl" c='black'>
                        {cantidadPolizasEnVigorProrrogado}
                    </Text>
                </Card>

                <Card shadow="sm" padding="lg" radius="md" withBorder style={{ flex: '1 1 auto', marginRight: '1px' }}
                >
                    
                    <Group justify="space-between" mt="md" mb="xs">
                        <Text fw={500}>Polizas Seguro Saldado:</Text>
                        <Badge color="black"></Badge>
                    </Group>
                    <Text size="xl" c='black'>
                        {cantidadPolizasSeguroSaldado}
                    </Text>
                </Card>



            </Group>
        </div>
    );
}

export default TarjetaPolizasCanceladas;
