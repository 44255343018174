import Moneda from './Moneda';

const Monedas = ({ monedas }) => {
    return (
        <div>
            {monedas.map((moneda) => (
                <Moneda key={moneda.id_moneda} moneda={moneda} />
            ))}
        </div>
    );
}

export default Monedas;