import React, { useState, useEffect } from 'react';
import { supabase } from "../supabaseClient";
import Header from '../components/Header';
import { Group, Button, Text, Modal, Card, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import PagosTable from '../components/PagosTable';
import PagosForm from '../components/PagosForm';
import FiltrosPagos from '../components/FiltrosPagos';
import moment from "moment";
import { IconSearch } from '@tabler/icons-react';



function CatalogoPagos({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [pagos, setPagos] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const [filters, setFilters] = React.useState({
        estatus_pago: "",
        numero_poliza: "",
        nombre_aseguradora: "",
        fechaInicial: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Primer día del mes actual
        fechaFinal: new Date(),
        numero_endoso: "",
        tipo_pago: "",
        nombre_tipo_seguro: "",
        nombre_moneda: "",
        notas_pago: "",
        pago_flexible: "", //para checkbox flexible
    });
    const dispatch = useDispatch();
    

    

    const fetchPagos = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('pagos_polizas').select(`
                id_pago_poliza,
                descripcion_pago,
                monto_pago,
                fecha_pago_requerida,
                fecha_pago_real,
                notas_pago,
                numero_endoso,
                pago_flexible,
                tipo_pago,
                cat_estatus_pagos!inner (
                    id_estatus_pago,
                    nombre_estatus_pago
                ),
                cat_conductos_cobros(
                    id_conducto_cobro,
                    nombre_conducto_cobro
                ),
                polizas!inner (
                    id_poliza,
                    numero_poliza,
                    nombre_asegurado,
                    cat_contratantes!inner (
                        id_contratante,
                        nombre_contratante,
                        telefono_celular,
                        correo
                    ),
                    cat_aseguradoras!inner (
                        id_aseguradora,
                        nombre_aseguradora
                    ),
                    cat_estatus_polizas (
                        nombre_estatus_poliza
                    ),
                    cat_tipos_seguros!inner (
                        id_tipo_seguro,
                        nombre_tipo_seguro
                    ),
                    cat_planes (
                        nombre_plan
                    ),
                    cat_monedas!inner (
                        id_moneda,
                        nombre_moneda
                    )
                )
            `);

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            // para pago flexible
            if (filters.pago_flexible) {
                supabaseQuery = supabaseQuery.eq('pago_flexible', filters.pago_flexible);
            }
            // para pago flexible

            if (filters.estatus_pago) {
                supabaseQuery = supabaseQuery.eq('cat_estatus_pagos.nombre_estatus_pago', filters.estatus_pago);
            }

            if (filters.numero_poliza) {
                supabaseQuery = supabaseQuery.eq('polizas.numero_poliza', filters.numero_poliza);
            }

            if (filters.notas_pago) {
                supabaseQuery = supabaseQuery.ilike('notas_pago', `%${filters.notas_pago}%`);
            }

            if (filters.nombre_aseguradora) {
                supabaseQuery = supabaseQuery.eq('polizas.cat_aseguradoras.nombre_aseguradora', filters.nombre_aseguradora);
            }

            if (filters.nombre_contratante) {
                supabaseQuery = supabaseQuery.eq('polizas.cat_contratantes.nombre_contratante', filters.nombre_contratante);
            }

            if (filters.fechaInicial) {
                const fechaInicialFormatted = moment(filters.fechaInicial).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.gte('fecha_pago_requerida', fechaInicialFormatted);
            }


            if (filters.fechaFinal) {
                const fechaFinalFormatted = moment(filters.fechaFinal).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.lte('fecha_pago_requerida', fechaFinalFormatted);
            }

            // Aquí se verifica si el filtro numero_endoso está vacío para buscar los EMPTY
            if (filters.numero_endoso) {
                supabaseQuery = supabaseQuery.ilike('numero_endoso', `%${filters.numero_endoso}%`);
            }

            // filtro para tipo de pago, polizas o endosos
            if (filters.tipo_pago) {
                supabaseQuery = supabaseQuery.eq('tipo_pago', filters.tipo_pago);
            }

            // se agrega el filtro para tipo_seguro
            if (filters.nombre_tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('polizas.cat_tipos_seguros.nombre_tipo_seguro', filters.nombre_tipo_seguro);
            }

            let { data: pagos, error } = await supabaseQuery
                .order('id_pago_poliza', { ascending: false });

            if (error) {
                throw error;
            }

            // Calcular dias_para_pago solo si fecha_pago_real es null
            pagos = pagos.map(pago => {
                if (pago.fecha_pago_real === null) {
                    const fechaRequerida = new Date(pago.fecha_pago_requerida);
                    const now = new Date();
                    const diffTime = fechaRequerida - now;
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Calcula días redondeando hacia arriba
                    return {
                        ...pago,
                        dias_para_pago: diffDays
                    };
                } else {
                    return {
                        ...pago,
                        dias_para_pago: "-"
                    };
                }
            });

            setPagos(pagos);
            console.log('Pagos', pagos)
            dispatch(HideLoading());
        } catch (error) {
            //console.error('Error al obtener pagos:', error.message);
            dispatch(HideLoading());
        }
    };

    //useEffect(() => {
    //    fetchPagos();
    //}, [filters]);

    const getData = async () => {
        try {
            await fetchPagos();
        } catch (error) {
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddpago = permisos &&
        permisos.pagos &&
        permisos.pagos.acciones &&
        permisos.pagos.acciones.registrar_pago === true;

    return (
        <div >
            <Card className='CardTituloCatalogos'
            >
                <Text
                    size="xl"
                    fw={900}

                >Pagos
                </Text>
            </Card>
            <Accordion defaultValue="pago">
                <Accordion.Item value="pago">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <FiltrosPagos
                                filters={filters}
                                setFilters={setFilters}
                                onEnterPress={getData}

                            />

                            <Button leftSection={<IconSearch size={20} />} color="#005187" onClick={getData}>
                                Buscar Pagos
                            </Button>

                            {canAddpago && (
                                <Button
                                    color='#005187'
                                    onClick={() => {
                                        setShowForm(true);
                                        setFormMode("add");
                                    }}>
                                    + Agregar Pago a Póliza
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>


            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Pago" : "Editar Pago"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <PagosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction}
                    />
                </Modal>

                <Group>

                </Group>
                <div style={{ overflowX: "auto" }}>
                    <PagosTable
                        pagos={pagos}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />
                </div>
            </Card>
        </div>
    );
}

export default CatalogoPagos;
