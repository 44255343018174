import { MantineProvider } from '@mantine/core';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot
import { BrowserRouter } from 'react-router-dom';

import '@mantine/core/styles.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// para spinner

import { Provider } from 'react-redux';
import store from './redux/store'; // Importa tu store Redux
import { Notifications } from "@mantine/notifications";

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider withCssVariables>
        <Notifications position="top-right" />
        <App />
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();







/* CODIGO EN REACT 17 Y SALIA WARNING: 

index.js:19 Warning: ReactDOM.render is no longer supported in React 18. 
Use createRoot instead. Until you switch to the new API, 
your app will behave as if it's running React 17. 
Learn more: https://reactjs.org/link/switch-to-createroot


import React from 'react';
import ReactDOM from 'react-dom'; //OJO NO react-dom/client SOLO react-dom
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import '@mantine/notifications/styles.css';

import '@mantine/core/styles.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// para spinner

import { Provider } from 'react-redux';
import store from './redux/store'; // Importa tu store Redux
import { Notifications } from "@mantine/notifications";


ReactDOM.render(
  

  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider withCssVariables >
        <Notifications position="top-right" />
          <App />        
      </MantineProvider>
    </Provider>
  

  </React.StrictMode>,
  
  document.getElementById('root')
);

reportWebVitals();
*/
