import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from "@mantine/notifications";
import { TextInput, Button, Card, Stack, Title, Text } from "@mantine/core";
import { useAuth } from '../../context/AuthContext';
import logo from '../../assets/logo.png';
import logoins from '../../assets/abba.png';

function Login() {
    const navigate = useNavigate();
    const { signIn, user, loading } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const [logoinsDimensions, setLogoinsDimensions] = useState({ width: 0, height: 0 });

    // Función para obtener las dimensiones originales de la imagen logoins
    const getLogoinsDimensions = () => {
        const img = new Image();
        img.onload = () => {
            setLogoinsDimensions({ width: img.width, height: img.height });
        };
        img.src = logoins;
    };

    useEffect(() => {
        getLogoinsDimensions();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        

        try {
            await signIn({ email, password });
            navigate("/home");
            showNotification({
                title: '¡Inicio de sesión exitoso!',
                message: 'Ahora estás conectado',
                color: 'green',
            });

        } catch (error) {
            setError(error.message);
            showNotification({
                title: 'Usuario o contraseña erróneas',
                message: 'Intente nuevamente por favor',
                color: 'red',
            });
            
        } finally {

            setSubmitting(false);

        }
    };

    return (
        <div className="flex h-screen justify-center items-center auth">
            <Card
                sx={{
                    width: 600,
                    padding: "sm",

                }}
                shadow="lg"
                withBorder
            >

                <img src={logo} alt="strategos" style={{ marginBottom: '30px' }} />
                
                <Text ta="center" size="xl" fw={700} style={{ marginBottom: '10px' }}>Sistema Control Pagos Pólizas</Text>
                <form onSubmit={handleLogin}>
                    <Stack mt={5}>
                        <Text mb={-16}>
                            Correo:
                        </Text>
                        <TextInput
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Ingrese su Email"
                            disabled={submitting}
                        />
                        <Text mb={-16}>
                            Password:
                        </Text>
                        <TextInput
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Ingrese su Contraseña"
                            disabled={submitting}
                        />
                        <Button type="submit" color="#085284" disabled={submitting}>
                            {submitting ? 'Cargando...' : 'Ingresar'}
                        </Button>
                        <Text c="dimmed" size="sm" ta="center" >Desarrollado por insadisa.com</Text>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                            src={logoins}
                            alt="logoins"
                            style={{
                                width: logoinsDimensions.width,
                                height: logoinsDimensions.height,
                            }}
                        />
                        </div>



                    </Stack>
                </form>
            </Card>
        </div>
    );
}

export default Login;

/* Para mostrar el error poner: 
<Button type="submit" color="#085284" disabled={submitting}>
{submitting ? 'Cargando...' : 'Ingresar'}
</Button>
{error && <p>{error}</p>} 
*/







/*
Login 01 marzo 2024

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from "@mantine/notifications";
import { TextInput, Button, Card, Stack, Title, Divider } from "@mantine/core";
import { useAuth } from '../../context/AuthContext';

function Login() {
    const navigate = useNavigate();
    const { signIn, loading } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        try {
            await signIn({ email, password });
            navigate("/");
            showNotification({
                title: '¡Inicio de sesión exitoso!',
                message: 'Ahora estás conectado',
                color: 'green',
            });
            
        } catch (error) {
            setError(error.message);
            showNotification({
                title: 'Usuario o contraseña erróneas',
                message: 'Intente nuevamente por favor',
                color: 'red',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="flex h-screen justify-center items-center auth">
            <Card
                sx={{
                    width: 600,
                    padding: "sm",
                    
                }}
                shadow="lg"
                withBorder
            >
                <Title order={2} mb={5} c="grey">LOGIN</Title>
                <form onSubmit={handleLogin}>
                    <Stack mt={5}>
                        <TextInput
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Email"
                            disabled={submitting}
                        />
                        <TextInput
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Contraseña"
                            disabled={submitting}
                        />
                        <Button type="submit" color="#085284" disabled={submitting}>
                            {submitting ? 'Cargando...' : 'Ingresar'}
                        </Button>
                        {error && <p>{error}</p>}
                    </Stack>
                </form>
            </Card>
        </div>
    );
}

export default Login;
*/
