import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import moment from "moment";
import { IconDownload, IconExclamationCircle, IconHourglassLow, IconAlertTriangle, IconUrgent, IconXboxX, IconBrandWhatsapp } from '@tabler/icons-react';
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";




function PolizasNuevasTable({ pagos2, setSelectedTransaction, setFormMode, setShowForm, getData }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    const handleSelectedTransaction = (pago2) => {
        setSelectedTransaction(pago2);
        setFormMode("edit");
        setShowForm(true);
    };


    const getRows = pagos2.slice(startIndex, endIndex).map((pago2, index) => {
        // Definir los iconos basado en los días_para_pago
        

        return (
            <tr key={index}>
                <td>{pago2.periodo}</td>
                <td>{pago2.mes}</td>
                <td>{pago2.numero_poliza}</td>
                <td>{pago2.nombre_aseguradora}</td>
                <td>{pago2.nombre_tipo_seguro}</td>
                <td>{pago2.fecha_emision}</td>
                <td>{pago2.prima}</td>
                <td>{pago2.nombre_moneda}</td>
            </tr>
        );
    });



    const csvHeaders = [
        'Periodo',
        'Mes',
        'Póliza',
        'Aseguradora',
        'Tipo Seguro',
        'Fecha emision',
        'Prima',
        'Moneda',
    ];



    const csvData = pagos2.map((pago2) => [
        pago2.periodo,
        pago2.mes,
        pago2.numero_poliza,
        pago2.nombre_aseguradora,
        pago2.nombre_tipo_seguro,
        pago2.fecha_emision,
        pago2.prima,
        pago2.nombre_moneda
    ]);

    return (
        <div>
            <div>
                <Table
                    style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                    verticalSpacing="xl"
                    fontSize="sm"
                    mb={10}
                    striped
                    withRowBorders={true}
                    className="my-custom-table"
                >
                    <thead style={{ textAlign: 'left' }}>
                        
                        <tr>
                            <th style={{ padding: '3px' }}>Periodo</th>
                            <th style={{ padding: '3px' }}>Mes</th>
                            <th style={{ padding: '3px' }}>Póliza</th>
                            <th style={{ padding: '3px' }}>Aseguradora</th>
                            <th style={{ padding: '3px' }}>Tipo Seguro</th>
                            <th style={{ padding: '3px' }}>Fecha Emisión</th>
                            <th style={{ padding: '3px' }}>Prima</th>
                            <th style={{ padding: '3px' }}>Moneda</th>                        

                        </tr>

                    </thead>
                    <tbody>{getRows}</tbody>
                </Table>



                <Group justify="flex-end" mb={10}>



                    <CsvDownloader
                        filename={'polizas_nuevas_detalle.csv'}
                        separator=","
                        wrapColumnChar=""
                        datas={csvData}
                        columns={csvHeaders}
                    >
                        <Button variant="light" color='#00497A' leftSection={<IconDownload size={20} />} >Descargar Tabla</Button>
                    </CsvDownloader>
                    <Pagination
                        total={Math.ceil(pagos2.length / itemsPerPage)}
                        siblings={1}
                        withEdges
                        value={currentPage}
                        onChange={(newPage) => setCurrentPage(newPage)}
                        color='#00497A'
                    />
                </Group>
            </div>
        </div>
    );
};

export default PolizasNuevasTable;
