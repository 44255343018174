import Plan from './Plan';

const Planes = ({ planes }) => {
    return (
        <div>
            {planes.map((plan) => (
                <Plan key={plan.id_plan} plan={plan} />
            ))}
        </div>
    );
}

export default Planes;