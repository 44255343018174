import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import { useAuth } from "../context/AuthContext";




function TiposSegurosTable({ tiposseguros, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombreTipoSeguro }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);

    // Este useEffect sirve para revisar qué permisos tiene este usuario
    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);
                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        }
    }, [user, dispatch]);


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const tiposegurosFiltrados = filtroNombreTipoSeguro
        ? tiposseguros.filter(tiposeguro => tiposeguro.nombre_tipo_seguro.toLowerCase().includes(filtroNombreTipoSeguro.toLowerCase()))
        : tiposseguros;

    const paginatedTransactions = tiposegurosFiltrados.slice(startIndex, endIndex);


    const handleSelectedTransaction = (tiposeguro) => {
        //console.log("Tipo Contratante seleccionado:", tiposeguro); // Agregar este console.log para verificar el contratante seleccionado
        setSelectedTransaction(tiposeguro);
        setFormMode("edit");
        setShowForm(true);
    };

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_tipos_seguros')
                .delete()
                .eq('id_tipo_seguro', id);

            if (error) {
                throw new Error('No se pudo borrar el Tipo de Seguro');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Tipo de seguro borrado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Tipo de Seguro',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((tiposeguro) => (
        <tr key={tiposeguro.id_tipo_seguro}>
            <td>{tiposeguro.id_tipo_seguro}</td>
            <td>{tiposeguro.nombre_tipo_seguro}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tiposseguros &&
                        permisos.catalogos.modulos.cat_tiposseguros.acciones &&
                        permisos.catalogos.modulos.cat_tiposseguros.acciones.modificar_tiposseguro && (
                            <i
                                key={`edit-${tiposeguro.id_tipo_seguro}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(tiposeguro);
                                    setFormMode("edit");
                                    setShowForm(true);
                                    //console.log("Tipo Contratante seleccionado:", tiposeguro);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tiposseguros &&
                        permisos.catalogos.modulos.cat_tiposseguros.acciones &&
                        permisos.catalogos.modulos.cat_tiposseguros.acciones.eliminar_tiposseguro && (

                            <i
                                key={`delete-${tiposeguro.id_tipo_seguro}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar este Tipo de Pago?')) {
                                        deleteTransaction(tiposeguro.id_tipo_seguro);
                                    }
                                }}
                            ></i>
                        )}
                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre tipo contratante',
    ];

    const csvData = tiposseguros.map((tiposeguro) => [
        tiposeguro.id_tipo_seguro,
        tiposeguro.nombre_tipo_seguro,
    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Nombre tipo seguro</th>
                        <th>Acciones</th>


                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(tiposseguros.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'tiposseguros.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default TiposSegurosTable;