// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarLinksGroup_control__k-hsy {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  /*color: var(--mantine-color-text);*/
  color: white; /* Cambia el color del texto a blanco */
  font-size: var(--mantine-font-size-sm);

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}

.NavbarLinksGroup_link__HySSD {
  font-weight: 300;
  display: block;
  text-decoration: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  margin-left: var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-sm);
  /*color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));*/
  color:white; /* Poner color en blanco*/

  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}

.NavbarLinksGroup_chevron__DNPQf {
  transition: transform 200ms ease;
}`, "",{"version":3,"sources":["webpack://./src/components/NavbarLinksGroup.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,WAAW;EACX,4DAA4D;EAC5D,oCAAoC;EACpC,YAAY,EAAE,uCAAuC;EACrD,sCAAsC;;EAEtC;IACE,sFAAsF;IACtF,0EAA0E;EAC5E;AACF;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,4DAA4D;EAC5D,uCAAuC;EACvC,sCAAsC;EACtC,sCAAsC;EACtC,+EAA+E;EAC/E,WAAW,EAAE,yBAAyB;;EAEtC,2FAA2F;;EAE3F;IACE,sFAAsF;IACtF,0EAA0E;EAC5E;AACF;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".control {\n  font-weight: 500;\n  display: block;\n  width: 100%;\n  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);\n  /*color: var(--mantine-color-text);*/\n  color: white; /* Cambia el color del texto a blanco */\n  font-size: var(--mantine-font-size-sm);\n\n  @mixin hover {\n    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));\n    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));\n  }\n}\n\n.link {\n  font-weight: 300;\n  display: block;\n  text-decoration: none;\n  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);\n  padding-left: var(--mantine-spacing-md);\n  margin-left: var(--mantine-spacing-xl);\n  font-size: var(--mantine-font-size-sm);\n  /*color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));*/\n  color:white; /* Poner color en blanco*/\n\n  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));\n\n  @mixin hover {\n    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));\n    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));\n  }\n}\n\n.chevron {\n  transition: transform 200ms ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `NavbarLinksGroup_control__k-hsy`,
	"link": `NavbarLinksGroup_link__HySSD`,
	"chevron": `NavbarLinksGroup_chevron__DNPQf`
};
export default ___CSS_LOADER_EXPORT___;
