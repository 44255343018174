import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button, NativeSelect, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDispatch } from 'react-redux';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import {
    IconNotes,
    IconUser,
    IconDotsCircleHorizontal,
    IconBuildingBank,
} from '@tabler/icons-react';



function PagosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        id_pago_poliza: "",
        id_poliza: "",
        descripcion_pago: "",
        monto_pago: "",
        fecha_pago_requerida: "",
        fecha_pago_real: "",
        id_estatus_pago: "",
        notas_pago: "",
        id_conducto_cobro: "",
        id_aseguradora: "",
        id_tipo_seguro: "",
        id_moneda: "",
        nombre_moneda: "",
        nombre_tipo_seguro: "",
        nombre_aseguradora: "",
        numero_endoso: "",

        //id_contratante: "", //estos no son necesarios
        //nombre_contratante: "", //estos no son necesarios
        //numero_poliza: "", //estos no son necesarios
        //nombre_estatus_pago: "", //estos no son necesarios
        //nombre_conducto_cobro: "", //estos no son necesarios

    })

    const [checked, setChecked] = useState(false);
    const [checkedFlexible, setCheckedFlexible] = useState(false);



    // Este useEffect es porque en CatalogoPagos ya tomo el valor de id_pagos_poliza, entonces
    // este codigo evita que lo ponga en "" y me marque un warning
    useEffect(() => {
        if (!user.id_pago_poliza) {
            setUser({
                id_pago_poliza: "",
                id_poliza: "",
                descripcion_pago: "",
                monto_pago: "",
                fecha_pago_requerida: "",
                fecha_pago_real: "",
                id_estatus_pago: "",
                notas_pago: "",
                id_conducto_cobro: "",

                id_aseguradora: "",
                id_tipo_seguro: "",
                id_moneda: "",
                nombre_moneda: "",
                nombre_tipo_seguro: "",
                nombre_aseguradora: "",
                numero_endoso: "",
            });
        }
    }, [user.id_pago_poliza]);



    // Codigo para agregar al select las opciones 
    const [polizas, setPolizas] = useState([]); // para las polizas
    const [selectedPoliza, setSelectedPoliza] = useState("");

    //const [estatuspolizas, setEstatusPolizas] = useState([]); // YA NO SE USA SEGUN CHATGPT
    //const [selectedEstatusPoliza, setSelectedEstatusPoliza] = useState("");

    const [estatuspagos, setEstatusPagos] = useState([]); // para los estatus de pago
    const [selectedEstatusPago, setSelectedEstatusPago] = useState("1");

    //const [contratantes, setContratantes] = useState([]); // para los contratantes // YA NO SE USA SEGUN CHATGPT
    //const [selectedContratante, setSelectedContratante] = useState("");

    const [conductoscobros, setConductosCobros] = useState([]); // para los conductos de cobro
    const [selectedConductoCobro, setSelectedConductoCobro] = useState("");


    useEffect(() => {
        async function fetchTodasPolizas() {
            try {
                const { data: tipos } = await supabase
                    .from('polizas')
                    .select('id_poliza, numero_poliza')
                    .order('numero_poliza', { ascending: true });

                setPolizas(tipos);
                //console.log('Todas las polizas:', tipos);
            } catch (error) {
                console.error('Error al obtener las polizas:', error.message);
            }
        }

        async function fetchEstatusPagos() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_estatus_pagos')
                    .select('id_estatus_pago, nombre_estatus_pago')
                    .order('nombre_estatus_pago', { ascending: true });
                setEstatusPagos(tipos);
                //console.log('Tipos de estatus pagos:', tipos);
            } catch (error) {
                console.error('Error al obtener los estatus de los pagos:', error.message);
            }
        }


        async function fetchConductoCobros() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_conductos_cobros')
                    .select('id_conducto_cobro, nombre_conducto_cobro')
                    .order('nombre_conducto_cobro', { ascending: true });
                setConductosCobros(tipos);
                //console.log('Conductos cobros:', tipos);
            } catch (error) {
                //console.error('Error al obtener estatus Polizas:', error.message);
            }
        }


        fetchTodasPolizas();
        fetchEstatusPagos();
        fetchConductoCobros();

    }, []);


    useEffect(() => {
        //console.log("Transaction data:", transactionData);
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                id_pago_poliza: transactionData.id_pago_poliza,
                descripcion_pago: transactionData.descripcion_pago,
                nombre_contratante: transactionData.polizas.cat_contratantes.nombre_contratante,
                id_estatus_pago: transactionData.cat_estatus_pagos.id_estatus_pago,
                id_conducto_cobro: transactionData.cat_conductos_cobros.id_conducto_cobro,
                notas_pago: transactionData.notas_pago,
                monto_pago: transactionData.monto_pago,
                fecha_pago_requerida: transactionData.fecha_pago_requerida,
                fecha_pago_real: transactionData.fecha_pago_real,
                nombre_tipo_seguro: transactionData.polizas.cat_tipos_seguros.nombre_tipo_seguro,
                nombre_aseguradora: transactionData.polizas.cat_aseguradoras.nombre_aseguradora,
                nombre_moneda: transactionData.polizas.cat_monedas.nombre_moneda,
                numero_endoso: transactionData.numero_endoso,
                pago_flexible: transactionData.pago_flexible,


            });

            if (transactionData.tipo_pago === 2) {
                setChecked(true); // Si tipo_pago es 2, checked es true
            } else {
                setChecked(false); // Si tipo_pago es 1 (o cualquier otro valor), checked es false
            }

            if (transactionData.pago_flexible === 2) {
                setCheckedFlexible(true); // Si tipo_pago es 2, checked es true
            } else {
                setCheckedFlexible(false); // Si tipo_pago es 1 (o cualquier otro valor), checked es false
            }

            if (transactionData.polizas && polizas.length > 0) {
                const tiposPolizaEncontrados = polizas.find(t => t.id_poliza === transactionData.polizas.id_poliza);
                if (tiposPolizaEncontrados) {
                    setSelectedPoliza(tiposPolizaEncontrados.id_poliza);
                }
            }

            if (transactionData.cat_estatus_pagos && estatuspagos.length > 0) {
                const tiposEstatusPagosEncontrados = estatuspagos.find(t => t.id_estatus_pago === transactionData.cat_estatus_pagos.id_estatus_pago);
                if (tiposEstatusPagosEncontrados) {
                    setSelectedEstatusPago(tiposEstatusPagosEncontrados.id_estatus_pago);
                }
            }

            if (transactionData.cat_conductos_cobros && conductoscobros.length > 0) {
                const tiposConductosCobrosEncontrados = conductoscobros.find(t => t.id_conducto_cobro === transactionData.cat_conductos_cobros.id_conducto_cobro);
                if (tiposConductosCobrosEncontrados) {
                    setSelectedConductoCobro(tiposConductosCobrosEncontrados.id_conducto_cobro);
                }
            }

            //console.log("Transaction data2:", transactionData);
        }
    }, [formMode, transactionData, polizas, estatuspagos, conductoscobros]);

    // La función handleUpdateContratante se ajustó para actualizar solo los campos que el usuario puede editar.
    const handleUpdateContratante = async () => {
        try {
            let tipoPagoValue = checked ? 2 : 1; // Determina el valor de tipo_pago según el estado del checkbox
            let tipoFlexible = checkedFlexible ? 2 : 1;


            await supabase
                .from('pagos_polizas')
                .update({
                    descripcion_pago: user.descripcion_pago,
                    id_poliza: selectedPoliza,
                    id_estatus_pago: selectedEstatusPago,
                    id_conducto_cobro: selectedConductoCobro,
                    monto_pago: user.monto_pago,
                    fecha_pago_requerida: user.fecha_pago_requerida,
                    fecha_pago_real: user.fecha_pago_real === '' ? null : user.fecha_pago_real,
                    notas_pago: user.notas_pago,
                    numero_endoso: user.numero_endoso,
                    tipo_pago: tipoPagoValue,
                    pago_flexible: tipoFlexible,
                })
                .eq('id_pago_poliza', user.id_pago_poliza);
            //console.log("fecha real pago", user.fecha_pago_real)
            //console.log("tipoPagoValue", tipoPagoValue)


            showNotification({
                title: 'Pago póliza actualizado',
                message: 'Pago actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false);
            getData();
        } catch (error) {
            //console.error('Error al actualizar pago:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el pago.',
                color: 'red',
            });
        }
    };

    const getNombreContratante = async (idPoliza) => {
        try {
            const { data: polizaData, error } = await supabase
                .from('polizas')
                .select(`
                    id_poliza, 
                    cat_contratantes(
                        id_contratante, 
                        nombre_contratante)
                `)
                .eq('id_poliza', idPoliza)
                .single();

            if (error) {
                throw error;
            }

            // Verificar si se encontró la póliza y el contratante
            if (polizaData && polizaData.cat_contratantes) {
                const contratante = polizaData.cat_contratantes;

                // Retornar el nombre del contratante
                return contratante ? contratante.nombre_contratante : "";
            }

            return "";
        } catch (error) {
            //console.error('Error al obtener el nombre del contratante:', error.message);
            return "";
        }
    };

    const getTipoSeguro = async (idPoliza) => {
        try {
            const { data: tipoPolizaData, error } = await supabase
                .from('polizas')
                .select(`
                    id_poliza, 
                    cat_tipos_seguros(
                        id_tipo_seguro, 
                        nombre_tipo_seguro)
                `)
                .eq('id_poliza', idPoliza)
                .single();

            if (error) {
                throw error;
            }

            // Verificar si se encontró la póliza y el contratante
            if (tipoPolizaData && tipoPolizaData.cat_tipos_seguros) {
                const tipopoliza = tipoPolizaData.cat_tipos_seguros;

                // Retornar el nombre del contratante
                return tipopoliza ? tipopoliza.nombre_tipo_seguro : "";
            }

            return "";
        } catch (error) {
            //console.error('Error al obtener el nombre del tipo seguro:', error.message);
            return "";
        }
    };

    const getAseguradora = async (idPoliza) => {
        try {
            const { data: tipoAseguradoraData, error } = await supabase
                .from('polizas')
                .select(`
                    id_poliza, 
                    cat_aseguradoras(
                        id_aseguradora, 
                        nombre_aseguradora)
                `)
                .eq('id_poliza', idPoliza)
                .single();

            if (error) {
                throw error;
            }

            // Verificar si se encontró la póliza y el contratante
            if (tipoAseguradoraData && tipoAseguradoraData.cat_aseguradoras) {
                const tipoaseguradora = tipoAseguradoraData.cat_aseguradoras;

                // Retornar el nombre del contratante
                return tipoaseguradora ? tipoaseguradora.nombre_aseguradora : "";
            }

            return "";
        } catch (error) {
            //console.error('Error al obtener el nombre del tipo aseguradora:', error.message);
            return "";
        }
    };

    const getMoneda = async (idPoliza) => {
        try {
            const { data: tipoMonedaData, error } = await supabase
                .from('polizas')
                .select(`
                    id_poliza, 
                    cat_monedas(
                        id_moneda, 
                        nombre_moneda)
                `)
                .eq('id_poliza', idPoliza)
                .single();

            if (error) {
                throw error;
            }

            // Verificar si se encontró la póliza y el contratante
            if (tipoMonedaData && tipoMonedaData.cat_monedas) {
                const tipomoneda = tipoMonedaData.cat_monedas;

                // Retornar el nombre del contratante
                return tipomoneda ? tipomoneda.nombre_moneda : "";
            }

            return "";
        } catch (error) {
            //console.error('Error al obtener el nombre del tipo moneda:', error.message);
            return "";
        }
    };


    useEffect(() => {
        if (selectedPoliza) {
            getNombreContratante(selectedPoliza)
                .then(nombreContratante => {
                    setUser(prevUser => ({
                        ...prevUser,
                        nombre_contratante: nombreContratante
                    }));
                })
                .catch(error => {
                    //console.error('Error al obtener nombre del contratante:', error);
                });

            getTipoSeguro(selectedPoliza)
                .then(nombre_tipo_seguro => {
                    setUser(prevUser => ({
                        ...prevUser,
                        nombre_tipo_seguro: nombre_tipo_seguro
                    }));
                });

            getAseguradora(selectedPoliza)
                .then(nombre_aseguradora => {
                    setUser(prevUser => ({
                        ...prevUser,
                        nombre_aseguradora: nombre_aseguradora
                    }));
                });

            getMoneda(selectedPoliza)
                .then(nombre_moneda => {
                    setUser(prevUser => ({
                        ...prevUser,
                        nombre_moneda: nombre_moneda
                    }));
                });


        }


    }, [selectedPoliza]);



    const handleChange = (event) => {
        const { name, value } = event.target;

        // Si el campo es "fecha_pago_real" y el valor es vacío, establecer ""
        let newValue = name === "fecha_pago_real" && value === "" ? '' : value;

        // Normalizar texto a minúsculas y sin acentos si el campo es "notas_pago"
        if (name === "notas_pago") {
            newValue = normalizeText(value);
        }

        // Imprimir el valor de fecha_pago_requerida
        if (name === "fecha_pago_requerida") {
            //console.log("Nuevo valor de fecha_pago_requerida:", value);
        }

        setUser(prevFormData => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };

    // Función para normalizar texto a minúsculas y sin acentos
    const normalizeText = (text) => {
        return text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""); // Elimina los diacríticos (acentos)
    };


    const calcularEstatusPago = (fechaPagoReal, fechaPagoRequerida) => {
        if (fechaPagoReal !== '') {
            const fechaPagoRealDate = new Date(fechaPagoReal);
            const fechaPagoRequeridaDate = new Date(fechaPagoRequerida);

            // Comparación de fechas
            if (fechaPagoRealDate <= fechaPagoRequeridaDate) {
                return "2"; // PAGADO
            } else {
                const diferenciaDias = (fechaPagoRealDate - fechaPagoRequeridaDate) / (1000 * 60 * 60 * 24);
                //console.log(`Diferencia en días: ${diferenciaDias}`);
                if (diferenciaDias < 15) {
                    return "2"; // PAGADO (menos de 15 días de diferencia)
                } else {
                    return "5"; // PAGADO EN PERIODO DE GRACIA (15 días o más de diferencia)
                }
            }
        }
        return "1"; // Por defecto, "Pendiente"
    };



    const handleFechaPagoRealChange = (event) => {
        const { value } = event.target;
        handleChange(event); // Actualizar el estado con la nueva fecha

        const estatusPago = calcularEstatusPago(value, user.fecha_pago_requerida, user.pago_flexible);
        setSelectedEstatusPago(estatusPago);
    };


    const handlers = {
        id_poliza: (value) => {
            setSelectedPoliza(value);
            setUser(prevFormData => ({
                ...prevFormData,
                nombre_contratante: getNombreContratante(value),
                nombre_tipo_seguro: getTipoSeguro(value),
                nombre_aseguradora: getAseguradora(value),
                nombre_moneda: getMoneda(value)
            }));
        },
        id_estatus_pago: setSelectedEstatusPago,
        id_conducto_cobro: setSelectedConductoCobro,
        checked: (value) => {
            setChecked(value);
            // Determina el valor de tipo_pago según el estado del checkbox
            let tipoPagoValue = value ? 2 : 1;
            setUser(prevFormData => ({
                ...prevFormData,
                tipo_pago: tipoPagoValue,
                numero_endoso: value ? prevFormData.numero_endoso : "" // Si value es true, establece numero_endoso como null, de lo contrario, deja el valor anterior
            }));
        },

        checkedFlexible: (value) => {
            setCheckedFlexible(value);
            // Determina el valor de tipo_pago según el estado del checkbox
            let tipoFlexible = value ? 2 : 1;
            setUser(prevFormData => ({
                ...prevFormData,
                pago_flexible: tipoFlexible,
            }));
        },
    };
    const handleChangeSelect = async (event) => {
        const { name, value } = event.target;
        const handler = handlers[name];
        if (handler) {
            handler(value);

            if (name === "id_poliza") {
                const nombreContratante = await getNombreContratante(value);
                const nombreTipoSeguro = await getTipoSeguro(value);
                const nombreAseguradora = await getAseguradora(value);
                const nombreMoneda = await getMoneda(value);
                setUser((prevFormData) => ({
                    ...prevFormData,
                    nombre_contratante: nombreContratante,
                    nombre_tipo_seguro: nombreTipoSeguro,
                    nombre_aseguradora: nombreAseguradora,
                    nombre_moneda: nombreMoneda,
                }));
            }
        }
    };



    const handleSubmit = async (event) => {
        event.preventDefault();


        try {
            dispatch(ShowLoading());
            let tipoPagoValue = checked ? '2' : '1'; //Si checked es true, tipoPagoValue es 2, de lo contrario, es 1
            //console.log('Tipo value para tipo de pago', tipoPagoValue)
            let tipoFlexible = checkedFlexible ? '2' : '1';
            //console.log('tipo flexible', tipoFlexible)

            if (formMode === 'add') {
                //console.log("Valor de tipoFlexible:", tipoFlexible); // Agregar aquí el console.log

                await supabase
                    .from('pagos_polizas')
                    .insert({
                        id_poliza: selectedPoliza,
                        descripcion_pago: user.descripcion_pago,
                        monto_pago: user.monto_pago,
                        fecha_pago_requerida: user.fecha_pago_requerida,
                        //fecha_pago_real: user.fecha_pago_real || null,
                        fecha_pago_real: user.fecha_pago_real === '' ? null : user.fecha_pago_real,

                        id_estatus_pago: selectedEstatusPago,
                        notas_pago: user.notas_pago,
                        id_conducto_cobro: selectedConductoCobro,
                        numero_endoso: user.numero_endoso,
                        tipo_pago: tipoPagoValue, // Asigna el valor calculado de tipo_pago
                        pago_flexible: tipoFlexible // valor de flexible


                    });
                //console.log("fecha real enviada insert", user.fecha_pago_real)

                showNotification({
                    title: 'Pago agregado',
                    message: 'La póliza ha sido agregado con éxito',
                    color: 'green',
                });
                dispatch(HideLoading());
                setShowForm(false);
                getData();

            } else if (formMode === 'edit') {
                await handleUpdateContratante();

            }
            dispatch(HideLoading());
            setShowForm(false);
            getData();
        } catch (error) {
            //console.error('Error al agregar/actualizar pago:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el pago.',
                color: 'red',
            });
        }
    };

    //console.log("contratantessss",contratantes);
    //console.log("seleccionado", selectedContratante);
    //console.log("Poliza selec", selectedPoliza)


    //console.log("SelectedTipoContratante antes del return:", selectedTipoContratante);


    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                        <Group >
                            <TextInput
                                disabled
                                name="id_pago_poliza"
                                label="ID"
                                //onChange={handleChange}
                                style={{ width: '300px' }}
                                defaultValue={user.id_pago_poliza}

                            />

                            <TextInput
                                name="descripcion_pago"
                                label="Descripción pago"
                                style={{ width: '300px' }}
                                required
                                onChange={handleChange}
                                value={user.descripcion_pago} // Asignar el valor del estado al campo
                                placeholder='Descripción'

                            />

                        </Group>
                        <Group >
                            <NativeSelect
                                name="id_poliza"
                                label="Póliza"
                                style={{ width: '300px', fontWeight: 'bold' }}
                                value={selectedPoliza}
                                onChange={handleChangeSelect}
                                placeholder='Póliza'
                                disabled={formMode === 'edit'} // para deshabilitar si es edit
                                className='input'
                                required

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {polizas.map((tipo) => (
                                    <option key={tipo.id_poliza} value={tipo.id_poliza}>
                                        {tipo.numero_poliza}
                                    </option>
                                ))}
                            </NativeSelect>
                            <Checkbox
                                label="Pago Flexible"
                                labelPosition="left"
                                checked={checkedFlexible}
                                onChange={(event) => handlers.checkedFlexible(event.currentTarget.checked)}
                                style={{ width: '300px', fontWeight: 'bold' }}
                            //disabled={formMode === 'edit'}

                            />
                        </Group>
                        <Group >
                            <Checkbox
                                label="Este pago deriva de un Endoso"
                                labelPosition="left"
                                checked={checked}
                                onChange={(event) => handlers.checked(event.currentTarget.checked)}
                                //onChange={(event) => setChecked(event.currentTarget.checked)}
                                style={{ width: '300px', fontWeight: 'bold' }}
                                disabled={formMode === 'edit'}

                            />

                            <TextInput
                                name="numero_endoso"
                                label="Endoso"
                                style={{ width: '300px' }}
                                onChange={handleChange}
                                value={user.numero_endoso}
                                placeholder='Endoso'
                                disabled={formMode === 'edit' || checked === false}
                            />






                        </Group>
                        <Group>
                            <TextInput
                                //disabled
                                name="nombre_aseguradora"
                                label="Aseguradora"
                                style={{ width: '300px', fontWeight: 'bold' }}
                                defaultValue={user.nombre_aseguradora}
                                disabled
                            />
                            <TextInput
                                //disabled
                                name="nombre_tipo_seguro"
                                label="T. Seguro"
                                style={{ width: '300px', fontWeight: 'bold' }}
                                defaultValue={user.nombre_tipo_seguro}
                                disabled
                            />

                        </Group>
                        <Group >
                            <TextInput
                                //disabled
                                name="nombre_contratante"
                                label="Contratante"
                                style={{ width: '300px', fontWeight: 'bold' }}
                                defaultValue={user.nombre_contratante}
                                disabled
                            />
                            <NativeSelect
                                name="id_conducto_cobro"
                                label="Conducto cobro"
                                style={{ width: '300px' }}
                                value={selectedConductoCobro}
                                onChange={handleChangeSelect}
                                placeholder='Conducto cobro'
                                required

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {conductoscobros.map((tipo) => (
                                    <option key={tipo.id_conducto_cobro} value={tipo.id_conducto_cobro}>
                                        {tipo.nombre_conducto_cobro}
                                    </option>
                                ))}
                            </NativeSelect>
                        </Group>
                        <Group>
                            <TextInput
                                name="monto_pago"
                                label="Monto"
                                style={{ width: '300px' }}
                                required
                                type='number'
                                onChange={handleChange}
                                value={user.monto_pago} // Asignar el valor del estado al campo
                                placeholder='Solo números'

                            />
                            <TextInput
                                //disabled
                                name="nombre_moneda"
                                label="Moneda"
                                style={{ width: '300px', fontWeight: 'bold' }}
                                defaultValue={user.nombre_moneda}
                                disabled
                            />

                        </Group>
                        <Group>
                            <TextInput
                                name="fecha_pago_requerida"
                                label="Fecha pago requerida"
                                style={{ width: '300px' }}
                                required
                                type='date'
                                onChange={handleChange}
                                value={user.fecha_pago_requerida} // Asignar el valor del estado al campo
                                placeholder=''

                            />
                            <TextInput
                                name="fecha_pago_real"
                                label="Fecha pago real"
                                style={{ width: '300px' }}
                                // required // No es required porque puede ser que no haya pagado aún
                                type='date'
                                onChange={handleFechaPagoRealChange}
                                value={user.fecha_pago_real || ''}
                                placeholder=''
                            />



                        </Group>
                        <Group>
                            <TextInput
                                name="id_estatus_pago"
                                label="Estatus pago"
                                style={{ width: '300px' }}
                                value={
                                    estatuspagos.find((estatus) => estatus.id_estatus_pago === selectedEstatusPago)?.nombre_estatus_pago ||
                                    ""
                                } // Buscar el nombre del estatus correspondiente a selectedEstatusPago
                                //onChange={handleChangeSelect} // Esto es opcional, puede eliminarlo si no se necesita
                                placeholder=''
                                disabled // Deshabilitar el TextInput para que el usuario no pueda editar
                                required
                            />

                            <TextInput
                                name="notas_pago"
                                label="Notas"
                                style={{ width: '300px' }}

                                type='text'
                                onChange={handleChange}
                                value={user.notas_pago} // Asignar el valor del estado al campo
                                placeholder=''

                            />



                        </Group>
                        <Group>


                        </Group>


                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Pago" : "Editar Pago"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default PagosForm;