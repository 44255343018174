import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import EstatusPolizasForm from '../components/EstatusPolizasForm';
import { Group, Button, Divider, Modal, Card, TextInput, Accordion, Text } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import EstatusPolizasTable from '../components/EstatusPolizasTable';
import { useAuth } from '../context/AuthContext';




function CatalogoEstatusPolizas() {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [estatuspolizas, setEstatusPolizas] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroEstatusPoliza, setFiltroEstatusPoliza] = useState('');

    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);
                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        }
    }, [user, dispatch]);


    useEffect(() => {
        //body
        try {
            const getEstatusPolizas = async () => {
                dispatch(ShowLoading());
                const allPlanes = await fetchEstatusPolizas()
                setEstatusPolizas(allPlanes) //populate the react state
                // Llamar a la función contarContratantes para obtener el conteo al cargar el componente
                //contarContratantes();
                dispatch(HideLoading());
            }

            getEstatusPolizas()

        } catch (error) {
            //console.error('Error:', error);
            dispatch(HideLoading());
        }
    }, [])

    /* ESTA CONST ES PARA QUERY SOLO CAT_TIPOS_CONTRATANTES */

    const fetchEstatusPolizas = async () => {
        try {
            let { data: estatuspolizas, error } = await supabase
                .from('cat_estatus_polizas')
                .select(`
                id_estatus_poliza,
                nombre_estatus_poliza
                
                `)
                .order('id_estatus_poliza', { ascending: true })
            //console.log("Tipo de planes obtenidos:", planes); // Agregar este console.log para verificar los contratantes obtenidos

            if (error) {
                throw error;
            }
            return estatuspolizas;
        } catch (error) {
            //console.error('Error al obtener planes:', error.message);
            throw error; // Propaga el error para que se maneje en el componente padre si es necesario
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: planesFiltrados, error } = await supabase
                .from('cat_estatus_polizas')
                .select(`
                id_estatus_poliza,
                nombre_estatus_poliza
                
                `)
                .ilike('nombre_estatus_poliza', `%${filtroEstatusPoliza.toLowerCase()}%`)
                .order('id_estatus_poliza', { ascending: true });

            if (error) {
                throw error;
            }
            setEstatusPolizas(planesFiltrados);
        } catch (error) {
            //console.error('Error al filtrar Estatus de Póliza:', error.message);
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };



    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            //console.log('Estado de carga activado');
            const allPlanes = await fetchEstatusPolizas();
            setEstatusPolizas(allPlanes);
        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddEstatusPolizas = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_estatuspolizas &&
        permisos.catalogos.modulos.cat_estatuspolizas.acciones &&
        permisos.catalogos.modulos.cat_estatuspolizas.acciones.registrar_estatuspoliza === true;


    return (
        <div>
            <Card className='CardTituloCatalogos'>
            <Text
                        size="xl"
                        fw={900}
                    >Estatus Pólizas
                    </Text>
            </Card>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar Estatus Póliza"
                                value={filtroEstatusPoliza}
                                onChange={(event) => setFiltroEstatusPoliza(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />

                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddEstatusPolizas && (
                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Aseguradora
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>

                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Estatus de Póliza" : "Editar Estatus de Póliza"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <EstatusPolizasForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar el contratante seleccionado como prop
                    />

                </Modal>
                <div style={{ overflowX: "auto" }}>
                    
                    <EstatusPolizasTable
                        estatuspolizas={estatuspolizas}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                    />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoEstatusPolizas;