import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from "../redux/alertsSlice";
import Login from '../pages/Auth/Login'; // Importa el componente Login

function ProtectedRoute({ children }) {
    const { user, loading } = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        if (loading) {
            dispatch(ShowLoading());
        } else {
            dispatch(HideLoading());
        }
    }, [loading, dispatch]);

    if (loading) {
        // Si está cargando, puedes mostrar un spinner u otro indicador de carga
        return <p>...</p> ;
    }

    if (!user) {
        // Si no hay usuario autenticado, redirige al usuario a la página de inicio de sesión
        return <Navigate to="/login" replace />;
    }

    // Si hay un usuario autenticado, muestra la ruta protegida
    return children;
}

export default ProtectedRoute;
