import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import moment from "moment";
import { IconDownload, IconExclamationCircle, IconHourglassLow, IconAlertTriangle, IconUrgent, IconXboxX, IconBrandWhatsapp } from '@tabler/icons-react';
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";




function PolizasNuevasTable3({ pagos3, setSelectedTransaction, setFormMode, setShowForm, getData }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    const handleSelectedTransaction = (pago3) => {
        setSelectedTransaction(pago3);
        setFormMode("edit");
        setShowForm(true);
    };


    const getRows = pagos3.slice(startIndex, endIndex).map((pago3, index) => {
        // Definir los iconos basado en los días_para_pago


        return (
            <tr key={index}>
                <td>{pago3.periodo}</td>
                <td>{pago3.mes}</td>
                <td>{pago3.numero_poliza}</td>
                <td>{pago3.nombre_aseguradora}</td>
                <td>{pago3.nombre_tipo_seguro}</td>
                <td>{pago3.descripcion_pago}</td>
                <td>{pago3.tipo_pago}</td>
                <td>{pago3.monto_pago}</td>
                <td>{pago3.nombre_moneda}</td>
                <td style={
                    pago3.nombre_estatus_pago === 'Pendiente' ? { backgroundColor: '#D5133A', color: 'white' } :
                        pago3.nombre_estatus_pago === 'Pagado' ? { backgroundColor: '#4CBB16', color: 'white' } :
                            pago3.nombre_estatus_pago === 'Demorado' ? { backgroundColor: '#E68C02', color: 'white' } : {}
                }>{pago3.nombre_estatus_pago}</td>
            </tr>
        );
    });



    const csvHeaders = [
        'Periodo',
        'Mes',
        'Póliza',
        'Aseguradora',
        'Tipo Seguro',
        'Descripción',
        'Tipo',
        'Monto',
        'Moneda',
        'Estatus pago',
    ];



    const csvData = pagos3.map((pago3) => [
        pago3.periodo,
        pago3.mes,
        pago3.numero_poliza,
        pago3.nombre_aseguradora,
        pago3.nombre_tipo_seguro,
        pago3.descripcion_pago,
        pago3.tipo_pago,
        pago3.monto_pago,
        pago3.nombre_moneda,
        pago3.nombre_estatus_pago,
    ]);

    return (
        <div>
            <div>
                <Table
                    style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                    verticalSpacing="xl"
                    fontSize="sm"
                    mb={10}
                    striped
                    withRowBorders={true}
                    className="my-custom-table"
                >
                    <thead style={{ textAlign: 'left' }}>

                        <tr>
                            <th style={{ padding: '3px' }}>Periodo</th>
                            <th style={{ padding: '3px' }}>Mes</th>
                            <th style={{ padding: '3px' }}>Póliza</th>
                            <th style={{ padding: '3px' }}>Aseguradora</th>
                            <th style={{ padding: '3px' }}>Tipo Seguro</th>
                            <th style={{ padding: '3px' }}>Descripción pago</th>
                            <th style={{ padding: '3px' }}>Tipo</th>
                            <th style={{ padding: '3px' }}>Monto</th>
                            <th style={{ padding: '3px' }}>Moneda</th>
                            <th style={{ padding: '3px' }}>Estatus pago</th>

                        </tr>

                    </thead>
                    <tbody>{getRows}</tbody>
                </Table>
                <Group justify="flex-end" mb={10}>
                    <CsvDownloader
                        filename={'detalle_pagos_polizas_nuevas.csv'}
                        separator=","
                        wrapColumnChar=""
                        datas={csvData}
                        columns={csvHeaders}
                    >
                        <Button variant="light" color='#00497A' leftSection={<IconDownload size={20} />} >Descargar Tabla</Button>
                    </CsvDownloader>
                    <Pagination
                        total={Math.ceil(pagos3.length / itemsPerPage)}
                        siblings={1}
                        withEdges
                        value={currentPage}
                        onChange={(newPage) => setCurrentPage(newPage)}
                        color='#00497A'
                    />
                </Group>
            </div>
        </div>
    );
};

export default PolizasNuevasTable3;
