import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import Header from '../components/Header';
import DuracionesPagosTable from '../components/DuracionesPagosTable';
import DuracionesPagosForm from '../components/DuracionesPagosForm';
import { Group, Button, Text, Accordion, Modal, Card, TextInput } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { useAuth } from '../context/AuthContext';




function CatalogoDuracionesPagos() {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [duracionespagos, setDuracionespagos] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroDuracionPago, setFiltroDuracionPago] = useState('');
    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);
                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        }
    }, [user, dispatch]);


    useEffect(() => {
        //body
        try {
            const getDuracionesPagos = async () => {
                dispatch(ShowLoading());
                const allDuracionesPagos = await fetchDuracionesPagos()
                setDuracionespagos(allDuracionesPagos) //populate the react state
                // Llamar a la función contarContratantes para obtener el conteo al cargar el componente
                //contarContratantes();
                dispatch(HideLoading());
            }
            getDuracionesPagos()

        } catch (error) {
            //console.error('Error:', error);
            dispatch(HideLoading());
        }
    }, [])

    /* ESTA CONST ES PARA QUERY SOLO CAT_TIPOS_CONTRATANTES */

    const fetchDuracionesPagos = async () => {
        try {
            let { data: duracionespagos, error } = await supabase
                .from('cat_duraciones_pagos')
                .select(`
                id_duracion_pago,
                nombre_duracion_pago
                
                `)
                .order('id_duracion_pago', { ascending: true })
            //console.log("Duracion de pagos obtenidos:", duracionespagos); // Agregar este console.log para verificar los contratantes obtenidos

            if (error) {
                throw error;
            }
            return duracionespagos;
        } catch (error) {
            //console.error('Error al obtener duracion pagos:', error.message);
            throw error; // Propaga el error para que se maneje en el componente padre si es necesario
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: aseguradorasFiltradas, error } = await supabase
                .from('cat_duraciones_pagos')
                .select(`
                id_duracion_pago,
                nombre_duracion_pago
                
                `)
                .ilike('nombre_duracion_pago', `%${filtroDuracionPago.toLowerCase()}%`)
                .order('id_duracion_pago', { ascending: true });

            if (error) {
                throw error;
            }
            setDuracionespagos(aseguradorasFiltradas);
        } catch (error) {
            //console.error('Error al filtrar duraciones de pago:', error.message);
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          handleBuscar();
        }
      };



    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            //console.log('Estado de carga activado');
            const allContratantes = await fetchDuracionesPagos();
            setDuracionespagos(allContratantes);
        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddDuracionesPagos = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_duracionespagos &&
        permisos.catalogos.modulos.cat_duracionespagos.acciones &&
        permisos.catalogos.modulos.cat_duracionespagos.acciones.registrar_duracionpago === true;


    return (
        <div>
            <Card className='CardTituloCatalogos'>
            <Text
                        size="xl"
                        fw={900}
                    >Catálogo Duraciones de Pago
                    </Text>
            </Card>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                        <TextInput
                            placeholder="Buscar"
                            value={filtroDuracionPago}
                            onChange={(event) => setFiltroDuracionPago(event.target.value)}
                            onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddDuracionesPagos && (
                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Duración de Pago
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Duración pago" : "Editar Duración pago"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <DuracionesPagosForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar aseguradora seleccionada como prop
                    />

                </Modal>
                <div style={{ overflowX: "auto" }}>
                
                <DuracionesPagosTable
                    duracionespagos={duracionespagos}
                    setSelectedTransaction={setSelectedTransaction}
                    setFormMode={setFormMode}
                    setShowForm={setShowForm}
                    getData={getData}
                />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoDuracionesPagos;