import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import moment from "moment";
import { IconDownload, IconExclamationCircle, IconHourglassLow, IconAlertTriangle, IconUrgent, IconXboxX, IconBrandWhatsapp } from '@tabler/icons-react';
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";




function PolizasNuevasTable({ pagos, setSelectedTransaction, setFormMode, setShowForm, getData }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    const handleSelectedTransaction = (pago) => {
        setSelectedTransaction(pago);
        setFormMode("edit");
        setShowForm(true);
    };


    const getRows = pagos.slice(startIndex, endIndex).map((pago, index) => {
        

        return (
            <tr key={index}>
                <td>{pago.periodo}</td>
                <td>{pago.mes}</td>
                <td>{pago.aseguradora}</td>
                <td>{pago.tipo_seguro}</td>
                <td>{pago.cantidad_polizas}</td>
                <td>{pago.suma_primas_mxn}</td>
                <td>{pago.suma_primas_dlls}</td>
                <td>{pago.suma_primas_udis}</td>




            </tr>
        );
    });



    const csvHeaders = [
        'Periodo',
        'Mes',
        'Aseguradora',
        'Tipo de Seguro',
        'Cantidad',
        'Suma prima MXN',
        'Suma prima DLL',
        'Suma prima UDIS',
    ];



    const csvData = pagos.map((pago) => [
        pago.periodo,
        pago.mes,
        pago.aseguradora,
        pago.tipo_seguro,
        pago.cantidad_polizas,
        pago.suma_primas_mxn,
        pago.suma_primas_dlls,
        pago.suma_primas_udis
    ]);





    return (
        <div>
            <div>
                <Table
                    style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                    verticalSpacing="xl"
                    fontSize="sm"
                    mb={10}
                    striped
                    withRowBorders={true}
                    className="my-custom-table"
                >
                    <thead style={{ textAlign: 'left' }}>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th colSpan="3">Suma Primas</th>
                        </tr>
                        <tr>
                            <th style={{ padding: '3px' }}>Periodo</th>
                            <th style={{ padding: '3px' }}>Mes</th>
                            <th style={{ padding: '3px' }}>Aseguradora</th>
                            <th style={{ padding: '3px' }}>Tipo de Seguro</th>
                            <th style={{ padding: '3px' }}>Cantidad</th>
                            <th style={{ padding: '3px' }}>MXN</th>
                            <th style={{ padding: '3px' }}>DLL</th>
                            <th style={{ padding: '3px' }}>UDIS</th>
                        </tr>
                        
                    </thead>
                    <tbody>{getRows}</tbody>
                </Table>




                <Group justify="flex-end" mb={10}>



                    <CsvDownloader
                        filename={'polizas_nuevas.csv'}
                        separator=","
                        wrapColumnChar=""
                        datas={csvData}
                        columns={csvHeaders}
                    >
                        <Button variant="light" color='#00497A' leftSection={<IconDownload size={20} />} >Descargar Tabla</Button>
                    </CsvDownloader>
                    <Pagination
                        total={Math.ceil(pagos.length / itemsPerPage)}
                        siblings={1}
                        withEdges
                        value={currentPage}
                        onChange={(newPage) => setCurrentPage(newPage)}
                        color='#00497A'
                    />
                </Group>
            </div>
        </div>
    );
};

export default PolizasNuevasTable;
