import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Text, Group, Select, Button, NativeSelect, Collapse } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';



function TiposSegurosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        nombre_tipo_seguro: "",
        id_tipo_seguro: "" // inicializar

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_tipo_seguro: transactionData.nombre_tipo_seguro,
                id_tipo_seguro: transactionData.id_tipo_seguro

            });
            //console.log("Transaction data2:", transactionData);
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateContratante = async () => {
        try {
            dispatch(ShowLoading());
            await supabase
                .from('cat_tipos_seguros')
                .update({
                    nombre_tipo_seguro: user.nombre_tipo_seguro
                })
                .eq('id_tipo_seguro', user.id_tipo_seguro); //Este método se utiliza para filtrar las filas en una tabla donde el valor de una columna específica es igual a un valor dado.

            showNotification({
                title: 'Tipo seguro actualizado',
                message: 'El tipo seguro se ha actualizado correctamente.',
                color: 'green',
            });
            dispatch(HideLoading());
            setShowForm(false);
            getData();
        } catch (error) {
            //console.error('Error al actualizar tipo seguro:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el tipo seguro.',
                color: 'red',
            });
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            if (formMode === 'add') {
                // Buscar el id_tipo_contratante correspondiente al nombre_tipo_contratante seleccionado
                dispatch(ShowLoading());
                const { error } = await supabase
                    .from('cat_tipos_seguros')
                    .insert({
                        nombre_tipo_seguro: user.nombre_tipo_seguro
                    });
                showNotification({
                    title: 'Tipo de Seguro agregado',
                    message: 'El tipo de seguro ha sido agregado con éxito',
                    color: 'green',
                });

                if (error) {
                    throw error;
                }
            } else if (formMode === 'edit') {
                await handleUpdateContratante();
            }
            setShowForm(false);
            getData();
            dispatch(HideLoading());
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar tipo de seguro:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el tipo de seguro.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>

                        <TextInput
                            disabled
                            name="id_tipo_seguro"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={user.id_tipo_seguro}

                        />

                        <TextInput
                            name="nombre_tipo_seguro"
                            label="Nombre tipo seguro"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={user.nombre_tipo_seguro} // Asignar el valor del estado al campo
                            placeholder='Tipo seguro'

                        />



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar tipo de seguro" : "Editar tipo de seguro"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default TiposSegurosForm;