import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { useDispatch } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import { HideLoading, ShowLoading } from '../redux/alertsSlice';
import moment from 'moment';

function ReporteCumpleanerosTable({ polizas, setSelectedTransaction, setFormMode, setShowForm, getData }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();

    // Función para calcular la edad
    const calcularEdad = (fechaNacimiento) => {
        const fechaActual = moment();
        const fechaNacimientoMoment = moment(fechaNacimiento, 'YYYY-MM-DD');
        const edad = fechaActual.diff(fechaNacimientoMoment, 'years');
        return edad;
    };

    const getRows = polizas
        .slice(startIndex, endIndex)
        .map((poliza) => {
            // Calcular la fecha actual
            const fechaActual = moment();

            // Calcular la fecha de nacimiento del asegurado
            const fechaNacimiento = moment(poliza.fecha_nacimiento_asegurado, 'YYYY-MM-DD');

            // Calcular la edad usando la función calcularEdad
            const edad = calcularEdad(poliza.fecha_nacimiento_asegurado);

            return (
                <tr key={poliza.id_poliza}>
                    <td>{poliza.id_poliza}</td>
                    <td>{poliza.mes}</td>
                    <td>{poliza.nombre_asegurado}</td>
                    <td>{moment(poliza.fecha_nacimiento_asegurado).format('DD-MM-YYYY')}</td>
                    <td>{edad}</td>
                    <td>{poliza.numero_poliza}</td>
                    <td>{poliza.cat_estatus_polizas.nombre_estatus_poliza}</td>
                    <td>{poliza.cat_aseguradoras.nombre_aseguradora}</td>
                    <td>{poliza.cat_contratantes.nombre_contratante}</td>
                    {/* Agrega más columnas según sea necesario */}
                </tr>
            );
        });

    const csvHeaders = ['ID póliza', 'Mes', 'Asegurado', 'F. Nacimiento', 'Edad', 'Póliza', 'Estatus póliza', 'Aseguradora', 'Contratante'];
    const csvData = polizas.map((poliza) => [
        poliza.id_poliza,
        poliza.mes,
        poliza.nombre_asegurado,
        poliza.fecha_nacimiento_asegurado,
        calcularEdad(poliza.fecha_nacimiento_asegurado),
        poliza.numero_poliza,
        poliza.cat_estatus_polizas.nombre_estatus_poliza,
        poliza.cat_aseguradoras.nombre_aseguradora,
        poliza.cat_contratantes.nombre_contratante,
    ]);

    return (
        <div>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead style={{ textAlign: 'left' }}>
                    <tr>
                        <th>ID póliza</th>
                        <th>Mes</th>
                        <th>Asegurado</th>
                        <th>F. Nacimiento</th>
                        <th>Edad</th>
                        <th>Póliza</th>
                        <th>Estatus Póliza</th>
                        <th>Aseguradora</th>
                        <th>Contratante</th>
                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(polizas.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color="#00497A"
                />
                <CsvDownloader filename={'cumpleaneros.csv'} separator="," wrapColumnChar="" datas={csvData} columns={csvHeaders}>
                    <Button variant="light" color="#00497A">
                        Descargar Tabla
                    </Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default ReporteCumpleanerosTable;
