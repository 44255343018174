//import '@mantine/dates/styles.css';
import React, { useEffect } from 'react';
import { Group, Button, Autocomplete } from '@mantine/core';
import { useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { supabase } from "../supabaseClient";
import { IconCalendar, IconSearch } from '@tabler/icons-react';
import { rem } from '@mantine/core';






function FiltrosPolizas({ setFilters, filters, getData }) {
    const icon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

    const [numeroPolizas, setNumeroPolizas] = useState([]);
    const [contratante, setContratante] = useState([]);
    const [aseguradoras, setAseguradora] = useState([]);
    const [tiposeguro, setTipoSeguro] = useState([]);
    const [planes, setPlanes] = useState([]);
    const [duracionpagos, setDuracionpagos] = useState([]);
    const [formapagos, setFormapagos] = useState([]);
    const [monedas, setMonedas] = useState([]);
    const [estatuspoliza, setEstatuspoliza] = useState([]);
    const [fechaInicial, setFechaInicial] = useState(null);
    const [fechaFinal, setFechaFinal] = useState(null);


    useEffect(() => {
        const fetchNumeroPoliza = async () => {
            try {
                // Consulta para select 
                const { data: numeropolizaData, error: estatusError } = await supabase
                    .from('polizas')
                    .select('numero_poliza');

                if (estatusError) {
                    throw estatusError;
                }

                const numeropolizasOptions = numeropolizaData.map((estatus) => ({
                    label: estatus.numero_poliza,
                    value: estatus.numero_poliza
                }));

                setNumeroPolizas(numeropolizasOptions);
            } catch (error) {
                console.error('Error al obtener los numeros de poliza:', error.message);
            }
        };

        const fetchContratantesData = async () => {
            try {
                // Consulta para select de Pólizas
                const { data: contratantesData, error: polizasError } = await supabase
                    .from('cat_contratantes')
                    .select('nombre_contratante')
                    .order('nombre_contratante', { ascending: true });



                if (polizasError) {
                    throw polizasError;
                }

                const contratantesOptions = contratantesData.map((contratante) => ({
                    label: contratante.nombre_contratante,
                    value: contratante.nombre_contratante
                }));

                setContratante(contratantesOptions);
            } catch (error) {
                console.error('Error al obtener las pólizas:', error.message);
            }
        };

        const fetchAseguradorasData = async () => {
            try {
                // Consulta para select de Pólizas
                const { data: contratantesData, error: polizasError } = await supabase
                    .from('cat_aseguradoras')
                    .select('nombre_aseguradora')
                    .order('nombre_aseguradora', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const aseguradorasOptions = contratantesData.map((aseguradora) => ({
                    label: aseguradora.nombre_aseguradora,
                    value: aseguradora.nombre_aseguradora
                }));

                setAseguradora(aseguradorasOptions);
            } catch (error) {
                console.error('Error al obtener las aseguradoras:', error.message);
            }
        };

        const fetchTipoSeguroData = async () => {
            try {
                // Consulta para select
                const { data: tiposeguroData, error: polizasError } = await supabase
                    .from('cat_tipos_seguros')
                    .select('nombre_tipo_seguro')
                    .order('nombre_tipo_seguro', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const tiposeguroOptions = tiposeguroData.map((tiposeguro) => ({
                    label: tiposeguro.nombre_tipo_seguro,
                    value: tiposeguro.nombre_tipo_seguro
                }));

                setTipoSeguro(tiposeguroOptions);
            } catch (error) {
                console.error('Error al obtener los tipos seguro:', error.message);
            }
        };
        const fetchPlanes = async () => {
            try {
                // Consulta para select
                const { data: planData, error: polizasError } = await supabase
                    .from('cat_planes')
                    .select('nombre_plan')
                    .order('nombre_plan', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const planesOptions = planData.map((tipoplan) => ({
                    label: tipoplan.nombre_plan,
                    value: tipoplan.nombre_plan
                }));

                setPlanes(planesOptions);
            } catch (error) {
                console.error('Error al obtener los tipos plan:', error.message);
            }
        };

        const fetchDuracionPagos = async () => {
            try {
                // Consulta para select
                const { data: duracionpagosData, error: polizasError } = await supabase
                    .from('cat_duraciones_pagos')
                    .select('nombre_duracion_pago')
                    .order('nombre_duracion_pago', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const duracionpagosOptions = duracionpagosData.map((tipoduracionpago) => ({
                    label: tipoduracionpago.nombre_duracion_pago,
                    value: tipoduracionpago.nombre_duracion_pago
                }));

                setDuracionpagos(duracionpagosOptions);
            } catch (error) {
                console.error('Error al obtener los tipos duracion pagos:', error.message);
            }
        };

        const fetchFormaPagos = async () => {
            try {
                // Consulta para select
                const { data: formapagosData, error: polizasError } = await supabase
                    .from('cat_formas_pagos')
                    .select('nombre_forma_pago')
                    .order('nombre_forma_pago', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const formapagosOptions = formapagosData.map((tipoformapago) => ({
                    label: tipoformapago.nombre_forma_pago,
                    value: tipoformapago.nombre_forma_pago
                }));

                setFormapagos(formapagosOptions);
            } catch (error) {
                console.error('Error al obtener los tipos forma pagos:', error.message);
            }
        };
        const fetchMonedas = async () => {
            try {
                // Consulta para select
                const { data: monedasData, error: polizasError } = await supabase
                    .from('cat_monedas')
                    .select('nombre_moneda')
                    .order('nombre_moneda', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const monedasOptions = monedasData.map((tipomonedas) => ({
                    label: tipomonedas.nombre_moneda,
                    value: tipomonedas.nombre_moneda
                }));

                setMonedas(monedasOptions);
            } catch (error) {
                console.error('Error al obtener los tipos moneda:', error.message);
            }
        };

        const fetchEstatuspoliza = async () => {
            try {
                // Consulta para select
                const { data: estatuspolizaData, error: polizasError } = await supabase
                    .from('cat_estatus_polizas')
                    .select('nombre_estatus_poliza')
                    .order('nombre_estatus_poliza', { ascending: true });
                if (polizasError) {
                    throw polizasError;
                }

                const estatuspolizaOptions = estatuspolizaData.map((tipoestatuspolizas) => ({
                    label: tipoestatuspolizas.nombre_estatus_poliza,
                    value: tipoestatuspolizas.nombre_estatus_poliza
                }));

                setEstatuspoliza(estatuspolizaOptions);
            } catch (error) {
                console.error('Error al obtener los tipos estatus poliza:', error.message);
            }
        };

        fetchNumeroPoliza();
        fetchContratantesData();
        fetchAseguradorasData();
        fetchTipoSeguroData();
        fetchPlanes();
        fetchDuracionPagos();
        fetchFormaPagos();
        fetchMonedas();
        fetchEstatuspoliza();
    }, []);

    const handleDateChange = (field, date) => {
        if (field === 'fechaInicial') {
            setFilters({ ...filters, fechaInicial: date });
        } if (field === 'fechaInicial2') {
            setFilters({ ...filters, fechaInicial2: date });
        } if (field === 'fechaFinal') {
            setFilters({ ...filters, fechaFinal: date });
        } else if (field === 'fechaFinal2') {
            setFilters({ ...filters, fechaFinal2: date });
        }
    };

    return (
        <div style={{
            //display: 'flex',
            //flexWrap: 'wrap',
            marginBottom: '10px',
            //justifyContent: 'center', // Centra horizontalmente
            //alignItems: 'center', // Centra verticalmente
        }}>
            <Group style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                <Autocomplete
                    label="Número de Póliza"
                    placeholder="Póliza"
                    data={numeroPolizas}
                    value={filters.numero_poliza}
                    onChange={(value) => setFilters({ ...filters, numero_poliza: value })}
                    name="numero_poliza"
                    //style={{ flex: '1 1 auto', marginRight: '0px' }} // Ancho flexible y margen derecho
                    style={{ width: '100%', maxWidth: '200px' }}

                />

                <Autocomplete
                    label="Contratante"
                    placeholder="Contratante"
                    data={contratante}
                    value={filters.nombre_contratante}
                    onChange={(value) => setFilters({ ...filters, nombre_contratante: value })}
                    name="nombre_contratante"
                    style={{ width: '100%', maxWidth: '200px' }}
                />


                <Autocomplete
                    label="Estatus de Póliza"
                    placeholder="Estatus Póliza"
                    data={estatuspoliza}
                    value={filters.nombre_estatus_poliza}
                    onChange={(value) => setFilters({ ...filters, nombre_estatus_poliza: value })}
                    name="nombre_estatus_poliza"
                    style={{ width: '100%', maxWidth: '200px' }}
                />
                <Autocomplete
                    label="Aseguradora"
                    placeholder="Aseguradora"
                    data={aseguradoras}
                    value={filters.nombre_aseguradora}
                    onChange={(value) => setFilters({ ...filters, nombre_aseguradora: value })}
                    name="nombre_aseguradora"
                    style={{ width: '100%', maxWidth: '240px' }}

                />



                <Autocomplete
                    label="Tipo seguro"
                    placeholder="Tipo"
                    data={tiposeguro}
                    value={filters.nombre_tipo_seguro}
                    onChange={(value) => setFilters({ ...filters, nombre_tipo_seguro: value })}
                    name="nombre_tipo_seguro"
                    style={{ width: '100%', maxWidth: '200px' }}

                />


                <Autocomplete
                    label="Plan"
                    placeholder="Plan"
                    data={planes}
                    value={filters.nombre_plan}
                    onChange={(value) => setFilters({ ...filters, nombre_plan: value })}
                    name="nombre_plan"
                    style={{ width: '100%', maxWidth: '200px' }}

                />



                <Autocomplete
                    label="Duración pagos"
                    placeholder="Duración pagos"
                    data={duracionpagos}
                    value={filters.nombre_duracion_pago}
                    onChange={(value) => setFilters({ ...filters, nombre_duracion_pago: value })}
                    name="nombre_duracion_pago"
                    style={{ width: '100%', maxWidth: '200px' }}

                />
                <Autocomplete
                    label="Forma de pagos"
                    placeholder="F. de pagos"
                    data={formapagos}
                    value={filters.nombre_forma_pago}
                    onChange={(value) => setFilters({ ...filters, nombre_forma_pago: value })}
                    name="nombre_forma_pago"
                    style={{ width: '100%', maxWidth: '110px' }}

                />

                <Autocomplete
                    label="Moneda"
                    placeholder="Moneda"
                    data={monedas}
                    value={filters.nombre_moneda}
                    onChange={(value) => setFilters({ ...filters, nombre_moneda: value })}
                    name="nombre_moneda"
                    style={{ width: '100%', maxWidth: '110px' }}

                />


                <DatePickerInput
                    label="Inicio F. Emisión"
                    placeholder="Fecha Inicial"
                    leftSection={icon}

                    value={filters.fechaInicial}
                    onChange={(date) => handleDateChange('fechaInicial', date)}
                    clearable
                    style={{ width: '100%', maxWidth: '200px' }}

                />
                <DatePickerInput
                    label="Fin F. Emisión"
                    placeholder="Fecha Inicial"
                    leftSection={icon}

                    value={filters.fechaInicial2}
                    onChange={(date) => handleDateChange('fechaInicial2', date)}
                    clearable
                    style={{ width: '100%', maxWidth: '200px' }}

                />
                <DatePickerInput
                    label="Inicio F. Vigencia"
                    placeholder="Fecha Final"
                    leftSection={icon}

                    value={filters.fechaFinal}
                    onChange={(date) => handleDateChange('fechaFinal', date)}
                    clearable
                    style={{ width: '100%', maxWidth: '200px' }}

                />
                <DatePickerInput
                    label="Fin F. Vigencia"
                    placeholder="Fecha Final"
                    leftSection={icon}

                    value={filters.fechaFinal2}
                    onChange={(date) => handleDateChange('fechaFinal2', date)}
                    clearable
                    style={{ width: '100%', maxWidth: '200px' }}

                />
            </Group>

        </div>
    );
}

export default FiltrosPolizas;
