import React, { useState } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { useDispatch } from 'react-redux';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";



function ConductosCobrosTable({ conductoscobros, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombreConductoCobro, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    //const truncateText = (text, maxLength) => {
    //    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    //};
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const tipoconductoscobrosFiltrados = filtroNombreConductoCobro
        ? conductoscobros.filter(conductocobro => conductocobro.nombre_conducto_cobro.toLowerCase().includes(filtroNombreConductoCobro.toLowerCase()))
        : conductoscobros;

    const paginatedTransactions = tipoconductoscobrosFiltrados.slice(startIndex, endIndex);

    /*
    const handleSelectedTransaction = (conductocobro) => {
        console.log("Tipo conductocobro seleccionado:", conductocobro); // Agregar este console.log para verificar el contratante seleccionado
        setSelectedTransaction(conductocobro);
        setFormMode("edit");
        setShowForm(true);
    };
    */

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_conductos_cobros')
                .delete()
                .eq('id_conducto_cobro', id);

            if (error) {
                throw new Error('No se pudo borrar el Conducto cobro');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Conducto cobro borrado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Conducto cobro',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((conductocobro) => (
        <tr key={conductocobro.id_conducto_cobro}>
            <td>{conductocobro.id_conducto_cobro}</td>
            <td>{conductocobro.nombre_conducto_cobro}</td>
            <td>
                <Group justify="center">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_conductosdecobros &&
                        permisos.catalogos.modulos.cat_conductosdecobros.acciones &&
                        permisos.catalogos.modulos.cat_conductosdecobros.acciones.modificar_conductosdecobro && (
                            <i
                                key={`edit-${conductocobro.id_conducto_cobro}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(conductocobro);
                                    setFormMode("edit");
                                    setShowForm(true);
                                    //console.log("Tipo conductocobro seleccionado:", conductocobro);
                                }}
                            ></i>
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_conductosdecobros &&
                        permisos.catalogos.modulos.cat_conductosdecobros.acciones &&
                        permisos.catalogos.modulos.cat_conductosdecobros.acciones.eliminar_conductosdecobro && (
                            <i
                                key={`delete-${conductocobro.id_conducto_cobro}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar este Conducto cobro?')) {
                                        deleteTransaction(conductocobro.id_conducto_cobro);
                                    }
                                }}
                            ></i>
                        )}

                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre tipo conductocobro',
    ];

    const csvData = conductoscobros.map((conductocobro) => [
        conductocobro.id_conducto_cobro,
        conductocobro.nombre_conducto_cobro,
    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Nombre tipo conducto cobro</th>

                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(conductoscobros.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'conductoscobros.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default ConductosCobrosTable;