import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import Header from '../components/Header';
import PolizasForm from '../components/PolizasForm';
import { Group, Button, Title, Modal, Card, Accordion, Text } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import PolizasTable from '../components/PolizasTable';
import { IconInfoCircle } from '@tabler/icons-react';
import FiltrosPolizas from '../components/FiltrosPolizas';
import moment from "moment";
import { IconSearch } from '@tabler/icons-react';
import TarjetaPolizasCanceladas from '../components/TarjetaPolizasCanceladas';
import PolizasTable2 from '../components/PolizasTable2';




function CatalogoPolizas({ user, permisos }) {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [polizas, setPolizas] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroPoliza, setFiltroPoliza] = useState('');
    const icon = <IconInfoCircle />;
    const [view, setView] = React.useState("table");


    //para Filtros Pagos
    const [filters, setFilters] = React.useState({
        numero_poliza: "",
        nombre_contratante: "",
        nombre_aseguradora: "",
        nombre_tipo_seguro: "",
        nombre_plan: "",
        nombre_duracion_pagos: "",
        nombre_forma_pago: "",
        fechaInicial: null,
        fechaFinal: null,
        fechaInicial2: null,
        fechaFinal2: null,
        nombre_moneda: "",
        nombre_estatus_poliza: "",

    });


    const fetchPolizas = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('polizas').select(`
                id_poliza,
                numero_poliza,
                prima,
                suma_asegurada,
                fecha_emision,
                nombre_asegurado,
                fecha_nacimiento_asegurado,
                fecha_vigencia,
                notas_poliza,
                cat_contratantes!inner(id_contratante, nombre_contratante),
                cat_aseguradoras!inner(id_aseguradora, nombre_aseguradora),
                cat_tipos_seguros!inner(id_tipo_seguro, nombre_tipo_seguro),
                cat_planes!inner(id_plan, nombre_plan),
                cat_duraciones_pagos!inner(id_duracion_pago, nombre_duracion_pago),
                cat_formas_pagos!inner(id_forma_pago, nombre_forma_pago),
                cat_estatus_polizas!inner(id_estatus_poliza, nombre_estatus_poliza),
                cat_monedas!inner(id_moneda, nombre_moneda)
                `);

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.numero_poliza) {
                supabaseQuery = supabaseQuery.eq('numero_poliza', filters.numero_poliza);
            }

            if (filters.nombre_contratante) {
                supabaseQuery = supabaseQuery.eq('cat_contratantes.nombre_contratante', filters.nombre_contratante);
            }

            if (filters.nombre_aseguradora) {
                supabaseQuery = supabaseQuery.eq('cat_aseguradoras.nombre_aseguradora', filters.nombre_aseguradora);
            }

            if (filters.nombre_tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('cat_tipos_seguros.nombre_tipo_seguro', filters.nombre_tipo_seguro);
            }

            if (filters.nombre_plan) {
                supabaseQuery = supabaseQuery.eq('cat_planes.nombre_plan', filters.nombre_plan);
            }

            if (filters.nombre_duracion_pagos) {
                supabaseQuery = supabaseQuery.eq('cat_duraciones_pagos.nombre_duracion_pagos', filters.nombre_duracion_pagos);
            }

            if (filters.nombre_forma_pago) {
                supabaseQuery = supabaseQuery.eq('cat_formas_pagos.nombre_forma_pago', filters.nombre_forma_pago);
            }

            if (filters.nombre_moneda) {
                supabaseQuery = supabaseQuery.eq('cat_monedas.nombre_moneda', filters.nombre_moneda);
            }
            if (filters.nombre_estatus_poliza) {
                supabaseQuery = supabaseQuery.eq('cat_estatus_polizas.nombre_estatus_poliza', filters.nombre_estatus_poliza);
            }
            if (filters.fechaInicial) {
                const fechaInicialFormatted = moment(filters.fechaInicial).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.gte('fecha_emision', fechaInicialFormatted);
            }
            if (filters.fechaInicial2) {
                const fechaInicialFormatted2 = moment(filters.fechaInicial2).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.lte('fecha_emision', fechaInicialFormatted2);
            }


            if (filters.fechaFinal) {
                const fechaFinalFormatted = moment(filters.fechaFinal).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.gte('fecha_vigencia', fechaFinalFormatted);
            }

            if (filters.fechaFinal2) {
                const fechaFinalFormatted2 = moment(filters.fechaFinal2).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.lte('fecha_vigencia', fechaFinalFormatted2);
            }

            let { data: polizas, error } = await supabaseQuery
                .order('id_poliza', { ascending: false });

            if (error) {
                throw error;
            }


            //console.log("Listado de polizas obtenidas:", polizas); // Agregar este console.log para verificar los contratantes obtenidos
            setPolizas(polizas);
            dispatch(HideLoading());

        } catch (error) {
            //console.error('Error al obtener polizas:', error.message);
            dispatch(HideLoading());

        }
    };





    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            await fetchPolizas();
        } catch (error) {
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddPoliza = permisos &&
        permisos.polizas &&
        permisos.polizas.acciones &&
        permisos.polizas.acciones.registrar_poliza === true;

    return (
        <div>
            <Card className='CardTituloReportes'
                mb={'15px'}
            >
                <Text
                    size="xl"
                    fw={900}

                >Pólizas
                </Text>
            </Card>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <FiltrosPolizas
                            filters={filters}
                            setFilters={setFilters}
                        />
                        <Group mt={'20px'}>
                            <Button leftSection={<IconSearch size={20} />} color="#005187" onClick={getData}>
                                Buscar Pólizas
                            </Button>
                            <Button.Group>
                                <Button
                                    color='#005187'
                                    variant={view === 'cards' ? "filled" : "outline"}
                                    onClick={() => setView("cards")}
                                >
                                    Tarjetas
                                </Button>
                                <Button
                                    color='#005187'
                                    variant={view === 'table' ? "filled" : "outline"}
                                    onClick={() => setView("table")}
                                >
                                    Tabla
                                </Button>
                            </Button.Group>
                            {canAddPoliza && (

                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Póliza
                                </Button>
                            )}


                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Accordion defaultValue="catalogo" mb={'20px'}>
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Contadores
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <TarjetaPolizasCanceladas
                            polizas={polizas}
                        />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Póliza" : "Editar Póliza"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <PolizasForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar el contratante seleccionado como prop
                    />

                </Modal>



                <div style={{ overflowX: "auto" }}>
            

                    {view === 'cards' && <PolizasTable
                        polizas={polizas}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}
                    {view === 'table' && <PolizasTable2
                        polizas={polizas}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        user={user}
                        permisos={permisos}
                    />}

                </div>
            </Card>
        </div>

    );

}


export default CatalogoPolizas;