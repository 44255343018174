import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import CatalogoContratantes from '../pages/CatalogoContratantes';
import CatalogoTiposContratantes from '../pages/CatalogosTiposContratantes';
import CatalogoAseguradoras from '../pages/CatalogoAseguradoras';
import CatalogoDuracionesPagos from '../pages/CatalogoDuracionesPagos';
import Login from "../pages/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from './PublicRoute';
import CatalogoTiposSeguros from '../pages/CatalogoTiposSeguros';
import CatalogoPlanes from '../pages/CatalogoPlanes'
import CatalogoFormasPagos from '../pages/CatalogoFormasPagos';
import CatalogoEstatusPagos from '../pages/CatalogoEstatusPagos';
import CatalogoEstatusPolizas from '../pages/CatalogoEstatusPolizas';
import CatalogoMonedas from '../pages/CatalogoMonedas';
import CatalogoConductosCobros from '../pages/CatalogoConductosCobros';
import CatalogoPolizas from '../pages/CatalogoPolizas';
import CatalogoPagos from '../pages/CatalogoPagos';
import CatalogoCumpleaneros from '../pages/CatalogoCumpleaneros';
import CatalogoUsuarios from '../pages/CatalogoUsuarios';
import CatalogoReporteListaRegalos from '../pages/CatalogoReporteListaRegalos';
import CatalogoPolizasNuevas from '../pages/CatalogoPolizasNuevas';
import CatalogoNivelCobranza from '../pages/CatalogoNivelCobranza';
import { useAuth } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { supabase } from '../supabaseClient';

const MainRouter = () => {

    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);

                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        } else {
            //console.log("no hay usuario")
            dispatch(HideLoading());

        }
    }, [user, dispatch]);


    return (
        <div>
            <main>
                <Routes>
                    <Route path='/home' element={<ProtectedRoute><HomePage /></ProtectedRoute>} />

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_contratantes &&
                        permisos.catalogos.modulos.cat_contratantes.activo === true && (
                            <Route path='/cat_contratantes' element={<ProtectedRoute><CatalogoContratantes /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tipos_contratantes &&
                        permisos.catalogos.modulos.cat_tipos_contratantes.activo === true && (
                            <Route path='/cat_tipos_contratantes' element={<ProtectedRoute><CatalogoTiposContratantes /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_aseguradoras &&
                        permisos.catalogos.modulos.cat_aseguradoras.activo === true && (
                            <Route path='/cat_aseguradoras' element={<ProtectedRoute><CatalogoAseguradoras user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tiposseguros &&
                        permisos.catalogos.modulos.cat_tiposseguros.activo === true && (
                            <Route path='/cat_tiposseguros' element={<ProtectedRoute><CatalogoTiposSeguros /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_duracionespagos &&
                        permisos.catalogos.modulos.cat_duracionespagos.activo === true && (
                            <Route path='/cat_duracionespagos' element={<ProtectedRoute><CatalogoDuracionesPagos /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_planes &&
                        permisos.catalogos.modulos.cat_planes.activo === true && (
                            <Route path='/cat_planes' element={<ProtectedRoute><CatalogoPlanes /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_formaspagos &&
                        permisos.catalogos.modulos.cat_formaspagos.activo === true && (
                            <Route path='/cat_formaspagos' element={<ProtectedRoute>< CatalogoFormasPagos /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_estatuspagos &&
                        permisos.catalogos.modulos.cat_estatuspagos.activo === true && (
                            <Route path='/cat_estatuspagos' element={<ProtectedRoute>< CatalogoEstatusPagos /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_estatuspolizas &&
                        permisos.catalogos.modulos.cat_estatuspolizas.activo === true && (
                            <Route path='/cat_estatuspolizas' element={<ProtectedRoute>< CatalogoEstatusPolizas /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_monedas &&
                        permisos.catalogos.modulos.cat_monedas.activo === true && (
                            <Route path='/cat_monedas' element={<ProtectedRoute>< CatalogoMonedas /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_conductosdecobros &&
                        permisos.catalogos.modulos.cat_conductosdecobros.activo === true && (
                            <Route path='/cat_conductosdecobros' element={<ProtectedRoute>< CatalogoConductosCobros user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_usuarios &&
                        permisos.catalogos.modulos.cat_usuarios.activo === true && (
                            <Route path='/cat_usuarios' element={<ProtectedRoute>< CatalogoUsuarios user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}



                    {permisos &&
                        permisos.polizas &&
                        permisos.polizas.activo === true && (
                            <Route path='/polizas' element={<ProtectedRoute><CatalogoPolizas user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.pagos &&
                        permisos.pagos.activo === true && (
                            <Route path='/pagos' element={<ProtectedRoute><CatalogoPagos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.modulos &&
                        permisos.reportes.modulos.cumpleaneros &&
                        permisos.reportes.modulos.cumpleaneros.activo === true && (
                            <Route path='/cumpleaneros' element={<ProtectedRoute>< CatalogoCumpleaneros /></ProtectedRoute>} />
                        )}
                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.modulos &&
                        permisos.reportes.modulos.lista_regalos &&
                        permisos.reportes.modulos.lista_regalos.activo === true && (
                            <Route path='/lista_regalos' element={<ProtectedRoute>< CatalogoReporteListaRegalos /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.modulos &&
                        permisos.reportes.modulos.polizas_nuevas &&
                        permisos.reportes.modulos.polizas_nuevas.activo === true && (
                            <Route path='/polizas_nuevas' element={<ProtectedRoute>< CatalogoPolizasNuevas /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.modulos &&
                        permisos.reportes.modulos.nivel_cobranza &&
                        permisos.reportes.modulos.nivel_cobranza.activo === true && (
                            <Route path='/nivel_cobranza' element={<ProtectedRoute>< CatalogoNivelCobranza /></ProtectedRoute>} />
                        )}


                    {/* Ruta /login, solo mostrar si el usuario no está autenticado */}
                    <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />

                </Routes>
            </main>
        </div>
    );
};

export default MainRouter;

/* antes las rutas eran:
<Route path='/cattiposcontratantes' element={<ProtectedRoute>{user ? <CatalogoTiposContratantes /> : <Navigate to="/login" />}</ProtectedRoute>} />
Pero las cambiamos a: 
<Route path='/cattiposcontratantes' element={<ProtectedRoute><CatalogoTiposContratantes /></ProtectedRoute>} />


y antes estaba:
<Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />


Codigo 01 de marzo 2024
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import HomePage from '../pages/HomePage';
import CatalogoContratantes from '../pages/CatalogoContratantes';
import CatalogoTiposContratantes from '../pages/CatalogosTiposContratantes';
import CatalogoAseguradoras from '../pages/CatalogoAseguradoras';
import CatalogoDuracionesPagos from '../pages/CatalogoDuracionesPagos';
import Login from "../pages/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import CatalogoTiposSeguros from '../pages/CatalogoTiposSeguros';
import CatalogoPlanes from '../pages/CatalogoPlanes'

const MainRouter = () => {
    const { user } = useAuth();

    return (
        <div>
            <main>
                <Routes>
                    <Route path='/' element={<ProtectedRoute>{user ? <HomePage /> : <Navigate to="/login" />}</ProtectedRoute>} />

                    <Route path='/catcontratantes' element={<ProtectedRoute><CatalogoContratantes /></ProtectedRoute>} />
                    <Route path='/cattiposcontratantes' element={<ProtectedRoute><CatalogoTiposContratantes /></ProtectedRoute>} />
                    <Route path='/cataseguradoras' element={<ProtectedRoute><CatalogoAseguradoras /></ProtectedRoute>} />
                    <Route path='/catduracionpagos' element={<ProtectedRoute><CatalogoDuracionesPagos /></ProtectedRoute>} />
                    <Route path='/cattiposseguros' element={<ProtectedRoute><CatalogoTiposSeguros /></ProtectedRoute>} />
                    <Route path='/catplanes' element={<ProtectedRoute><CatalogoPlanes /></ProtectedRoute>} />

                    Ruta /login, solo mostrar si el usuario no está autenticado
                    <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
                </Routes>
            </main>
        </div>
    );
};

export default MainRouter;

*/

