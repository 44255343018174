import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import Header from '../components/Header';

import { Group, Button, Divider, Text, Card, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import { IconSearch } from '@tabler/icons-react';
import FiltrosReporteCumpleaneros from '../components/FiltrosReporteCumpleaneros';
import ReporteCumpleanerosTable from '../components/ReporteCumpleanerosTable';




function CatalogoCumpleaneros() {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [polizas, setPolizas] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroPoliza, setFiltroPoliza] = useState('');
    const [selectedMes, setSelectedMes] = useState(null)

    const handleSelectMes = (selectedOption) => {
        setSelectedMes(selectedOption);
    };

    //para Filtros Cumpleaneros

    //ME QUEDE AQUI, EMPEZAR A FORMATEAR
    // USAR FILTROS REPORTE CUMPLEAÑEROS
    // NOMAS DEJAR EL FILTRO DE NOMBRE POR MES Y HACER CONSULTA PARA CREAR UN COMPONENTE CUMPEANEROSTABLE
    // Nombre, fecha_nacimiento, edad_a_cumplir, _poliza, estatus_poliza, aseguradora, tipo_seguro, 


    const [filters, setFilters] = React.useState({
        numero_poliza: "",
        nombre_contratante: "",
        nombre_aseguradora: "",
        nombre_estatus_poliza: "",
        nombre_mes: "ENERO",

    });


    const fetchPolizas = async () => {
        try {
            dispatch(ShowLoading());

            let supabaseQuery = supabase.from('polizas').select(`
                id_poliza,
                numero_poliza,
                nombre_asegurado,
                fecha_nacimiento_asegurado,
                cat_contratantes!inner(id_contratante, nombre_contratante),
                cat_aseguradoras!inner(id_aseguradora, nombre_aseguradora),
                cat_tipos_seguros!inner(id_tipo_seguro, nombre_tipo_seguro),
                cat_estatus_polizas!inner(id_estatus_poliza, nombre_estatus_poliza)
                `);

            // Verificar si filters.estatus_pago está vacío, si es así, omitir la condición
            if (filters.numero_poliza) {
                supabaseQuery = supabaseQuery.eq('numero_poliza', filters.numero_poliza);
            }

            if (filters.nombre_contratante) {
                supabaseQuery = supabaseQuery.eq('cat_contratantes.nombre_contratante', filters.nombre_contratante);
            }

            if (filters.nombre_aseguradora) {
                supabaseQuery = supabaseQuery.eq('cat_aseguradoras.nombre_aseguradora', filters.nombre_aseguradora);
            }

            if (filters.nombre_tipo_seguro) {
                supabaseQuery = supabaseQuery.eq('cat_tipos_seguros.nombre_tipo_seguro', filters.nombre_tipo_seguro);
            }

            if (filters.nombre_estatus_poliza) {
                supabaseQuery = supabaseQuery.eq('cat_estatus_polizas.nombre_estatus_poliza', filters.nombre_estatus_poliza);
            }


            let { data: polizas, error } = await supabaseQuery
                .order('id_poliza', { ascending: false });

            if (error) {
                throw error;
            }

            const obtenerNombreMes = (numeroMes) => {
                const meses = {
                    1: "ENERO",
                    2: "FEBRERO",
                    3: "MARZO",
                    4: "ABRIL",
                    5: "MAYO",
                    6: "JUNIO",
                    7: "JULIO",
                    8: "AGOSTO",
                    9: "SEPTIEMBRE",
                    10: "OCTUBRE",
                    11: "NOVIEMBRE",
                    12: "DICIEMBRE"
                };

                return meses[numeroMes] || "";
            };

            // Ahora, manipular los datos para extraer el mes de fecha_nacimiento_asegurado
            polizas = polizas.map((poliza) => {
                // Obtener el mes de la fecha de nacimiento asegurado
                const fechaNacimiento = new Date(poliza.fecha_nacimiento_asegurado);
                const numeroMes = fechaNacimiento.getUTCMonth() + 1; // Sumar 1 porque los meses en JavaScript van de 0 a 11

                // Obtener el nombre del mes en español
                const nombreMes = obtenerNombreMes(numeroMes);

                // Actualizar el objeto poliza con el mes
                return {
                    ...poliza,
                    mes: nombreMes,
                };
            });

            // Filtrar por el nombre del mes si está presente en los filtros
            if (filters.nombre_mes) {
                polizas = polizas.filter((poliza) => poliza.mes === filters.nombre_mes);
            }


            //console.log("Listado de polizas obtenidas:", polizas); // Agregar este console.log para verificar los contratantes obtenidos
            setPolizas(polizas);
            dispatch(HideLoading());

        } catch (error) {
            //console.error('Error al obtener polizas:', error.message);
            dispatch(HideLoading());

        }
    };


    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            await fetchPolizas();
        } catch (error) {
            //console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };


    return (
        <div>
            <Card className='CardTituloReportes'>
                <Text
                    size="xl"
                    fw={900}
                >Reporte Cumpleañeros
                </Text>
            </Card>
            <Accordion defaultValue="pago">
                <Accordion.Item value="pago">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <FiltrosReporteCumpleaneros
                                filters={filters}
                                setFilters={setFilters}
                            />
                        </Group>
                        <Button leftSection={<IconSearch size={20} />} mt={'10px'} color="#005187" onClick={getData}>
                            Buscar Cumpleañeros
                        </Button>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            <Card className='CardTablas'>
                <div style={{ overflowX: "auto" }}>
                    <ReporteCumpleanerosTable
                        polizas={polizas}
                        getData={getData}
                    />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoCumpleaneros;