import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import moment from "moment";
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import { useAuth } from "../context/AuthContext";




function ContratantesTable({ contratantes, setSelectedTransaction, setFormMode, setShowForm, getData, filtroNombreContratante }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const dispatch = useDispatch();

  const { user } = useAuth();
  const [permisos, setPermisos] = useState([]);


  // Este useEffect sirve para revisar qué permisos tiene este usuario
  useEffect(() => {
    if (user) {
      const fetchUserPermissions = async () => {
        try {
          dispatch(ShowLoading());
          const { data: profile, error } = await supabase
            .from('profiles')
            .select('acciones')
            .eq('id', user.id)
            .single();

          if (error) {
            console.error("Error fetching user profile:", error.message);
            throw error;
          }

          if (profile) {
            const userPermissions = profile.acciones || {};
            setPermisos(userPermissions);
            //console.log("Permisos:", userPermissions);
          }

        } catch (error) {
          console.error("Error fetching user permissions:", error.message);
        } finally {
          dispatch(HideLoading());
        }
      };

      fetchUserPermissions();
    }
  }, [user, dispatch]);


  // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
  const contratantesFiltrados = filtroNombreContratante
    ? contratantes.filter(contratante => contratante.nombre_contratante.toLowerCase().includes(filtroNombreContratante.toLowerCase()))
    : contratantes;

  const paginatedTransactions = contratantesFiltrados.slice(startIndex, endIndex);

  // para Eliminar
  const deleteTransaction = async (id) => {
    try {
      dispatch(ShowLoading());

      // Aquí realizamos la eliminación usando Supabase
      const { error } = await supabase
        .from('cat_contratantes')
        .delete()
        .eq('id_contratante', id);

      if (error) {
        throw new Error('No se pudo borrar el Contratante');
      }

      dispatch(HideLoading());
      showNotification({
        title: 'Contratante borrado',
        color: 'green'
      });

      getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
    } catch (error) {
      dispatch(HideLoading());
      showNotification({
        title: 'Error, no se pudo borrar el Contratante',
        color: 'red'
      });
    }
  };


  const handleSelectedTransaction = (contratante) => {
    //console.log("Contratante seleccionado:", contratante); // Agregar este console.log para verificar el contratante seleccionado
    setSelectedTransaction(contratante);
    setFormMode("edit");
    setShowForm(true);
  };

  const getRows = paginatedTransactions.map((contratante) => (
    <tr key={contratante.id_contratante}>
      <td>{contratante.id_contratante}</td>
      <td>{contratante.clave_contratante}</td>
      <td>{contratante.nombre_contratante}</td>
      <td>{contratante.cat_tipos_contratantes ? contratante.cat_tipos_contratantes.nombre_tipo_contratante : ''}</td>
      <td>{contratante.telefono_celular}</td>
      <td>{contratante.correo}</td>
      <td>
        <Group justify="center">
          {permisos &&
            permisos.catalogos &&
            permisos.catalogos.modulos &&
            permisos.catalogos.modulos.cat_contratantes &&
            permisos.catalogos.modulos.cat_contratantes.acciones &&
            permisos.catalogos.modulos.cat_contratantes.acciones.modificar_contratante && (
              <i
                key={`edit-${contratante.id_contratante}`}
                className='ri-pencil-line'
                onClick={() => {
                  setSelectedTransaction(contratante);
                  setFormMode("edit");
                  setShowForm(true);
                  //console.log("Contratante seleccionado:", contratante);
                }}
              ></i>
            )}
          {permisos &&
            permisos.catalogos &&
            permisos.catalogos.modulos &&
            permisos.catalogos.modulos.cat_contratantes &&
            permisos.catalogos.modulos.cat_contratantes.acciones &&
            permisos.catalogos.modulos.cat_contratantes.acciones.eliminar_contratante && (
              <i
                key={`delete-${contratante.id_contratante}`}
                className='ri-delete-bin-line'
                onClick={() => {
                  // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                  if (window.confirm('¿Estás seguro de eliminar este elemento?')) {
                    deleteTransaction(contratante.id_contratante);
                  }
                }}
              ></i>
            )}

        </Group>
      </td>
    </tr>
  ));

  const csvHeaders = [
    'ID',
    'Clave contratante',
    'Nombre contratante',
    'Tipo contratante',
    'Direccion',
    'Tel. oficina',
    'Tel. celular',
    'Correo',
    'Nombre asistente',
    'Notas',
  ];

  const csvData = contratantes.map((contratante) => [
    contratante.id_contratante,
    // nuevo campo
    contratante.clave_contratante,
    contratante.nombre_contratante,
    contratante.cat_tipos_contratantes ? contratante.cat_tipos_contratantes.nombre_tipo_contratante : '',
    contratante.direccion,
    contratante.telefono_oficina,
    contratante.telefono_celular,
    contratante.correo,
    //contratante.fecha_nacimiento,
    contratante.nombre_asistente,
    contratante.notas_contratante
  ]);

  return (
    <div >

      <Table
        style={{ textAlign: 'left', borderCollapse: 'collapse' }}
        verticalSpacing="xl"
        fontSize="sm"
        mb={10}
        striped
        withRowBorders={true}
        className="my-custom-table"
      >
        <thead
          style={{ textAlign: 'left' }}
        >
          <tr>
            <th>ID</th>
            <th>Clave</th>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Teléfono Cel</th>
            <th>Correo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{getRows}</tbody>
      </Table>
      <Group justify="flex-end" mb={10}>
        <Pagination
          total={Math.ceil(contratantes.length / itemsPerPage)}
          siblings={1}
          withEdges
          value={currentPage}
          onChange={(newPage) => setCurrentPage(newPage)}
          color='#00497A'
        />

        {/* Agregar enlace para descargar CSV con react-csv-downloader */}

        <CsvDownloader
          filename={'contratantes.csv'}
          separator=","
          wrapColumnChar=""
          datas={csvData}
          columns={csvHeaders}
        >
          <Button variant="light" color='#00497A' >Descargar Tabla</Button>
        </CsvDownloader>
      </Group>

    </div>
  );
}

export default ContratantesTable;