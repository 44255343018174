// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserButton_user__63gQs {
    display: block;
    width: 100%;
    padding: var(--mantine-spacing-xs);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-8));
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/UserButton.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,kCAAkC;IAClC,0EAA0E;;IAE1E;MACE,sFAAsF;IACxF;EACF","sourcesContent":[".user {\n    display: block;\n    width: 100%;\n    padding: var(--mantine-spacing-xs);\n    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));\n  \n    @mixin hover {\n      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-8));\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": `UserButton_user__63gQs`
};
export default ___CSS_LOADER_EXPORT___;
