import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Input, Group, Select, Button, NativeSelect, Collapse } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDispatch } from 'react-redux';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import {
    IconNotes,
    IconUser,
    IconDotsCircleHorizontal,
    IconBuildingBank,
} from '@tabler/icons-react';



function ContratanteForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        clave_contratante: "",
        nombre_contratante: "",
        id_contratante: "", // inicializar para que no marque error
        telefono_oficina: "",
        telefono_celular: "",
        correo: "",
        nombre_tipo_contratante: "",
        direccion: "",
        nombre_asistente: "",
        id_tipo_contratante: "",
        notas_contratante: "",
    })

    // Este useEffect es porque en CatalogoContratante ya tomo el valor de id_tipo_contratante, entonces
    // este codigo evita que lo ponga en "" y me marque un warning
    useEffect(() => {
        if (!user.id_contratante) {
            setUser({
                clave_contratante: "",
                nombre_contratante: "",
                id_contratante: "",
                telefono_oficina: "",
                telefono_celular: "",
                correo: "",
                nombre_tipo_contratante: "",
                direccion: "",
                nombre_asistente: "",
                id_tipo_contratante: "",
                notas_contratante: "",

            });
        }
    }, [user.id_contratante]);

    // Codigo para agregar al select las opciones de tipo de contratante, segun cat_tipo_contratante
    const [selectedTipoContratante, setSelectedTipoContratante] = useState(""); //se pone persona fisica por default al agregar contratante
    const [tiposContratante, setTiposContratante] = useState([]);


    useEffect(() => {
        async function fetchTiposContratante() {
            try {
                const { data: tipos } = await supabase
                    .from('cat_tipos_contratantes')
                    .select('id_tipo_contratante, nombre_tipo_contratante');

                setTiposContratante(tipos);
            } catch (error) {
            }
        }
        fetchTiposContratante();
    }, []);

    //para capturar lo que escriben
    // esta linea la quito chatgpt
    //function handleChange(event) {
    //    setUser(prevFormData => {
    //        return {
    //            ...prevFormData,
    //            [event.target.name]: event.target.value
    //        }
    //    })
    //};

    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {

            setUser({
                clave_contratante: transactionData.clave_contratante || "",
                nombre_contratante: transactionData.nombre_contratante || "",
                direccion: transactionData.direccion || "",
                nombre_asistente: transactionData.nombre_asistente || "",
                notas_contratante: transactionData.notas_contratante || "",
                id_contratante: transactionData.id_contratante || "",
                telefono_oficina: transactionData.telefono_oficina || "",
                telefono_celular: transactionData.telefono_celular || "",
                correo: transactionData.correo || "",

            });
            setSelectedTipoContratante(transactionData.id_tipo_contratante || "");

            //console.log("Transaction data2:", transactionData);
        }

    }, [formMode, transactionData]);




    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleChangeSelect = (event) => {
        setSelectedTipoContratante(event.target.value);
        //console.log(event.target.value);
    };

    const handleUpdateContratante = async () => {
        try {
            // Primero, obtenemos la clave_contratante actual del contratante que se está editando
            const { data: contratanteActual, error: contratanteError } = await supabase
                .from('cat_contratantes')
                .select('nombre_contratante')
                .eq('id_contratante', user.id_contratante)
                .single();
    
            if (contratanteError) {
                throw contratanteError;
            }
    
            // Verificamos si la clave_contratante se ha modificado
            if (user.nombre_contratante  !== contratanteActual.nombre_contratante ) {
                // Si la clave_contratante ha cambiado, verificamos si ya existe en otro registro
                const { data, error } = await supabase
                    .from('cat_contratantes')
                    .select('nombre_contratante')
                    .eq('nombre_contratante', user.nombre_contratante)
                    .limit(1);
    
                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre de contratante ya existe.',
                        color: 'red',
                    });
                    dispatch(HideLoading()); // Ocultamos el indicador de carga
                    return; // Salimos de la función sin realizar la actualización
                }
            }
    
            // Si la clave_contratante no ha cambiado o no existe en otro registro, procedemos con la actualización
            await supabase
                .from('cat_contratantes')
                .update({
                    correo: user.correo,
                    direccion: user.direccion,
                    id_tipo_contratante: selectedTipoContratante,
                    nombre_contratante: user.nombre_contratante,
                    nombre_asistente: user.nombre_asistente,
                    notas_contratante: user.notas_contratante,
                    telefono_oficina: user.telefono_oficina,
                    telefono_celular: user.telefono_celular,
                    clave_contratante: user.clave_contratante
                })
                .eq('id_contratante', user.id_contratante);
    
            showNotification({
                title: 'Contratante actualizado',
                message: 'El contratante se ha actualizado correctamente.',
                color: 'green',
            });
    
            setShowForm(false);
            getData();
        } catch (error) {
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el contratante.',
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }
    };
    



    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {
                const { data, error } = await supabase
                    .from('cat_contratantes')
                    .select('nombre_contratante')
                    .eq('nombre_contratante', user.nombre_contratante)
                    .limit(1); // Limitamos a un solo resultado

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre de contratante ya existe. Intente otro nombre',
                        color: 'red',
                    });
                    return dispatch(HideLoading());

                } else {
                    await supabase
                        .from('cat_contratantes')
                        .insert({
                            correo: user.correo,
                            direccion: user.direccion,
                            id_tipo_contratante: selectedTipoContratante,
                            nombre_contratante: user.nombre_contratante,
                            nombre_asistente: user.nombre_asistente,
                            notas_contratante: user.notas_contratante,
                            telefono_oficina: user.telefono_oficina,
                            telefono_celular: user.telefono_celular,
                            clave_contratante: user.clave_contratante
                        });

                    showNotification({
                        title: 'Contratante agregado',
                        message: 'El contratante ha sido agregado con éxito',
                        color: 'green',
                    });
                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();
                }
            } else if (formMode === 'edit') {
                await handleUpdateContratante();
            }
            dispatch(HideLoading());
            //setShowForm(false);
            //getData();
        } catch (error) {
            //console.error('Error al agregar/actualizar contratante:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el contratante.',
                color: 'red',
            });
        }
    };







    //console.log("SelectedTipoContratante antes del return:", selectedTipoContratante);



    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group>
                    <Stack>
                        <Group >
                            <TextInput
                                disabled
                                name="id_contratante"
                                label="ID"
                                onChange={handleChange}
                                style={{ width: '300px' }}
                                value={user.id_contratante}

                            />

                            <TextInput
                                name="clave_contratante"
                                label="Clave"
                                style={{ width: '300px' }}
                                required
                                onChange={handleChange}
                                value={user.clave_contratante} // Asignar el valor del estado al campo
                                placeholder='Clave contratante'

                            />

                        </Group>
                        <Group >


                            <TextInput
                                name="nombre_contratante"
                                label="Nombre contratante"
                                style={{ width: '300px' }}
                                required
                                onChange={handleChange}
                                value={user.nombre_contratante} // Asignar el valor del estado al campo
                                placeholder='¿Quién es el contratante?'
                                icon={<IconUser />}
                            />

                            <NativeSelect
                                name="id_tipo_contratante"
                                label="Tipo"
                                style={{ width: '300px' }}
                                value={selectedTipoContratante}
                                onChange={handleChangeSelect}
                                placeholder='P. fisica o Moral'
                                required

                            >
                                {formMode === 'add' && (
                                    <option value="">Seleccione Valor</option>
                                )}
                                {tiposContratante.map((tipo) => (
                                    <option key={tipo.id_tipo_contratante} value={tipo.id_tipo_contratante}>
                                        {tipo.nombre_tipo_contratante}
                                    </option>
                                ))}
                            </NativeSelect>

                        </Group>
                        <Group >



                            <TextInput
                                name="direccion"
                                label="Dirección"
                                style={{ width: '300px' }}
                                value={user.direccion}
                                onChange={handleChange}
                                placeholder='Dirección'
                                required
                            />
                            <TextInput
                                name="telefono_oficina"
                                label="Tel oficina"
                                style={{ width: '300px' }}
                                onChange={handleChange}
                                required
                                value={user.telefono_oficina}
                                placeholder='Tel oficina'


                            />

                        </Group>
                        <Group>

                            <TextInput
                                name="telefono_celular"
                                type='number'
                                label="Celular"
                                style={{ width: '300px' }}
                                onChange={handleChange}
                                required
                                value={user.telefono_celular}
                                placeholder='Solo números'


                            />
                            <TextInput
                                name="correo"
                                label="Correo"
                                style={{ width: '300px' }}
                                onChange={handleChange}
                                required
                                value={user.correo}
                                placeholder='Email'


                            />




                        </Group>
                        <Group >
                            <TextInput
                                name="nombre_asistente"
                                label="Asistente"
                                style={{ width: '300px' }}
                                value={user.nombre_asistente}
                                onChange={handleChange}
                                placeholder='Asistente'

                            />
                            <TextInput
                                name="notas_contratante"
                                resize="vertical"
                                autosize="true"

                                icon={<IconNotes />}
                                placeholder="Notas"
                                label="Notas"
                                value={user.notas_contratante}
                                style={{ width: '300px' }}
                                onChange={handleChange}

                            />
                        </Group>


                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar contratante" : "Editar contratante"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ContratanteForm;