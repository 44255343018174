import React, { useEffect, useState } from 'react'
import { supabase } from "../supabaseClient";
import Header from '../components/Header';
import ContratantesTable from '../components/ContratantesTable';
import ContratanteForm from '../components/ContratanteForm';
import { Group, Button, Divider, Modal, Card, TextInput, Accordion, Text } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { useAuth } from '../context/AuthContext';
import FiltrosContratantes from '../components/FiltrosContratantes';



function CatalogoContratantes() {
    const [showForm, setShowForm] = React.useState(false);
    const [formMode, setFormMode] = React.useState("add");
    const [contratantes, setContratantes] = useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({});
    const dispatch = useDispatch();
    const [filtroNombreContratante, setFiltroNombreContratante] = useState('');
    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);
                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        }
    }, [user, dispatch]);



    useEffect(() => {
        //body
        try {
            const getContratantes = async () => {
                dispatch(ShowLoading());
                const allContratantes = await fetchContratantes()
                setContratantes(allContratantes) //populate the react state
                // Llamar a la función contarContratantes para obtener el conteo al cargar el componente
                //contarContratantes();
                dispatch(HideLoading());
            }
            getContratantes()

        } catch (error) {
            //console.error('Error:', error);
            dispatch(HideLoading());
        }
    }, [])

    /* ESTA CONST ES PARA QUERY SOLO CAT_CONTRATANTES */

    const fetchContratantes = async () => {
        try {
            let { data: contratantes, error } = await supabase
                .from('cat_contratantes')
                .select(`
                id_contratante,
                clave_contratante,
                nombre_contratante,
                direccion,
                telefono_oficina,
                telefono_celular,
                correo,
                nombre_asistente,
                notas_contratante,
                id_tipo_contratante,
                cat_tipos_contratantes (id_tipo_contratante, nombre_tipo_contratante)
                `)
                .order('id_contratante', { ascending: true })
            //console.log("Contratantes obtenidos:", contratantes); // Agregar este console.log para verificar los contratantes obtenidos

            if (error) {
                throw error;
            }
            return contratantes;
        } catch (error) {
            //console.error('Error al obtener contratantes:', error.message);
            throw error; // Propaga el error para que se maneje en el componente padre si es necesario
        }
    };

    const handleBuscar = async () => {
        try {
            dispatch(ShowLoading());
            const { data: contratantesFiltrados, error } = await supabase
                .from('cat_contratantes')
                .select(`
                id_contratante,
                clave_contratante,
                nombre_contratante,
                direccion,
                telefono_oficina,
                telefono_celular,
                correo,
                nombre_asistente,
                notas_contratante,
                id_tipo_contratante,
                cat_tipos_contratantes (id_tipo_contratante, nombre_tipo_contratante)
                `)
                .ilike('nombre_contratante', `%${filtroNombreContratante.toLowerCase()}%`)
                .order('id_contratante', { ascending: true });

            if (error) {
                throw error;
            }
            setContratantes(contratantesFiltrados);
        } catch (error) {
            //console.error('Error al filtrar contratantes:', error.message);
        } finally {
            dispatch(HideLoading());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleBuscar();
        }
    };



    //Exacto. La función getData te ayudará a recargar los datos 
    //de los contratantes después de que hayas realizado una edición en la tabla cat_contratantes
    const getData = async () => {
        try {
            //console.log('Estado de carga activado');
            const allContratantes = await fetchContratantes();
            setContratantes(allContratantes);
        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };

    const canAddContratante = permisos &&
        permisos.catalogos &&
        permisos.catalogos.modulos &&
        permisos.catalogos.modulos.cat_contratantes &&
        permisos.catalogos.modulos.cat_contratantes.acciones &&
        permisos.catalogos.modulos.cat_contratantes.acciones.registrar_contratante === true;


    return (
        <div>
            <Card className='CardTituloCatalogos'
                mb={'15px'}
            >
                <Text
                    size="xl"
                    fw={900}

                >Catálogo Contratantes
                </Text>
            </Card>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Group justify='flex-start'>
                            <TextInput
                                placeholder="Buscar por nombre"
                                value={filtroNombreContratante}
                                onChange={(event) => setFiltroNombreContratante(event.target.value)}
                                onKeyPress={handleKeyPress} // Ejecuta handleKeyPress al presionar Enter
                            />
                            <Button onClick={handleBuscar}>Buscar</Button>
                            {canAddContratante && (
                                <Button
                                    color='#005187'
                                    onClick={
                                        () => {
                                            setShowForm(true);
                                            setFormMode("add");
                                        }}>
                                    + Agregar Contratante
                                </Button>
                            )}

                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Card className='CardTablas'>
                <Modal
                    size="auto"
                    title={formMode === "add" ? "Agregar Contratante" : "Editar Contratante"}
                    opened={showForm}
                    onClose={() => setShowForm(false)}
                >
                    <ContratanteForm
                        formMode={formMode}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                        transactionData={selectedTransaction} // Pasar el contratante seleccionado como prop
                    />
                </Modal>

                <div style={{ overflowX: "auto" }}>
                    <ContratantesTable
                        contratantes={contratantes}
                        setSelectedTransaction={setSelectedTransaction}
                        setFormMode={setFormMode}
                        setShowForm={setShowForm}
                        getData={getData}
                    />
                </div>
            </Card>
        </div>

    );

}


export default CatalogoContratantes;